import { LEAD_SEARCH, LOCATIONS_LIST } from "../api/queries";
import { useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import { ErrorMessage } from "../components/ErrorMessage";
import FilterButton from "../components/data-visualization/FilterButton";
import LeadDetails from "../components/lead/LeadDetails";
import LeadList from "../components/lead/LeadList";
import Row from "react-bootstrap/Row";
import SearchInput from "../components/SearchInput";
import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";

const MIN_CHAR_LEAD_SEARCH = 3;
const PAGINATION_SIZE = 20;
const PAGINATION_FIRST_PAGE = 1;

const APPOINTMENT_FILTER = "appointment";
const CONTACT_FILTER = "contact";

const ScrollableFillScreen = styled.div`
  height: calc(100vh - 145px);
  overflow-y: scroll;
`;
// Enabled sets the default value
const statusFilterOptions = {
  Contattato: {
    value: "CONTACT",
    include: true,
  },
  "Non Contattato": {
    value: "CONTACT",
    exclude: true,
    enabled: false,
  },
  Tutti: {
    value: "ALL",
    enabled: true,
    noFilters: true,
  },
};

const appointmentFilterOptions = {
  "Con Appuntamento": {
    value: "APPOINTMENT",
    include: true,
  },
  "Senza Appuntamento": {
    value: "APPOINTMENT",
    exclude: true,
    enabled: false,
  },
  Tutti: {
    value: "ALL",
    enabled: true,
    noFilters: true,
  },
};

const activityFilterState = {
  [APPOINTMENT_FILTER]: appointmentFilterOptions,
  [CONTACT_FILTER]: statusFilterOptions,
};

const mapStatusFilterToSearchQuery = (filters) => {
  const activities = {
    includedActivityTypes: [],
    excludedActivityTypes: [],
  };
  Object.keys(filters).forEach((filterName) => {
    const filterEntry = filters[filterName];
    Object.keys(filterEntry).forEach((key) => {
      const f = filterEntry[key];
      if (f?.include && f?.enabled) {
        activities["includedActivityTypes"].push(f["value"]);
      }
      if (f?.exclude && f?.enabled) {
        activities["excludedActivityTypes"].push(f["value"]);
      }
    });
  });
  return activities;
};

const convertLocations = (locationList) => {
  if (!locationList) {
    return [];
  }
  const reducedLocationList = locationList.reduce((acc, location) => {
    acc[location.name] = {
      enabled: false,
      value: location?.uuid,
    };
    return acc;
  }, {});
  return reducedLocationList;
};

const getSearchVariables = (
  filterLocations = {},
  filterSearchText = null,
  filterActivities = {},
  currentResultsPage = PAGINATION_FIRST_PAGE
) => {
  let locationUuids = Object.values(filterLocations)
    .filter((item) => item.enabled)
    .map((location) => location.value);

  let fetchVariables = {
    page: currentResultsPage,
    pageSize: PAGINATION_SIZE,
    includedLocationUuids: null,
    searchQuery: null,
    includedActivityTypes: [],
    excludedActivityTypes: [],
  };

  // Location
  if (locationUuids.length !== 0) {
    fetchVariables["includedLocationUuids"] = locationUuids;
  }

  // Search Query
  if (filterSearchText) {
    fetchVariables["searchQuery"] = filterSearchText;
  }

  // Included / Excluded activities
  if (filterActivities) {
    fetchVariables = {
      ...fetchVariables,
      ...mapStatusFilterToSearchQuery(filterActivities),
    };
  }
  return fetchVariables;
};

function LeadManagerPage() {
  const [selectedLead, setSelectedLead] = useState(null);
  const [filterLocations, setFilterLocations] = useState({});
  const [filterSearchText, setFilterSearchText] = useState("");
  const [filterActivities, setFilterActivities] = useState(activityFilterState);
  const [currentResultsPage, setCurrentResultsPage] = useState(1);

  // Leads query
  const {
    loading: leadsLoading,
    data: leadsData,
    error: leadsError,
    fetchMore,
    refetch,
  } = useQuery(LEAD_SEARCH, {
    variables: getSearchVariables(),
  });

  // Locations query
  const {
    loading: locationsLoading,
    error: locationsError,
    data: locationsData,
  } = useQuery(LOCATIONS_LIST);

  /* eslint-disable react-hooks/exhaustive-deps */

  // Filters trigger
  useEffect(() => {
    const fetchVariables = getSearchVariables(
      filterLocations,
      filterSearchText,
      filterActivities,
      currentResultsPage
    );
    // Resets page
    refetch(fetchVariables);
  }, [filterLocations, filterSearchText, filterActivities, refetch]);

  // Pagination trigger
  useEffect(() => {
    // Changing pages
    const fetchVariables = getSearchVariables(
      filterLocations,
      filterSearchText,
      filterActivities,
      currentResultsPage
    );
    // Prevents double trigger
    const lastLoadedPage = leadsData?.leadSearch?.page;
    if (!!lastLoadedPage && currentResultsPage > lastLoadedPage) {
      fetchMore({ variables: fetchVariables });
    }
  }, [currentResultsPage, fetchMore]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // Event handlers
  const handleTextChange = (newValue, actionMeta) => {
    const input = newValue?.trim();
    if (input.length < MIN_CHAR_LEAD_SEARCH && input.length !== 0) return;
    setFilterSearchText(input);
    setCurrentResultsPage(PAGINATION_FIRST_PAGE);
  };

  const handleNextPage = () => {
    if (leadsData.leadSearch.hasNext) {
      setCurrentResultsPage(currentResultsPage + 1);
    }
  };

  const handleLocationChange = (updatedFilters) => {
    setFilterLocations(updatedFilters);
    setCurrentResultsPage(PAGINATION_FIRST_PAGE);
  };

  const handleFilterChange = (updatedFilters, filterName) => {
    const newFilterState = {
      ...filterActivities,
      [filterName]: updatedFilters,
    };
    setFilterActivities(newFilterState);
  };

  // Components
  return (
    <div>
      <ErrorMessage errors={[locationsError]} />
      {/* Navbar */}
      <Row className="my-4">
        <Col>
          <SearchInput onChange={handleTextChange} />
        </Col>
        <Col>
          <span className="mx-2">
            <FilterButton
              name="Sede"
              filtersList={convertLocations(locationsData?.locationList)}
              isLoading={locationsLoading}
              onFilterChange={handleLocationChange}
            />
          </span>
          <span className="mx-2">
            <FilterButton
              name="Stato"
              singleSelection={true}
              filtersList={statusFilterOptions}
              onFilterChange={(updatedFilters) => {
                handleFilterChange(updatedFilters, CONTACT_FILTER);
              }}
            />
          </span>
          <span className="mx-2">
            <FilterButton
              name="Appuntamento"
              singleSelection={true}
              filtersList={appointmentFilterOptions}
              onFilterChange={(updatedFilters) => {
                handleFilterChange(updatedFilters, APPOINTMENT_FILTER);
              }}
            />
          </span>
        </Col>
      </Row>
      <Row>
        <Col md lg="4">
          <ScrollableFillScreen>
            {/* Left List */}
            <LeadList
              data={leadsData}
              onSelect={(selectedLead) => {
                setSelectedLead(selectedLead);
              }}
              onNextPage={handleNextPage}
              showNextButton={!!leadsData?.leadSearch?.hasNext}
              isLoading={leadsLoading}
              leadsError={leadsError}
            />
          </ScrollableFillScreen>
        </Col>
        <Col md lg="8">
          {/* Lead content */}
          <ScrollableFillScreen>
            <LeadDetails leadDetails={selectedLead} />
          </ScrollableFillScreen>
        </Col>
      </Row>
    </div>
  );
}

export default LeadManagerPage;
