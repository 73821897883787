import {
  useResizeColumns,
  useSortBy,
  useTable,
  useFlexLayout,
} from "react-table";
import constants from "../constants";
import React from "react";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

function UnpaidSubscriptionsList({ columns, unpaidList }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: unpaidList,
      },
      useSortBy,
      useFlexLayout,
      useResizeColumns
    );

  return (
    <>
      <h3 className="text-center">Elenco Abbonamenti Insoluti</h3>
      <Row>
        <Table striped bordered hover size="sm" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr key={key} {...restHeaderGroupProps}>
                  {headerGroup.headers.map((column) => {
                    const { key, ...restColumnProps } = column.getHeaderProps(
                      column.getSortByToggleProps()
                    );
                    return (
                      <th key={key} {...restColumnProps}>
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? constants.DESCENDING_SORT_SIMBOL
                              : constants.ASCENDING_SORT_SIMBOL
                            : ""}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { key, ...restRowProps } = row.getRowProps();
              return (
                <tr key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps();
                    return (
                      <td key={key} {...restCellProps}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </>
  );
}

export default UnpaidSubscriptionsList;
