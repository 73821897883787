import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  makeVar,
} from "@apollo/client";

//import auth from "./auth";
import constants from "./constants.js";
import { customIdTypePolicies } from "./utils";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: constants.GRAPHQL_ROOT,
});

// https://docs.djangoproject.com/en/3.0/ref/csrf/
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const csrftoken = getCookie("csrftoken");
  // return the headers to the context so httpLink can read them
  /*
  const authToken = auth.getAccessToken();
  const httpHeaders = {
    headers: {
      ...headers,
      //"X-CSRFToken": csrftoken,
      authorization: authToken ? `Bearer ${authToken}` : "",
    },
  };

  */
  const httpHeaders = {
    headers: {
      ...headers,
    },
  };
  if (csrftoken) {
    httpHeaders.headers["X-CSRFToken"] = csrftoken;
  }

  return httpHeaders;
});

const defaultOptions = {
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
};

const paginationMerge = (
  existing = { objects: [] },
  incoming = { objects: [] }
) => {
  const mergedMap = new Map();
  const mergedArrays = [...existing.objects, ...incoming.objects];
  // Removes dupes using Map, which acts as a set
  mergedArrays.forEach((obj) => {
    mergedMap.set(obj.__ref, obj);
  });
  const mergedObjects = Array.from(mergedMap.values());
  const returnData = {
    ...incoming,
    objects: mergedObjects,
  };
  return returnData;
};
const cacheOptions = {
  typePolicies: {
    Query: {
      fields: {
        stripePaymentMethodList: {
          read() {
            return localState.stripePaymentMethodList();
          },
        },
        leadSearch: {
          keyArgs: false,
          merge: paginationMerge,
        },
      },
    },
    ...customIdTypePolicies(["StripeSubscription", "UserType"], "id"),
    ...customIdTypePolicies([
      "Customer",
      "CheckIn",
      "Location",
      "UserSetting",
      "Subscription",
      "Plan",
      "Lead",
    ]),
  },
};
/*
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
    forward(operation);
  }
);*/

const link = from([/*errorLink,*/ authLink, httpLink]);

const cache = new InMemoryCache(cacheOptions);

let localState = null;

function resetLocalState() {
  localState = {
    newSubscription: {
      paymentMethod: makeVar(null),
      invoicingCustomer: makeVar(null),
      error: makeVar(null),
    },
  };
}
resetLocalState();

const client = new ApolloClient({
  defaultOptions,
  link,
  cache,
});

export { client, cacheOptions, localState, resetLocalState };
