import { BsArrowRight } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import React from "react";
import Row from "react-bootstrap/Row";

function ActionsButton({ linksList }) {
  const linksComponents = linksList.map((data) => {
    return (
      <Row key={data["title"]}>
        <Button className="text-left" variant="link" href={data["url"]}>
          {data["title"]} <BsArrowRight />
        </Button>
      </Row>
    );
  });

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{linksComponents}</Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="left-start"
      rootClose={true}
      overlay={popover}
    >
      <div style={{ textAlign: "center" }}>
        <Button variant="outline-dark" size="sm">
          •••
        </Button>
      </div>
    </OverlayTrigger>
  );
}

export default ActionsButton;
