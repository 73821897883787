import "react-phone-input-2/lib/style.css";

import Col from "react-bootstrap/Col";
import { Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import React from "react";

const NUMBER_PREFIX = "+";
function ensurePlus(number) {
  if (number.length === 0) {
    return number;
  }
  const prefix = number.charAt(0);
  if (prefix !== NUMBER_PREFIX) {
    return `${NUMBER_PREFIX}${number}`;
  }
  return number;
}

function PhoneNumber({
  control,
  errors,
  prefix = "",
  controlName = "phoneNumber",
  as,
}) {
  let prefixer = (name) => `${prefix}${name}`;
  return (
    <Form.Group as={as ? as : Col} controlId="phoneNumber">
      <Controller
        control={control}
        name={prefixer(controlName)}
        render={(props) => {
          const { onChange, onBlur, value, name } = props.field;
          return (
            <PhoneInput
              country="it"
              defaultMask="... ......."
              enableLongNumbers={true}
              disableDropdown={false}
              countryCodeEditable={false}
              onlyCountries={["it"]}
              onChange={(number) => {
                onChange(ensurePlus(number));
              }}
              onBlur={onBlur}
              value={value}
              inputProps={{
                name,
              }}
              isValid={(value, country) => {
                if (!!errors?.phoneNumber?.message) {
                  return errors.phoneNumber.message;
                } else {
                  return true;
                }
              }}
            />
          );
        }}
      />
    </Form.Group>
  );
}

export default PhoneNumber;
