import React, { useState } from "react";
import {
  //loadPaymentMethod,
  loadSubscriptionItalianState,
  paymentMethod,
  subscriptionItalianState,
} from "../api/utils";

import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import MainTable from "./MainTable";
import Row from "react-bootstrap/Row";
import urls from "../api/urls";
import { useNavigate } from "react-router-dom";

function buildUrlListSubscription(page, filter, order = "") {
  let params;
  params = new URLSearchParams({
    page,
    filter,
    order,
  });

  return `${urls.URL_LIST_SUBSCRIPTION}?${params.toString()}`;
}

function lookUpFieldSort(val) {
  const lookup = {
    startDate: "start_date",
    planName: "plan__name",
    checkinCount: "count_checkin",
    paymentType: "payment_type",
    customerName: "customer_fullname",
    invoicingCustomerFullName: "invoicing_customer_fullname",
    stateIt: "state",
    lastCheckIn: "last_checkin",
  };
  let result = lookup[val];

  result = typeof result === "undefined" ? "" : result;
  return result;
}
function findPlanName(planList, valueToLookFor) {
  for (const key in planList) {
    if (planList.hasOwnProperty(key)) {
      const valueFound = planList[key];
      if (valueFound.key === valueToLookFor) {
        return valueFound.plan;
      }
    }
  }
  // Se il valore non è stato trovato, restituisci null o un valore di default
  return null;
}
function loadQueryDescription(filter, planList) {
  //Build description of query
  var arrayFilter = filter.split(":");
  let querySubscription = "";
  switch (arrayFilter[0]) {
    case "state":
      querySubscription = `Stato - ${subscriptionItalianState[arrayFilter[1]]}`;
      break;
    case "payment_method":
      querySubscription = `Metodo Pagamento - ${paymentMethod[arrayFilter[1]]}`;
      break;
    case "plan_type":
      querySubscription = `Piano Pagamento -${findPlanName(
        planList,
        arrayFilter[1]
      )}`;
      break;
    default:
  }
  return <Badge bg="secondary">{querySubscription}</Badge>;

  //return `Filtro: ${querySubscription}`;
}

function SubscriptionsList({
  columns,
  data,
  paginator,
  filter,
  order,
  planList,
}) {
  //---------------------------------------------------
  const [typeOrder, setTypeOrder] = useState(order);
  const navigate = useNavigate();
  let page = paginator?.page;
  let hasNext = paginator?.hasNext;
  let hasPrev = paginator?.hasPrev;

  //const arrayPaymentMethod = loadPaymentMethod();
  const arraySubscriptionItalianState = loadSubscriptionItalianState();
  const querySubscription = loadQueryDescription(filter, planList);

  let url;
  //----------------------------------------------------------
  url = buildUrlListSubscription(page + 1, filter, order);
  const showNextPage = true;
  const nextPage = showNextPage ? (
    <Button
      // size="sm"
      variant="secondary"
      disabled={!hasNext}
      action="true"
      href={url}
    >
      Avanti{">"}
    </Button>
  ) : null;

  //----------------------------------------------------------
  const showPreviusPage = true;
  url = buildUrlListSubscription(page - 1, filter, order);
  const previusPage = showPreviusPage ? (
    <Button
      //size="sm"
      variant="secondary"
      disabled={!hasPrev}
      action="true"
      href={url}
    >
      {"<"}Indietro
    </Button>
  ) : null;

  const handleSelect = (e, eventKey) => {
    url = buildUrlListSubscription("1", e);
    navigate(url);
  };

  const handleColumn = (col) => {
    // Get column name
    const fieldSort = lookUpFieldSort(col?.id);
    //Check if the column contains sortable data
    if (fieldSort !== "") {
      let stringOrder = "";
      // Check if it is the same column chose earlier
      if (typeOrder === fieldSort) {
        //Swap the sort type from the previous choice
        const typeOrderFirstChar = typeOrder.substr(0, 1);
        if (typeOrderFirstChar === "-") {
          stringOrder = "";
        } else if (typeOrderFirstChar !== "-") {
          stringOrder = "-";
        }
      }
      // Set the sort parameter
      const paramOrder = `${stringOrder}${fieldSort}`;
      // Save the sort parameter
      setTypeOrder(paramOrder);
      //Call up the list of subscriptions
      if (paramOrder !== "") {
        url = buildUrlListSubscription("1", filter, paramOrder);
        navigate(url);
      }
    }
    // console.log(col?.Header);
    // console.log(col?.id);
  };

  return (
    <>
      <h3 className="text-center">Elenco Abbonamenti</h3>
      <hr></hr>
      <Row>
        <Col>
          <ButtonGroup size="sm">
            {/* <ButtonGroup size="sm">
              <DropdownButton
                onSelect={handleSelect}
                title="Metodo Pagamento"
                id="sm-nested-dropdown"
                size="sm"
                variant="outline-primary"
              >
                {arrayPaymentMethod.map((s, index) => (
                  <Dropdown.Item
                    key={index}
                    value={s.key}
                    eventKey={`payment_method:${s.key}`}
                  >
                    {s.method}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </ButtonGroup> */}
            <DropdownButton
              onSelect={handleSelect}
              title="Stato"
              id="sm-nested-dropdown"
              size="sm"
              variant="outline-primary"
            >
              {arraySubscriptionItalianState.map((s, index) => (
                <Dropdown.Item
                  key={index}
                  value={s.key}
                  eventKey={`state:${s.key}`}
                >
                  {s.state}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <DropdownButton
              onSelect={handleSelect}
              title="Piano"
              id="sm-nested-dropdown"
              size="sm"
              variant="outline-primary"
            >
              {planList.map((s, index) => (
                <Dropdown.Item
                  key={index}
                  value={s.key}
                  eventKey={`plan_type:${s.key}`}
                >
                  {s.plan}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </ButtonGroup>
        </Col>
        <Col>
          <h4 className="float-end">{querySubscription}</h4>
        </Col>
        <Col>
          <ButtonGroup size="sm" className="float-end">
            {previusPage}
            {nextPage}
          </ButtonGroup>
        </Col>
      </Row>

      <Row>{MainTable(columns, data, handleColumn)}</Row>

      <Row>
        <Col>
          <ButtonGroup size="sm" className="float-end">
            {previusPage}
            {nextPage}
          </ButtonGroup>
        </Col>
      </Row>
    </>
  );
}

export default SubscriptionsList;
