import { CustomerRow } from "../components/subscription/Common";
import React, { useState } from "react";
import { MUTATION_UPDATE_SUBSCRIPTION } from "../api/mutations";
import { useMutation } from "@apollo/client";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { CustomerModule } from "../components/customer/CustomerModule";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { customerValidationType } from "../components/validators";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

// Invoicing customer Section
function Customer({
  customer,
  onChange,
  title,
  fullInfo,
  className,
  validation = customerValidationType.INVOICING,
}) {
  return (
    <CustomerRow>
      <Row>
        <Col>
          <h3>{title}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomerModule
            className={className}
            customer={customer}
            onSetCustomer={onChange}
            validation={validation}
            fullInfo={fullInfo}
            createCustomer={false}
          />
        </Col>
      </Row>
    </CustomerRow>
  );
}

function UpdateReceivingCustomerModal({ subscriptionUuid, show, onHide }) {
  const [modifiedCustomer, setModifiedCustomer] = useState(null);
  const [msgValue, setMsgValue] = useState(null);
  const [enableUpdateButton, setEnableUpdateButton] = useState(true);

  // Create Subscription Mutation Hook
  const [updateSubscription, { loading: mutationLoading }] = useMutation(
    MUTATION_UPDATE_SUBSCRIPTION
  );

  const updateReceivingCustomer = async (e) => {
    e.preventDefault();

    setEnableUpdateButton(false);
    const subscription = {
      receivingCustomer: modifiedCustomer.uuid,
    };

    await updateSubscription({
      variables: {
        subscription,
        guid: subscriptionUuid,
      },
    })
      .then((result) => {
        if (!!result?.data?.updateSubscription?.error) {
          // There is an error
          setMsgValue(
            <Alert variant="danger">
              {result?.data?.updateSubscription?.error?.message}
            </Alert>
          );
        } else {
          setMsgValue(
            <Alert variant="success">
              {"Variazione avvenuta con successo"}
            </Alert>
          );
        }
      })
      .catch((error) => {
        let msg = `pz${error.message} ${error?.networkError?.result?.errors[0]?.message}`;
        setMsgValue(<Alert variant="danger">{msg}</Alert>);
      });
  };
  // Funzione per gestire l'evento onHide
  const handleModalHide = () => {
    // Codice da eseguire quando la finestra modale viene chiusa
    setModifiedCustomer(null);
    setEnableUpdateButton(true);
    setMsgValue(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
          handleModalHide();
        }}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Cambio Beneficiario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Customer
            customer={modifiedCustomer}
            fullInfo={true}
            className="invoicing-customer"
            onChange={(customer) => {
              setModifiedCustomer(customer);
            }}
          />
          <Row>
            <div className="mt-4" />
            <div className="mt-4" />
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={updateReceivingCustomer}
                disabled={mutationLoading || !enableUpdateButton}
              >
                {mutationLoading ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  "Cambia"
                )}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1> </h1>
            </Col>
          </Row>
          <Row>{msgValue !== "" ? msgValue : null}</Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
UpdateReceivingCustomerModal.propTypes = {
  subscription: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
export default UpdateReceivingCustomerModal;
