import { config } from "../config";

class StripeApi {
  constructor(key) {
    this.apiKey = key;
  }
  loadPlans = async () => {};
}
const stripe_var = new StripeApi(config.STRIPE_PUBLIC_KEY);
export default stripe_var;
