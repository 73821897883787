import SalesLedgerTotalMonthsList from "../components/SalesLedgerTotalMonthsList";
import { ErrorMessage } from "../components/ErrorMessage";
import { DAYLY_SALES_REPORT_LIST } from "../api/queries";
import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { isUserInGroup } from "../api/utils";
import constants from "../constants";
import { useNavigate } from "react-router-dom";

function SalesLedgerTotalMonthsListPage() {
  const { paramDate } = useParams();
  const navigate = useNavigate(); // Hook per il reindirizzamento

  // Query loading
  const { loading, error, data } = useQuery(DAYLY_SALES_REPORT_LIST, {
    variables: {
      dateParam: paramDate,
    },
  });

  //---------------------------------------

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Giorni
          </div>
        ),
        accessor: "day",
        width: 25,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <div>Totale </div>
            <div>Corrispettivi </div>
          </div>
        ),
        accessor: "grossAmount",
        width: 50,
        Cell: (row) => (
          <div style={{ textAlign: "right" }}>
            {(+row.value).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        ),
      },
      {
        Header: () => (
          <div style={{ textAlign: "center" }}>
            <div>E-Commerce </div>
            <div>Ricevute </div>
            <div>Aliquota 22%</div>
          </div>
        ),
        accessor: "dailyTotalAmountReceipts",
        width: 50,
        Cell: (row) => (
          <div style={{ textAlign: "right" }}>
            {(+row.value).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <div>E-Commerce </div>
            <div>Resi </div>
            <div>Aliquota 22%</div>
          </div>
        ),
        accessor: "dailyTotalAmountRefunds",
        width: 50,
        Cell: (row) => (
          <div style={{ textAlign: "right" }}>
            {(+row.value).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <div>E-Commerce </div>
            <div>Fatture </div>
            <div>Aliquota 22%</div>
          </div>
        ),
        accessor: "dailyTotalAmountInvoices",
        width: 50,
        Cell: (row) => (
          <div style={{ textAlign: "right" }}>
            {(+row.value).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        ),
      },
      {
        Header: "Note",
        accessor: "notes",
        width: 80,
      },
    ],
    []
  );

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  const isManagementCorrispettivi = isUserInGroup(
    data?.userSetting ?? {}, // Use null coalescing to handle the undefined/null case
    constants.GROUPS_MANAGEMENT_CORRISPETTIVI
  );

  // Return null directly if the condition is not satisfied
  if (!data?.userSetting || !isManagementCorrispettivi) {
    navigate(-1); // Questo ti riporta alla pagina precedente nella history
    return null;
  }

  let dailyReport = data?.dailySalesReportList;
  //Create a list with the last item
  const dailyReportTotal = dailyReport[dailyReport.length - 1];
  //Removes the last line
  dailyReport = [...dailyReport.slice(0, -1)];

  return (
    <>
      <SalesLedgerTotalMonthsList
        columns={columns}
        data={dailyReport}
        dailyReportTotal={dailyReportTotal}
      />
    </>
  );
}
export default SalesLedgerTotalMonthsListPage;
