// This module loads the appropriate configuration for different environments
//import { getEnv, envList } from "./api/utils";

const STRIPE_PUBLIC_KEY_TEST = "pk_test_HmlfK4HgJzvQTyhT0zawArwv";
const STRIPE_PUBLIC_KEY_PRODUCTION = "pk_live_xJXudzzkA9XizN6HNzccbdLf";
const SUMUP_AFFILIATE_KEY = "42a43c40-8d9f-41b7-af5d-d8515e64a694";
const SUMUP_CALLBACK_HOST_LOCAL_HOST = "192.168.1.22:3000";

const envList = {
  PRODUCTION: "production",
  DEVELOPMENT: "development",
  STAGING: "staging",
};

function isOnNode() {
  return typeof window === "undefined";
}

function getEnv() {
  // Default value is prod
  let env = envList.DEVELOPMENT;
  if (isOnNode()) {
    // Node
    const process = require("process");
    env = process?.env?.REACT_APP_MEDEX_ENV;
  } else {
    // Browser
    env = window?.SERVER_DATA?.REACT_APP_MEDEX_ENV;
  }
  if (!env || env === "%REACT_APP_MEDEX_ENV%") {
    // env equals %REACT_APP_MEDEX_ENV% when the build is uncompiled
    env = envList.DEVELOPMENT;
  }
  return env;
}

function loadConfig(currentEnv = null) {
  const ENV = currentEnv ? currentEnv : getEnv();
  const base = {
    STRIPE_PUBLIC_KEY: STRIPE_PUBLIC_KEY_TEST,
    SUMUP_AFFILIATE_KEY,
    SUMUP_CALLBACK_HOST: SUMUP_CALLBACK_HOST_LOCAL_HOST,
    ENV,
  };

  // Production specific configs
  const production = {
    STRIPE_PUBLIC_KEY: STRIPE_PUBLIC_KEY_PRODUCTION,
    SUMUP_CALLBACK_HOST: window?.location?.host, // Will be null on Node
  };
  // Staging specific configs
  const staging = {
    SUMUP_CALLBACK_HOST: window?.location?.host, // Will be null on Node
  };

  let config = Object.assign({}, base);
  if (ENV === envList.STAGING) {
    Object.assign(config, staging);
  }

  if (ENV === envList.PRODUCTION) {
    Object.assign(config, production);
  }
  return config;
}

const loadedConfig = loadConfig();

export { loadedConfig as config, loadConfig, envList, getEnv, isOnNode };
