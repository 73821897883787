import React, { useState } from "react";

import { ErrorMessage } from "../../components/ErrorMessage";
import { GET_INVOICE } from "../api/queries";
import { Navigate } from "react-router";
import ReceiptDetailForm from "../components/ReceiptDetailForm";
import { getCheckinDetailUrl } from "../../api/urls";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

function ReceiptDetailPage() {
  let { receiptUuid } = useParams();

  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);

  const { loading, error, data } = useQuery(GET_INVOICE, {
    variables: {
      invoiceUuid: receiptUuid,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  const currentLocation = data?.userSetting?.currentLocation;
  const receipt = data?.getInvoice;
  const receiptItem = data?.getItemsForInvoice[0];
  const creditNote = data?.getCreditNoteForInvoice;
  const checkin = receipt?.checkin;

  const checkinUuid = checkin?.uuid;

  let redirectContinueToList = null;
  if (!!shouldRedirectToList) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: getCheckinDetailUrl(checkinUuid),
        }}
      />
    );
  }

  return (
    <>
      <ReceiptDetailForm
        receipt={receipt}
        receiptItem={receiptItem}
        creditNote={creditNote}
        checkin={checkin}
        currentLocation={currentLocation}
        modalContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
      />
      {redirectContinueToList}
    </>
  );
}

export default ReceiptDetailPage;
