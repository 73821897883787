import React from "react";
import Table from "react-bootstrap/Table";
import { useSortBy } from "react-table";
import Button from "react-bootstrap/Button";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
//import CustomerCreateForm from "./forms/CustomerCreateForm";
//import Modal from "./Modal";
import CustomerCreateModal from "./customer/CustomerCreateModal";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  //useAsyncDebounce,
} from "react-table";

import {
  GlobalFilter,
  DefaultColumnFilter,
  fuzzyTextFilterFn,
} from "../api/table_react_function";

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function CustomerListForm({
  columns,
  data,
  openCustomer,
  onCreatedCustomer,
  creating,
  completed,
  createError,
  globalFilter,
  referralSourcesList,
}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy
  );

  const [modalShow, setModalShow] = React.useState(false);
  const modalForm = () => {
    setModalShow(true);
  };

  let tagGlobal = "";
  if (globalFilter === true) {
    tagGlobal = (
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
    );
  }
  //const firstPageRows = rows.slice(0, 1000);

  return (
    <>
      <Row>
        <Col xl={6}>
          <h3>Elenco Clienti</h3>
        </Col>
        <Col xl={6}>
          <Button variant="primary" onClick={modalForm}>
            Inserisci nuovo cliente
          </Button>
        </Col>
      </Row>
      <div>
        <Row>
          <Col xl={10}>
            <Table striped bordered hover size="sm" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                        {/* Render the columns filter UI */}
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
                <tr>
                  <th
                    colSpan={visibleColumns.length}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {tagGlobal}
                  </th>
                </tr>
              </thead>
              <tbody {...getTableBodyProps()}>
                {/* {firstPageRows.map((row, i) => { */}
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={() => {
                        openCustomer(row.original);
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <CustomerCreateModal
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
          onCreated={onCreatedCustomer}
        />
      </div>
    </>
  );
}

export default CustomerListForm;
