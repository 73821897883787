import * as yup from "yup";

import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  GET_LEAD_AND_ACTIVITIES_BY_UUID,
  LOCATIONS_LIST,
} from "../../api/queries";
import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import { CustomerInputField } from "../CustomerInput";
import { ErrorMessage } from "../ErrorMessage";
import Form from "react-bootstrap/Form";
import { FormControl } from "react-bootstrap";
import { MUTATION_UPDATE_LEAD } from "../../api/mutations";
import Modal from "react-bootstrap/Modal";
import PhoneNumber from "../PhoneNumber";
import Select from "react-select";
import { useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

const LeadDetailsEditModal = ({ leadDetails, show, onHide, onUpdate }) => {
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Lead Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LeadDetailsForm leadDetails={leadDetails} onUpdate={onUpdate} />
        </Modal.Body>
      </Modal>
    </>
  );
};

// Define your validation schema
const schema = yup.object().shape({
  fullName: yup.string().required("Nome obbligatorio"),
  email: yup.string().email("Invalid email").required("Email obbligatoria"),
  phoneNumber: yup.string().required("Numero obbligatorio"),
  locationUuid: yup
    .string()
    .uuid("Location non valida")
    .required("Location obbligatoria"),
});

const LeadDetailsForm = ({ leadDetails, onUpdate }) => {
  const [updateLeadError, setUpdateLeadError] = useState(null);
  const [
    updateLead,
    {
      loading: updateLeadLoading,
      error: updateLeadLoadingError,
      //data: responseData,
    },
  ] = useMutation(MUTATION_UPDATE_LEAD, {
    refetchQueries: (mutationResult) => [
      {
        query: GET_LEAD_AND_ACTIVITIES_BY_UUID,
        variables: { uuid: mutationResult?.data?.updateLead?.lead?.uuid },
      },
    ],
  });

  const {
    loading: locationsLoading,
    error: locationsError,
    data: locationsData,
  } = useQuery(LOCATIONS_LIST);
  // Use the useForm hook with the schema
  const useFormMethods = useForm({
    defaultValues: {
      fullName: leadDetails?.fullName,
      email: leadDetails?.email,
      phoneNumber: leadDetails?.phoneNumber,
      locationUuid: leadDetails?.location?.uuid,
    },

    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useFormMethods;

  // Handle form submission
  const onSubmit = async (data) => {
    try {
      const response = await updateLead({
        variables: {
          uuid: data.uuid,
          email: data.email,
          fullName: data.fullName,
          phoneNumber: data.phoneNumber,
          locationUuid: data.locationUuid,
        },
      });
      const error = response?.data?.updateLead?.error;
      if (error) {
        throw error;
      }
      const returnData = response?.data?.updateLead?.lead;

      onUpdate(returnData);
    } catch (error) {
      setUpdateLeadError(error);
    }
  };

  let locationSelectValues = [];

  if (locationsData?.locationList) {
    locationSelectValues = locationsData?.locationList.map((item) => {
      return {
        value: item.uuid,
        label: item.name,
      };
    });
  }
  return (
    <FormProvider {...useFormMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="fullName">
          <CustomerInputField
            label="Nome Completo"
            name="fullName"
            register={register}
            errors={errors}
          ></CustomerInputField>
          {/*errors.fullName && <p>{errors.fullName.message}</p>*/}
        </Form.Group>
        <Form.Group controlId="email">
          <CustomerInputField
            label="Email"
            name="email"
            register={register}
            errors={errors}
          ></CustomerInputField>
        </Form.Group>
        <Form.Group controlId="phoneNumber">
          <Form.Label>Telefono</Form.Label>
          <PhoneNumber
            control={control}
            errors={errors}
            controlName="phoneNumber"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Sede Assegnata</Form.Label>
          <Controller
            name="locationUuid"
            control={control}
            defaultValue={leadDetails?.location?.uuid}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const selectedLocation = locationSelectValues.find((item) => {
                return item.value === value;
              });
              const errorComponent = error ? (
                <Form.Text className="text-danger">{error.message}</Form.Text>
              ) : null;
              return (
                <>
                  <Select
                    options={locationSelectValues}
                    onChange={(selectedOption) => {
                      return onChange(selectedOption.value);
                    }}
                    value={selectedLocation}
                    isLoading={locationsLoading}
                  />
                  {errorComponent}
                </>
              );
            }}
          />
        </Form.Group>
        <FormControl
          type="hidden"
          defaultValue={leadDetails?.uuid}
          {...register("uuid")}
        />

        <Form.Group>
          <br />
          <ErrorMessage
            errors={[locationsError, updateLeadError, updateLeadLoadingError]}
          />
          <Button
            disabled={updateLeadLoading || locationsLoading}
            type="submit"
          >
            Salva
          </Button>
        </Form.Group>
      </Form>
    </FormProvider>
  );
};

export default LeadDetailsEditModal;
