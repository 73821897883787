import {
  getCheckinDetailUrl,
  getCreditDetailUrl,
  getPlanUrl,
  getSubscriptionDetailUrl,
  getSubscriptionInvoicesListUrl,
} from "../api/urls";

import ActionsButton from "../components/ActionsButton";
import Button from "react-bootstrap/Button";
import CustomerCheckInsList from "../components/CustomerCheckInsList";
import { ErrorMessage } from "../components/ErrorMessage";
import { QUERY_CUSTOMER_ALL_CHECKINS } from "../api/queries";
import React from "react";
import Row from "react-bootstrap/Row";
import StateIcon from "../components/StateIcon";
import addBsArrowRight from "../components/AddBsArrowRight";
import constants from "../constants";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { calculateDurationCheckIn } from "../api/utils";

function LoadCheckInList(customerCheckin) {
  const maxChar = 50;
  let j = 0;
  let listCheckIn = customerCheckin.map((s) => {
    j = j + 1;
    let foundingSource = s.checkinFundingSource?.name;
    let itemSub = foundingSource;
    let planUuid = null;
    let paymentType = null;
    let subscriptionState = null;
    let subscriptionOrCredit;

    let notes = "";
    if (!!s?.notes) {
      notes =
        s?.notes.length <= maxChar
          ? s?.notes
          : ` ${s?.notes.substr(0, maxChar)} •••••`;
    }
    const checkinStatus =
      s.checkInStatus === constants.CHECK_IN_STATUS_TYPE_PENDING
        ? constants.STATUS_PENDING
        : null;
    const present = s.isPresent ? s?.product?.name : "Non Presentato";
    const checkInMinute = calculateDurationCheckIn(
      s?.checkInStatus,
      s?.closedDate,
      s?.timestamp
    );
    const checkInDuration = !!checkInMinute ? checkInMinute : null;

    switch (s?.checkinFundingSource?.uuid) {
      case constants.CHECKIN_FUNDING_SUBSCRIPTION:
        planUuid = s?.subscription?.plan?.uuid;
        paymentType = s?.subscription?.paymentType;
        foundingSource = s?.subscription?.plan?.name;
        itemSub = ` ${s?.subscription?.startDate} - ${foundingSource}`;
        subscriptionState = (
          <StateIcon
            nameIcon={s?.subscription?.state}
            objType="Icon"
          ></StateIcon>
        );
        subscriptionOrCredit = (
          <Row>
            <Button
              variant="link"
              href={getSubscriptionDetailUrl(s?.subscription?.uuid)}
            >
              {addBsArrowRight("Visualizza Abbonamento")}
            </Button>
          </Row>
        );
        break;
      case constants.CHECKIN_FUNDING_CREDIT:
        planUuid = s?.credit?.plan?.uuid;
        foundingSource = s?.credit?.plan?.name;
        itemSub = ` ${s?.credit?.startDate}  - ${s?.checkinFundingSource?.name}`;
        subscriptionState = (
          <StateIcon nameIcon={s?.credit?.state} objType="Icon"></StateIcon>
        );
        subscriptionOrCredit = (
          <Row>
            <Button variant="link" href={getCreditDetailUrl(s?.credit?.uuid)}>
              {addBsArrowRight("Visualizza Credito")}
            </Button>
          </Row>
        );
        break;
      default:
        foundingSource = s?.checkinFundingSource?.name;
    }

    return {
      uuid: s?.uuid,
      date: s.date,
      plan: foundingSource,
      product: present,
      worker: s?.worker?.last,
      notes: notes,
      subscription: s.subscription?.plan?.name,
      foundingsource: s.checkinFundingSource?.name,
      startDate: itemSub,
      subscriptionUuid: s?.subscription?.uuid,
      planUuid: planUuid,
      paymentType: paymentType,
      subscriptionState: subscriptionState,
      subscriptionOrCredit: subscriptionOrCredit,
      checkInStatus: checkinStatus,
      checkInDuration: checkInDuration,
      index: j,
    };
  });
  return listCheckIn;
}

function CustomerCheckInsListPage() {
  const { customerUuid } = useParams();

  // Query loading
  const { loading, error, data } = useQuery(QUERY_CUSTOMER_ALL_CHECKINS, {
    variables: {
      customerUuid,
    },
  });

  const popoverLinksList = (cell) => {
    let linksList = [];

    const showPaymentList =
      cell?.paymentType?.toUpperCase() === constants.STRIPE_PAYMENT_METHOD;

    const showPlanDetail = !!cell?.planUuid;

    if (showPaymentList) {
      linksList.push({
        title: "Visualizza Elenco Pagamenti",
        url: getSubscriptionInvoicesListUrl(cell?.subscriptionUuid),
      });
    }

    if (showPlanDetail) {
      linksList.push({
        title: "Visualizza Piano",
        url: getPlanUrl(cell?.planUuid),
      });
    }

    linksList = [
      ...linksList,
      {
        title: "Visualizza Check-In",
        url: getCheckinDetailUrl(cell.uuid),
      },
    ];
    return linksList;
  };

  //---------------------------------------

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          ></div>
        ),
        accessor: "index",
        width: 10,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Data",
        accessor: "date",
        width: 30,
        Cell: (cell) => (
          <div style={{ textAlign: "center" }}>
            {cell?.row?.original.checkInStatus}
            {cell?.row?.original.date}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Durata (minuti)
          </div>
        ),
        accessor: "checkInDuration",
        width: 18,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Tipo Check-In",
        accessor: "startDate",
        width: 100,
        Cell: ({ cell }) => (
          <div>
            {cell?.row?.original.subscriptionState}
            {cell?.row?.original.startDate}
          </div>
        ),
      },
      {
        Header: "Trattamento",
        accessor: "product",
        width: 50,
      },
      {
        Header: "Estetista",
        accessor: "worker",
        width: 30,
      },
      {
        Header: "Note",
        accessor: "notes",
        width: 30,
      },
      {
        Header: "",
        accessor: "menu",
        width: 15,
        Cell: ({ cell }) => {
          const links = popoverLinksList(cell?.row?.original);
          return <ActionsButton linksList={links} />;
        },
      },
    ],
    []
  );

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  let checkInListData = data?.checkInCustomer ? data?.checkInCustomer : [];

  const checkinList = LoadCheckInList(checkInListData);

  return (
    <>
      <CustomerCheckInsList
        columns={columns}
        data={checkinList}
        customer={data?.customer}
      />
    </>
  );
}
export default CustomerCheckInsListPage;
