import {
  getCheckinDetailUrl,
  getCreditDetailUrl,
  getCustomerCheckinsListUrl,
  getCustomerUrl,
  getPlanUrl,
  getSubscriptionDetailUrl,
} from "../api/urls";
import ActionsButton from "../components/ActionsButton";
import CheckInListForm from "../components/CheckInListForm";
import { QUERY_GET_CHECKIN_PAGINATOR } from "../api/queries";
import React from "react";

import constants from "../constants";
import { calculateDurationCheckIn } from "../api/utils";
import { formatLocalTime } from "../api/date-utils";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

function loadAllCheckIn(allCheckinByLocation) {
  let j = 0;
  let listCheckIn = allCheckinByLocation.map((s) => {
    let planName;
    let planUuid;
    const present = s.isPresent ? s?.product.name : "Non Presentato";
    const checkinStatus =
      s.checkInStatus === constants.CHECK_IN_STATUS_TYPE_PENDING
        ? constants.STATUS_PENDING
        : null;
    const checkInMinute = calculateDurationCheckIn(
      s?.checkInStatus,
      s?.closedDate,
      s?.timestamp
    );
    const checkInDuration = !!checkInMinute ? checkInMinute : null;

    switch (s?.checkinFundingSource?.uuid) {
      case constants.CHECKIN_FUNDING_SUBSCRIPTION:
        planName = s?.subscription?.plan?.name;
        planUuid = s?.subscription?.plan?.uuid;
        break;
      case constants.CHECKIN_FUNDING_CREDIT:
        planName = s?.checkinFundingSource?.name;
        planUuid = s?.credit?.plan?.uuid;
        break;
      default:
        planName = s?.checkinFundingSource?.name;
    }

    return {
      uuid: s?.uuid,
      date: s.date,
      time: formatLocalTime(s?.time).substr(0, 5),
      plan: planName,
      productName: present,
      customerUuid: s?.customer?.uuid,
      fullName: s?.customer?.fullName,
      subscriptionUuid: s?.subscription?.uuid,
      foundingsourceUuid: s?.checkinFundingSource?.uuid,
      planUuid: planUuid,
      creditUuid: s?.credit?.uuid,
      checkInStatus: checkinStatus,
      checkInDuration: checkInDuration,
      index: j,
      menu: "•••",
    };
  });

  return listCheckIn;
}

function CheckInListPage() {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const page = parseInt(urlParams.get("page"));
  let numPage = page === null ? 1 : page;
  const PAGESIZE = 50; //set row for Page
  //const locationGuid = constants.DEFAULT_LOCATION_UUID;
  const { loading, error, data } = useQuery(QUERY_GET_CHECKIN_PAGINATOR, {
    variables: {
      page: numPage,
      pageSize: PAGESIZE,
    },
  });

  const popoverLinksList = (cell) => {
    let linksList = [];

    if (!!cell?.subscriptionUuid) {
      linksList.push({
        title: "Visualizza Abbonamento",
        url: getSubscriptionDetailUrl(cell?.subscriptionUuid),
      });
    }

    if (!!cell?.creditUuid) {
      linksList.push({
        title: "Visualizza Credito",
        url: getCreditDetailUrl(cell?.creditUuid),
      });
    }

    if (!!cell?.planUuid) {
      linksList.push({
        title: "Visualizza Piano",
        url: getPlanUrl(cell?.planUuid),
      });
    }

    linksList = [
      ...linksList,
      {
        title: "Visualizza Check-In",
        url: getCheckinDetailUrl(cell.uuid),
      },
      {
        title: "Visualizza Cliente",
        url: getCustomerUrl(cell?.customerUuid),
      },
      {
        title: "Visualizza Elenco Check-In",
        url: getCustomerCheckinsListUrl(cell?.customerUuid),
      },
    ];

    return linksList;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Data",
        accessor: "date",
        width: 25,
        Cell: (cell) => (
          <div style={{ textAlign: "center" }}>
            {cell?.row?.original.checkInStatus}
            {cell?.row?.original.date}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Ora
          </div>
        ),
        accessor: "time",
        width: 10,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Durata (minuti)
          </div>
        ),
        accessor: "checkInDuration",
        width: 12,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Cliente",
        accessor: "fullName",
        width: 40,
      },
      {
        Header: "Trattamento",
        accessor: "productName",
        width: 50,
      },
      {
        Header: "Piano",
        accessor: "plan",
        width: 65,
      },
      {
        Header: "",
        accessor: "menu",
        width: 10,
        Cell: ({ cell }) => {
          const links = popoverLinksList(cell?.row?.original);
          return <ActionsButton linksList={links} />;
        },
      },
    ],
    []
  );

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <p>Error!</p>;
  }

  const checkinList = loadAllCheckIn(data?.checkinPaginator?.objects);

  return (
    <CheckInListForm
      data={checkinList}
      columns={columns}
      paginator={data?.paginator}
    />
  );
}
export default CheckInListPage;
