import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { ErrorMessage } from "../../components/ErrorMessage";
import { GET_CHECKIN_PRODUCT_PRICE } from "../api/queries";
import { MUTATION_CREATE_INVOICE } from "../api/mutations";
import { Navigate } from "react-router";
import ReceiptCreateForm from "../components/ReceiptCreateForm";
import { getCheckinDetailUrl } from "../../api/urls";
import { useParams } from "react-router-dom";

function ReceiptCreatePage() {
  let { checkinUuid } = useParams();

  const [
    createReceiptMutation,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_CREATE_INVOICE);

  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);
  const { loading, error, data } = useQuery(GET_CHECKIN_PRODUCT_PRICE, {
    variables: {
      checkinUuid: checkinUuid,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }
  let redirectContinueToList = null;
  if (!!shouldRedirectToList) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: getCheckinDetailUrl(checkinUuid),
        }}
      />
    );
  }
  return (
    <>
      <ReceiptCreateForm
        data={data}
        createReceipt={createReceiptMutation}
        creating={mutationLoading}
        completed={!!mutationData}
        createError={mutationError}
        modalContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
      />
      {redirectContinueToList}
    </>
  );
}

export default ReceiptCreatePage;
