import React from "react";
import PrepaidPackageDetailForm from "../components/PrepaidPackageDetailForm";
import { QUERY_GET_PREPAID_PACKAGE } from "../api/queries";
import { useQuery } from "@apollo/client";
import { ErrorMessage } from "../components/ErrorMessage";
import { useParams } from "react-router-dom";
import { loadListCheckIn } from "../api/utils";

function PrepaidPackageDetailPage() {
  let { prepaidPackageUuid } = useParams();

  const { loading, error, data } = useQuery(QUERY_GET_PREPAID_PACKAGE, {
    variables: {
      prepaidPackageuuid: prepaidPackageUuid,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  const currentLocation = data?.userSetting?.currentLocation;
  const counterCheckIn = data?.checkInCounter;
  const checkinList = loadListCheckIn(data?.checkInPrepaidPackage);

  return (
    <PrepaidPackageDetailForm
      data={data}
      checkinList={checkinList}
      counterCheckIn={counterCheckIn}
      checkInNotPresentCounter={data?.checkInNotPresentCounter}
      currentLocation={currentLocation}
    />
  );
}

export default PrepaidPackageDetailPage;
