import * as yup from "yup";

import { checkTaxiId } from "../api/utils";
import constants from "../constants";
import { yupResolver } from "@hookform/resolvers/yup";

// Customer
const INVOICING_CUSTOMER = "INVOICING";
const RECEIVING_CUSTOMER = "RECEIVING";

const validationReceivingCustomer = {
  first: yup.string().required(constants.VALIDATION_MANDATORY_FIELD),
  last: yup.string().required(constants.VALIDATION_MANDATORY_FIELD),
  email: yup
    .string()
    .required(constants.VALIDATION_MANDATORY_FIELD)
    .email("Mail non valida"),
  taxId: yup
    .string()
    .matches(constants.REGEX_CF, "CF invalido")
    .test("checkTaxiId", "Codice Fiscale Errato", function (taxId) {
      return checkTaxiId(taxId);
    }),
  postcode: yup
    .string()
    .nullable()
    .when("road", (road, schema) => {
      return schema.test({
        test: (postcode) =>
          !(
            (road === "" || road === null) &&
            postcode !== null &&
            postcode !== ""
          ),
        message: "Nell'indirizzo manca la Via",
      });
    })
    .matches(/\b\d{5}\b/, {
      message: "Il CAP non è valido",
      excludeEmptyString: true,
    }),
  city: yup
    .string()
    .nullable()
    .when("road", (road, schema) => {
      return schema.test({
        test: (city) =>
          !((road === "" || road === null) && city !== null && city !== ""),
        message: "Nell'indirizzo manca la Via",
      });
    })
    .when("postcode", (postcode, schema) => {
      return schema.test({
        test: (city) =>
          !(
            (postcode === "" || postcode === null) &&
            city !== null &&
            city !== ""
          ),
        message: "Nell'indirizzo manca la CAP",
      });
    }),
  county: yup
    .string()
    .nullable()
    .when("city", (city, schema) => {
      return schema.test({
        test: (county) =>
          !((city === "" || city === null) && county !== null && county !== ""),
        message: "Nell'indirizzo manca la Città",
      });
    }),
  road: yup
    .string()
    .nullable()
    .when("houseNumber", (houseNumber, schema) => {
      return schema.test({
        test: (road) =>
          !(
            (houseNumber === "" || houseNumber === null) &&
            road !== null &&
            road !== ""
          ),
        message: "Nell'indirizzo manca il numero civico",
      });
    }),
};

const validationInvoicingCustomer = {
  ...validationReceivingCustomer,
  phoneNumber: yup
    .string()
    .required(constants.VALIDATION_MANDATORY_FIELD)
    .nullable(),
};

export const customerValidationType = {
  INVOICING: INVOICING_CUSTOMER,
  RECEIVING: RECEIVING_CUSTOMER,
};

export const validationSubscriptionState = yup.object().shape({
  invoicingCustomer: yup
    .object()
    .nullable()
    .required("Selezionare il cliente pagante"),
  paymentProvider: yup
    .object()
    .nullable()
    .required("Selezionare il metodo di pagamento"),
  plan: yup
    .object()
    .nullable()
    .required("Selezionare il piano per l'abbonamento"),
  scheduledDateEnabled: yup.boolean(),
  scheduledDate: yup.date().when("scheduledDateEnabled", {
    is: true,
    then: yup
      .date()
      .nullable()
      .required("Immettere una data per l'abbonamento")
      .test(
        "past-date",
        "La data dell'abbonamento schedulato deve essere nel futuro",
        (value, context) => {
          // Not using min to delay evaluation of new Date
          // This way we can mock Date
          const isFuture = value > new Date();
          return isFuture;
        }
      ),
    otherwise: yup.date().nullable(),
  }),
});

const customerValidationMap = {
  [customerValidationType.RECEIVING]: validationReceivingCustomer,
  [customerValidationType.INVOICING]: validationInvoicingCustomer,
};

export function getCustomerValidationResolver(validationType) {
  const v = customerValidationMap[validationType];
  return yupResolver(yup.object().shape(v));
}
