import { Elements } from "@stripe/react-stripe-js";
import React from "react";
import Stripe from "../api/stripe";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(Stripe.apiKey);

// Wrapper required to use Stripe Elements
function StripeElementsWrapper({ children }) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}

export default StripeElementsWrapper;
