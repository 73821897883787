import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import React from "react";
import Row from "react-bootstrap/Row";
import addBsArrowRight from "../components/AddBsArrowRight";
import dropDownHeaderView from "../components/DropDownHeaderView";
import { getCustomerUrl } from "../api/urls";
import MainTable from "./MainTable";

const handleColumn = () => {};

function CustomerCheckInsList({ columns, data, customer }) {
  return (
    <>
      <Row>
        <Col xs={8}>
          <h3 className="text-center"> Elenco Check-In Cliente </h3>
        </Col>
        <Col xs={4}>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <ButtonGroup>
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="outline-primary"
              >
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item href={getCustomerUrl(customer?.uuid)}>
                  {addBsArrowRight("Cliente")}
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Nome: </h6>
          <span>{customer.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{customer.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{customer.email}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>{MainTable(columns, data, handleColumn)}</Row>
    </>
  );
}

export default CustomerCheckInsList;
