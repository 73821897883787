import { LOCATION_DROPDOWN_DATA } from "../../api/queries";
import Navbar from "react-bootstrap/Navbar";
import { useQuery } from "@apollo/client";

function NavUser() {
  // Fetch user data
  const { data, loading, error } = useQuery(LOCATION_DROPDOWN_DATA, {
    fetchPolicy: "cache-first", // This line sets the fetch policy
  });
  // Handle loading state
  if (loading) return <p>Loading...</p>;

  // Handle error state
  if (error) return <p>Error!</p>;

  // Destructure user data
  const {
    userSetting: {
      user: { username },
    },
  } = data;

  return <Navbar.Text className="me-3">{username}</Navbar.Text>;
}

export default NavUser;
