export const CONTACT = "CONTACT";
export const APPOINTMENT = "APPOINTMENT";
export const NOTE = "NOTE";
export const MODIFIED = "MODIFIED";
export const APPOINTMENT_CHANGED = "APPOINTMENT_CHANGED";
export const APPOINTMENT_CANCELED = "APPOINTMENT_CANCELED";

export const activityTypeToNameMap = {
  [CONTACT]: "Contatto cliente",
  [APPOINTMENT]: "Appuntamento",
  [NOTE]: "Nota",
  [MODIFIED]: "Modifica",
  [APPOINTMENT_CHANGED]: "Appuntamento modificato",
  [APPOINTMENT_CANCELED]: "Appuntamento cancellato",
};

export function filterActivityType(
  originalDict,
  allowedList,
  inverted = false
) {
  // Used to filter activityTypeToNameMap
  const filteredDict = {};
  Object.keys(originalDict)
    .filter((key) => allowedList.includes(key))
    .forEach((key) => {
      if (inverted) {
        return (filteredDict[originalDict[key]] = key);
      } else {
        return (filteredDict[key] = originalDict[key]);
      }
    });
  return filteredDict;
}

export const allowedCreateLeadActivities = [CONTACT, NOTE];

export function getAllowedCreateLeadActivity() {
  return filterActivityType(activityTypeToNameMap, allowedCreateLeadActivities);
}
