import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import MainTable from "./MainTable";
import constants from "../constants";
import dropDownHeaderView from "./DropDownHeaderView";
import addBsArrowRight from "./AddBsArrowRight";
import {
  getPrepaidPackageDetailUrl,
  getCustomerUrl,
  getCustomerNewCheckInUrl,
} from "../api/urls";

const handleColumn = () => {};

function PrepaidPackageCheckInsList({
  columns,
  data,
  prepaidPackage,
  counterCheckIn,
}) {
  const customer = prepaidPackage?.customer;

  const enabled =
    prepaidPackage?.state === constants.PREPAID_PACKAGE_STATE_ACTIVE;
  const newCheckIn =
    !customer?.needsUpdate && enabled && customer?.uuid ? (
      <Dropdown.Item href={getCustomerNewCheckInUrl(customer?.uuid)}>
        Nuovo Check-In
      </Dropdown.Item>
    ) : null;

  return (
    <>
      <Row>
        <Col xs={8}>
          <h3 className="text-center"> Elenco Check-In Pacchetto Prepagato </h3>
        </Col>
        <Col xs={4}>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <ButtonGroup>
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="outline-secondary"
              >
                {newCheckIn}
                <Dropdown.Divider />
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item
                  href={getPrepaidPackageDetailUrl(prepaidPackage?.uuid)}
                >
                  {addBsArrowRight("Pacchetto Prepagato")}
                </Dropdown.Item>
                <Dropdown.Item href={getCustomerUrl(customer?.uuid)}>
                  {addBsArrowRight("Cliente")}
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col xs={3}>
          <h6> Data Inizio </h6>
          <span>{prepaidPackage?.startDate} </span>
        </Col>
        <Col xs={3}>
          <h6>Data Scadenza </h6>
          <span>{prepaidPackage?.endDate} </span>
        </Col>
        <Col xs={3}>
          <h6>Piano</h6>
          <span>{prepaidPackage?.packagePlan?.description}</span>
        </Col>
        <Col xs={3}>
          <h6>Sede Corrente</h6>
          <span>{prepaidPackage?.location?.name}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col xs={3}>
          <h6> Sessioni</h6>
          <span>
            {counterCheckIn}/{prepaidPackage?.sessionQuantity}{" "}
          </span>
        </Col>
        <Col xs={3}>
          <h6> Stato </h6>
          <span>{prepaidPackage?.state} </span>
        </Col>
        <Col xs={3}></Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h6>Nome: </h6>
          <span>{customer.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{customer.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{customer.email}</span>
        </Col>
      </Row>

      <div className="mt-4" />

      <Row>{MainTable(columns, data, handleColumn)}</Row>
    </>
  );
}

export default PrepaidPackageCheckInsList;
