import * as yup from "yup";

import {
  APPOINTMENT_CANCELED,
  MODIFIED,
  NOTE,
  activityTypeToNameMap,
  getAllowedCreateLeadActivity,
} from "./common";
import {
  BsArrowRightCircle,
  BsPencil,
  BsPencilSquare,
  BsTrash,
} from "react-icons/bs";
import {
  MUTATION_CREATE_LEAD_ACTIVITY,
  MUTATION_DELETE_LEAD_ACTIVITY,
  MUTATION_EDIT_LEAD_ACTIVITY,
} from "../../api/mutations";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ConfirmActionModal from "../ConfirmActionModal";
import { ErrorMessage } from "../ErrorMessage";
import Form from "react-bootstrap/Form";
import { GET_LEAD_AND_ACTIVITIES_BY_UUID } from "../../api/queries";
import { LOCATIONS_LIST } from "../../api/queries";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const SAVE_STRING = "Salva";
const CREATE_STRING = "Crea";

const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;
const dateFormatter = new Intl.DateTimeFormat(userLocale, {
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hourCycle: "h23",
});

const activityTypeToComponent = {
  MODIFIED: ModifiedActivityContent,
  APPOINTMENT: AppointmentContent,
  APPOINTMENT_CHANGED: AppointmentContent,
  APPOINTMENT_CANCELED: AppointmentContent,
};

const leadActivitySchema = yup.object().shape({
  notes: yup.string().required("Le note sono obbligatorie"),
});

// Every CRUD on Operation on LeadActivity refetches these queries
const refetchLeadActivityOptions = (uuid) => {
  return {
    refetchQueries: () => [
      {
        query: GET_LEAD_AND_ACTIVITIES_BY_UUID,
        variables: { uuid },
      },
    ],
  };
};

const ButtonGroup = styled.span`
  visibility: ${(props) => (props.showEditControls ? "visible" : "hidden")};
`;

function CreateEditLeadActivity({
  leadUuid = null,
  editMode = false,
  activity = null,
  onUpdate = () => {},
  onCreate = () => {},
}) {
  const finalLeadUuid = leadUuid || activity?.lead?.uuid;
  const refetchOptions = refetchLeadActivityOptions(finalLeadUuid);
  const [createEditError, setCreateEditError] = useState(null);

  // Create LeadActivity Mutation
  const [
    createLeadActivityMutation,
    { loading: createLeadActivityLoading, error: createLeadActivityError },
    // Mutations
  ] = useMutation(MUTATION_CREATE_LEAD_ACTIVITY, {
    ...refetchOptions,
  });

  // Edit LeadActivity Mutation
  const [
    editLeadActivityMutation,
    { loading: editLeadActivityLoading, error: editLeadActivityError },
  ] = useMutation(MUTATION_EDIT_LEAD_ACTIVITY, {
    ...refetchOptions,
  });
  // Form validation
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(leadActivitySchema),
    defaultValues: {
      notes: "",
      activityType: NOTE,
    },
  });
  // Runs this only once
  const filteredActivityTypes = useMemo(() => {
    return getAllowedCreateLeadActivity();
  }, []);

  // Edit vs Create setup

  // Loads data if in edit mode
  useEffect(() => {
    if (editMode && activity) {
      setValue("notes", activity.notes);
      setValue("activityType", activity.activityType);
    }
  }, [editMode, activity, setValue]);

  const submitText = editMode ? SAVE_STRING : CREATE_STRING;
  const mutation = editMode
    ? editLeadActivityMutation
    : createLeadActivityMutation;
  const mutationLoading = editMode
    ? editLeadActivityLoading
    : createLeadActivityLoading;
  let mutationError = editMode
    ? editLeadActivityError
    : createLeadActivityError;

  if (!editMode && !!activity) {
    mutationError = "Activity is defined with editModeOn. It should be null";
  }
  if (!finalLeadUuid) {
    mutationError = "lead uuid not provided";
  }
  // Submit

  const onSubmit = async (data) => {
    const variables = {
      activity_type: data.activityType,
      notes: data.notes,
    };
    if (!!editMode) {
      variables["uuid"] = activity.uuid;
    } else {
      variables["lead_uuid"] = finalLeadUuid;
    }

    let response = await mutation({
      variables,
    });
    response =
      response.data.createLeadActivity || response.data.editLeadActivity;
    if (response.errors) {
      setCreateEditError(errors);
    }
    if (!!editMode && !response.errors) {
      onUpdate(response);
    } else {
      onCreate(response);
    }
    reset();
  };
  const buttonSpinner = <Spinner animation="border" variant="light" />;

  return (
    <>
      <Card className="my-4">
        <Card.Header>Nuova Attività</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Select name="activityType" {...register("activityType")}>
                {Object.entries(filteredActivityTypes).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Control
                name="notes"
                as="textarea"
                placeholder="Testo"
                {...register("notes")}
                isInvalid={!!errors["notes"]}
                rows={3}
              />
              <Form.Control.Feedback type="invalid">
                {errors["notes"] && errors["notes"].message}
              </Form.Control.Feedback>
            </Form.Group>
            <ErrorMessage errors={[mutationError, createEditError]} />

            <Button className="mt-3" variant="primary" type="submit">
              {mutationLoading ? buttonSpinner : submitText}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

function ModifiedActivityContent({ activity }) {
  const LOCATION_CHANGED_PAYLOAD = "location";
  const { error: locationsError, data: locationsData } =
    useQuery(LOCATIONS_LIST);

  const locationDict = {};
  locationsData?.locationList.map((item) => {
    return (locationDict[item.uuid] = item.name);
  });

  const changes = activity.payload.map((change, index) => {
    let from = change.from;
    let to = change.to;
    if (change["field"] === LOCATION_CHANGED_PAYLOAD) {
      from = locationDict[from];
      to = locationDict[to];
    }
    return (
      <ListGroup.Item key={index} variant="secondary">
        <BsPencil /> {from} <BsArrowRightCircle /> {to}
      </ListGroup.Item>
    );
  });

  return (
    <>
      <ErrorMessage errors={[locationsError]} />
      <ListGroup>{changes}</ListGroup>
    </>
  );
}

function AppointmentContent({ activity }) {
  if (activity.activityType === APPOINTMENT_CANCELED) {
    return <span>❌ Cancellato</span>;
  }
  const appointmentStartDate = activity?.appointment?.startDatetime;
  const calendarName = activity?.appointment?.calendarName;
  if (appointmentStartDate) {
    const startDate = dateFormatter.format(new Date(appointmentStartDate));
    return (
      <div>
        <span>
          <strong> 🗓️ Data:</strong> {startDate}
        </span>
        <br />
        <span>
          <strong>📍 Sede:</strong> {calendarName}
        </span>
      </div>
    );
  } else {
    return <span> Errore. Data non presente</span>;
  }
}

function DisplayLeadActivity({
  activity,
  onEdit = () => {},
  onDelete = () => {},
}) {
  const [showEditControls, setShowEditControls] = useState(false);
  const invertShowEditControls = () => {
    setShowEditControls(!showEditControls);
  };

  const canDelete =
    !activity.autoGenerated && activity?.activityType !== MODIFIED; // Will change it based on activityType
  const canEdit =
    !activity.autoGenerated && activity?.activityType !== MODIFIED; // Will change it based on activityType

  const creationDate = new Date(activity.creationDate);
  const formattedCreationDate = dateFormatter.format(creationDate);
  const humanReadableActivityType =
    activityTypeToNameMap[activity.activityType];
  const displayUsername = activity.autoGenerated
    ? "🤖"
    : activity?.owner?.username;

  const ContentActivityComponent =
    activityTypeToComponent[activity.activityType];

  let activityContent = null;
  if (ContentActivityComponent) {
    activityContent = (
      <ContentActivityComponent key={activity.uuid} activity={activity} />
    );
  } else {
    activityContent = activity.notes;
  }

  return (
    <Card
      className="my-4"
      key={activity.uuid}
      onMouseEnter={invertShowEditControls}
      onMouseLeave={invertShowEditControls}
    >
      <Card.Header>
        <span>{`${formattedCreationDate} | ${displayUsername} - ${humanReadableActivityType}`}</span>
        <ButtonGroup showEditControls={showEditControls} className="float-end">
          {canDelete && (
            <Button
              variant="light"
              size="sm"
              onClick={() => {
                return onDelete(activity);
              }}
            >
              <BsTrash />
            </Button>
          )}
          {canEdit && (
            <Button variant="light" size="sm" onClick={onEdit}>
              <BsPencilSquare />
            </Button>
          )}
        </ButtonGroup>
      </Card.Header>
      <Card.Body>{activityContent}</Card.Body>
    </Card>
  );
}

function LeadActivity({ activity }) {
  const [isEditmode, setEditMode] = useState(false);
  const [activityToDelete, setActivityToDelete] = useState(null);

  const leadUuid = activity?.lead?.uuid;
  // Delete LeadActivity
  const [
    deleteLeadActivityMutation,
    { error: deleteLeadActivityError, loading: deleteLeadActivityLoading },
  ] = useMutation(MUTATION_DELETE_LEAD_ACTIVITY, {
    ...refetchLeadActivityOptions(leadUuid),
  });

  const confirmationModal = (
    <ConfirmActionModal
      title="Cancella attività"
      message="Vuoi cancellare questa attività?"
      show={!!activityToDelete}
      loading={deleteLeadActivityLoading}
      error={deleteLeadActivityError}
      onConfirm={async () => {
        await deleteLeadActivityMutation({
          variables: {
            uuid: activityToDelete.uuid,
          },
        });
        setActivityToDelete(null);
      }}
      onCancel={() => {
        setActivityToDelete(null);
      }}
    />
  );

  if (isEditmode) {
    return (
      <CreateEditLeadActivity
        activity={activity}
        editMode={true}
        onCancel={() => {
          setEditMode(false);
        }}
        onUpdate={() => {
          setEditMode(false);
        }}
      />
    );
  } else {
    return (
      <>
        <DisplayLeadActivity
          activity={activity}
          onEdit={() => {
            setEditMode(true);
          }}
          onDelete={() => {
            setActivityToDelete(activity);
          }}
        />
        {activityToDelete ? confirmationModal : null}
      </>
    );
  }
}

export { LeadActivity, CreateEditLeadActivity };
