import {
  BsBoxArrowUpRight,
  BsFileEarmark,
  BsFileEarmarkPerson,
  BsPencil,
} from "react-icons/bs";
import { CreateEditLeadActivity, LeadActivity } from "./LeadActivity";
import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { ErrorMessage } from "../ErrorMessage";
import { GET_LEAD_AND_ACTIVITIES_BY_UUID } from "../../api/queries";
import LeadAssignButton from "./LeadAssignButton";
import LeadDetailsEditModal from "./LeadDetailsEditModal";
import ListGroup from "react-bootstrap/ListGroup";
import { Spinner } from "react-bootstrap";
import { formatDateIso } from "../../api/date-utils";
import styled from "@emotion/styled";
import urls from "../../api/urls";
import { useQuery } from "@apollo/client";

const LeadDetail = styled.li`
  list-style: none;
  list-style-position: outside;
`;

const LeadDetailHeader = styled.span`
  font-weight: bold;
  margin-right: 0.5rem;
  color: #888;
`;

const LeadTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeadTitle = styled.h2``;

const LeadIcon = styled(BsFileEarmark)`
  margin-right: 0.3rem;
`;
const CustomerIcon = styled(BsFileEarmarkPerson)`
  margin-right: 0.3rem;
`;

function ExistingLeadItem({ lead }) {
  const leadUrl = `/leads/${lead.uuid}`;
  const creationDateStr = formatDateIso(new Date(lead.creationDate));
  return (
    <ListGroup.Item
      action
      className="d-flex justify-content-between align-items-center"
      onClick={() => window.open(leadUrl, "_blank")}
    >
      <div className="d-flex align-items-center">
        <LeadIcon title="Lead" />
        <span className="mr-2">
          {lead.email} | {creationDateStr}
        </span>
      </div>
      <BsBoxArrowUpRight />
    </ListGroup.Item>
  );
}

function ExistingCustomerItem({ customer }) {
  const customerUrl = `/customers/${customer.uuid}`;
  const creationDateStr = formatDateIso(new Date(customer.timestamp));

  return (
    <ListGroup.Item
      action
      className="d-flex justify-content-between align-items-center"
      onClick={() => window.open(customerUrl, "_blank")}
    >
      <div className="d-flex align-items-center">
        <CustomerIcon title="Cliente" />
        <span className="mr-2">
          {customer.fullName} | {creationDateStr}
        </span>
      </div>
      <BsBoxArrowUpRight />
    </ListGroup.Item>
  );
}

function ExistingLeadsCustomers({ leads, customers }) {
  if (!leads?.length && !customers?.length) {
    return null;
  }

  return (
    <>
      <h3>Contatti Esistenti</h3>
      <ListGroup>
        {leads?.map((lead) => (
          <ExistingLeadItem key={lead.uuid} lead={lead} />
        ))}
        {customers?.map((customer) => (
          <ExistingCustomerItem key={customer.uuid} customer={customer} />
        ))}
      </ListGroup>
    </>
  );
}

function LeadDetails({ leadDetails, leadUuid = null }) {
  const finalLeadUuid = leadDetails?.uuid || leadUuid;
  const noLeadToLoad = !leadDetails?.uuid && !leadUuid;
  // Queries
  const {
    loading: leadLoading,
    error: leadError,
    data: leadData,
  } = useQuery(GET_LEAD_AND_ACTIVITIES_BY_UUID, {
    variables: { uuid: finalLeadUuid },
    skip: noLeadToLoad,
  });
  const [showEditLeadDetailsModal, setShowEditLeadDetailsModal] =
    useState(false);

  let finalLeadDetails = leadData?.getLeadByUuid || leadDetails;

  if (leadLoading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (noLeadToLoad) {
    return <h2>Seleziona una lead</h2>;
  }

  const leadDisplayData = finalLeadDetails;
  const leadActivityListData = leadData?.leadActivityList ?? [];
  const leadUrl = urls.URL_SINGLE_LEAD.replace(":leadUuid", finalLeadUuid);
  return (
    <>
      <ErrorMessage errors={[leadError]} />
      <LeadTitleContainer>
        <LeadTitle>{leadDisplayData?.fullName}</LeadTitle>

        <ButtonGroup className="ml-4">
          <LeadAssignButton
            leadUuid={finalLeadDetails?.uuid}
            leadUsername={leadDisplayData?.owner?.username}
          />
          <Button
            onClick={() => {
              setShowEditLeadDetailsModal(true);
            }}
            size="sm"
            variant="light"
            title="Modifica lead"
          >
            <BsPencil /> Modifica
          </Button>
          <Button
            href={leadUrl}
            target="_blank"
            variant="light"
            title="Link alla lead"
          >
            <BsBoxArrowUpRight />
          </Button>
        </ButtonGroup>
      </LeadTitleContainer>
      <ul className="px-0">
        <LeadDetail>
          <LeadDetailHeader>Telefono:</LeadDetailHeader>
          {leadDisplayData?.phoneNumber}
        </LeadDetail>
        <LeadDetail>
          <LeadDetailHeader>Email:</LeadDetailHeader>
          {leadDisplayData?.email}
        </LeadDetail>
        <LeadDetail>
          <LeadDetailHeader>Sede Assegnata:</LeadDetailHeader>
          {leadDisplayData?.location?.name}
        </LeadDetail>
        <LeadDetail>
          <LeadDetailHeader>Sede Originale:</LeadDetailHeader>
          {leadDisplayData?.originalLocation?.name}
        </LeadDetail>
      </ul>
      <hr></hr>
      <ul className="px-0">
        <LeadDetail>
          <LeadDetailHeader>Sorgente:</LeadDetailHeader>
          {leadDisplayData?.source?.name}
        </LeadDetail>
        <LeadDetail>
          <LeadDetailHeader>Campagna:</LeadDetailHeader>
          {leadDisplayData?.campaignName}
        </LeadDetail>
        <LeadDetail>
          <LeadDetailHeader>Ad Set:</LeadDetailHeader>
          {leadDisplayData?.adSetName}
        </LeadDetail>
        <LeadDetail>
          <LeadDetailHeader>Ad:</LeadDetailHeader>
          {leadDisplayData?.adName}
        </LeadDetail>
        <LeadDetail>
          <LeadDetailHeader>Data:</LeadDetailHeader>
          {leadDisplayData?.creationDate}
        </LeadDetail>
        <LeadDetail>
          <LeadDetailHeader>Assegnatario:</LeadDetailHeader>
          {leadDisplayData?.owner?.username}
        </LeadDetail>
      </ul>
      <hr></hr>
      <ExistingLeadsCustomers
        leads={finalLeadDetails?.matchingLeads}
        customers={finalLeadDetails?.matchingCustomers}
      />
      <hr></hr>
      <h3>Attività</h3>
      <div>
        <CreateEditLeadActivity leadUuid={finalLeadDetails?.uuid} />
        <LeadActivities leadActivityList={leadActivityListData} />
      </div>
      <LeadDetailsEditModal
        show={showEditLeadDetailsModal}
        onHide={() => {
          setShowEditLeadDetailsModal(false);
        }}
        leadDetails={leadDisplayData}
        onUpdate={(data) => {
          setShowEditLeadDetailsModal(false);
        }}
      />
    </>
  );
}

function LeadActivities({ leadActivityList }) {
  let leadActivities = <span>🫤 Nessuna</span>;
  if (leadActivityList?.length > 0) {
    leadActivities = leadActivityList.map((activity) => (
      <LeadActivity className="my-4" key={activity.uuid} activity={activity} />
    ));
  }
  return leadActivities;
}

export default LeadDetails;
