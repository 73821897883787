import "./App.css";

import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { ErrorMessage } from "../components/ErrorMessage";
import { GET_STRIPE_INVOICE } from "../api/queries";
import InvoiceDetailForm from "../components/InvoiceDetailForm";
import { MUTATION_CREDIT_NOTE_STRIPE_INVOICE } from "../api/mutations";
import { MUTATION_MARK_UNCOLLECTIBLE_STRIPE_INVOICE } from "../api/mutations";
import { MUTATION_PAY_STRIPE_INVOICE } from "../api/mutations";
import { MUTATION_VOID_STRIPE_INVOICE } from "../api/mutations";
import { MUTATION_UPDATE_EXPIRED_INVOICE } from "../api/mutations";
import { Navigate } from "react-router";
import { QUERY_GET_SUBSCRIPTION } from "../api/queries";
import { getCustomerUrl } from "../api/urls";
import { isProd } from "../api/utils";

import { useParams } from "react-router-dom";

function InvoiceDetailPage() {
  let { subscriptionUuid } = useParams();
  let { stripeInvoiceId } = useParams();

  const [completed, setCompleted] = useState(false);
  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);

  const [
    retryChargeInvoiceMutation,
    { loading: retryChargeLoading, error: retryChargeError },
  ] = useMutation(MUTATION_PAY_STRIPE_INVOICE, {
    onCompleted: () => {
      setCompleted(true);
    },
  });
  const [voidInvoiceMutation, { loading: voidLoading, error: voidError }] =
    useMutation(MUTATION_VOID_STRIPE_INVOICE, {
      onCompleted: () => {
        setCompleted(true);
      },
    });
  const [
    markUncollectibleInvoiceMutation,
    { loading: markUncollectibleLoading, error: markUncollectibleError },
  ] = useMutation(MUTATION_MARK_UNCOLLECTIBLE_STRIPE_INVOICE, {
    onCompleted: () => {
      setCompleted(true);
    },
  });
  const [
    creditNoteInvoiceMutation,
    { loading: creditNoteLoading, error: creditNoteError },
  ] = useMutation(MUTATION_CREDIT_NOTE_STRIPE_INVOICE, {
    onCompleted: () => {
      setCompleted(true);
    },
  });

  const [
    updateExpiredInvoiceMutation,
    { loading: expiredInvoiceLoading, error: expiredInvoiceError },
  ] = useMutation(MUTATION_UPDATE_EXPIRED_INVOICE, {
    onCompleted: () => {
      setCompleted(true);
    },
  });

  const LIMIT_MAX_RECORD = 0;

  const { loading, error, data } = useQuery(QUERY_GET_SUBSCRIPTION, {
    variables: {
      subscriptionUuid: subscriptionUuid,
      checkInMaxLimit: LIMIT_MAX_RECORD,
    },
  });

  const userSetting = data?.userSetting;

  const {
    loading: invoiceLoading,
    error: invoiceError,
    data: invoiceData,
  } = useQuery(GET_STRIPE_INVOICE, {
    variables: {
      invoiceId: stripeInvoiceId,
      subscriptionUuid: subscriptionUuid,
    },
  });

  if (
    loading ||
    invoiceLoading ||
    retryChargeLoading ||
    voidLoading ||
    markUncollectibleLoading ||
    creditNoteLoading ||
    expiredInvoiceLoading
  ) {
    return <p>Loading..</p>;
  }

  if (isProd()) {
    if (
      (!!error,
      !!retryChargeError ||
        !!voidError ||
        !!markUncollectibleError ||
        !!creditNoteError ||
        !!invoiceError) ||
      !!expiredInvoiceError
    ) {
      return (
        <ErrorMessage
          errors={[
            error,
            retryChargeError,
            voidError,
            markUncollectibleError,
            creditNoteError,
            invoiceError,
            expiredInvoiceError,
          ]}
        />
      );
    }
  }

  let subscriptionData = data?.subscription;
  const customerUuid = !!subscriptionData?.customer?.uuid
    ? subscriptionData?.customer?.uuid
    : subscriptionData?.invoicingCustomer?.uuid;

  let redirectContinueToList = null;
  if (!!shouldRedirectToList) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: `${getCustomerUrl(customerUuid)}`,
        }}
      />
    );
  }

  return (
    <>
      <InvoiceDetailForm
        data={subscriptionData}
        invoiceData={invoiceData}
        retryChargeMutation={retryChargeInvoiceMutation}
        retryChargeUpdating={retryChargeLoading}
        retryChargeError={retryChargeError}
        voidInvoiceMutation={voidInvoiceMutation}
        voidUpdating={voidLoading}
        voidError={voidError}
        markUncollectibleInvoiceMutation={markUncollectibleInvoiceMutation}
        markUncollectibleUpdating={markUncollectibleLoading}
        markUncollectibleError={markUncollectibleError}
        creditNoteInvoiceMutation={creditNoteInvoiceMutation}
        creditNoteUpdating={creditNoteLoading}
        creditNoteError={creditNoteError}
        updateExpiredInvoiceMutation={updateExpiredInvoiceMutation}
        expiredInvoiceLoading={expiredInvoiceLoading}
        expiredInvoiceError={expiredInvoiceError}
        completed={completed}
        modalContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
        userSetting={userSetting}
      />
      {redirectContinueToList}
    </>
  );
}

export default InvoiceDetailPage;
