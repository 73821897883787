import "./App.css";

import ActionsButton from "../components/ActionsButton";
import { ErrorMessage } from "../components/ErrorMessage";
import { QUERY_UNPAIDSUBSCRIPTIONS } from "../api/queries";
import React from "react";
import UnpaidInvoicesList from "../components/UnpaidInvoicesList";
import urls from "../api/urls";
import { useQuery } from "@apollo/client";

function UnpaidInvoicesListPage() {
  const popoverLinksList = (cell) => {
    // builds the url to display the list of Payments
    let urlPaymentList = urls.URL_SUBSCRIPTION_INVOICES_LIST.replace(
      ":subscriptionUuid",
      cell?.subscriptionUuid
    );

    const linksList = [
      {
        title: "Visualizza Abbon. STRIPE",
        url: `${urls.URL_STRIPE_CUSTOMER}${cell?.customerStripeId}`,
      },
      {
        title: "Visualizza Abbonamento",
        url: `${urls.URL_SUBSCRIPTION_DETAIL}${cell?.subscriptionUuid}`,
      },
      {
        title: "Visualizza Cliente",
        url: `${urls.URL_CUSTOMER_DETAIL}${cell?.invoicingCustomerUuid}`,
      },
      {
        title: "Visualizza Elenco Pagamenti",
        url: urlPaymentList,
      },
      {
        title: "Visualizza Piano",
        url: `${urls.URL_PLAN_DETAIL}${cell?.planUuid?.toString()}`,
      },
    ];
    return linksList;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Data",
        accessor: "invoiceDateEnd",
        width: 28,
      },
      {
        Header: "Cliente",
        accessor: "invoicingCustomerFullName",
        width: 50,
      },
      {
        Header: "Piano",
        accessor: "planName",
        width: 65,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Importo
          </div>
        ),
        accessor: "attemptedAmount",
        width: 20,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Motivo",
        accessor: "reason",
        width: 50,
      },
      {
        Header: "",
        accessor: "menu",
        width: 15,
        Cell: ({ cell }) => {
          const links = popoverLinksList(cell?.row?.original);
          return <ActionsButton linksList={links} />;
        },
      },
    ],
    []
  );

  const { loading, error, data } = useQuery(QUERY_UNPAIDSUBSCRIPTIONS);

  if (loading) {
    return <p>Loading..</p>;
  }

  let invoiceList = null;
  if (!!data) {
    invoiceList = data?.unpaidSuscriptions?.result.map((s) => {
      return {
        invoiceDateEnd: s?.invoiceDateEnd,
        invoicingCustomerFullName: s?.subscription?.invoicingCustomer?.fullName,
        invoicingCustomerEmail: s?.subscription?.invoicingCustomer?.email,
        planName: s?.subscription?.plan?.name,
        attemptedAmount: s?.attemptedAmount,
        reason: s?.reason,
        invoicingCustomerUuid: s?.subscription?.invoicingCustomer?.uuid,
        subscriptionUuid: s?.subscription?.uuid,
        planUuid: s?.subscription?.plan?.uuid,
        customerStripeId: s?.subscription?.invoicingCustomer?.stripeId,
      };
    });
  } else {
    const invList = [
      {
        invoiceDateEnd: null,
        invoicingCustomerFullName: null,
        invoicingCustomerEmail: null,
        planName: null,
        attemptedAmount: null,
        reason: null,
        invoicingCustomerUuid: null,
        subscriptionUuid: null,
        planUuid: null,
        customerStripeId: null,
      },
    ];

    invoiceList = invList;
  }

  return (
    <>
      <UnpaidInvoicesList columns={columns} invoiceList={invoiceList} />
      <ErrorMessage errors={[error, data?.unpaidSuscriptions?.error]} />
    </>
  );
}

export default UnpaidInvoicesListPage;
