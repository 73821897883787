import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import {
  useTable,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
} from "react-table";
import constants from "../constants";
import dropDownHeaderView from "../components/DropDownHeaderView";
import addBsArrowRight from "../components/AddBsArrowRight";
import {
  getSubscriptionDetailUrl,
  getCustomerUrl,
  getPlanUrl,
  getSubscriptionCheckinsListUrl,
} from "../api/urls";
import { subscriptionItalianState } from "../api/utils";
function SubscriptionInvoicesListForm({
  columns,
  invoiceList,
  subscription,
  invoiceCounterExpired,
  ContinueClicked = () => {},
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: invoiceList,
      },
      useSortBy,
      useFlexLayout,
      useResizeColumns
    );

  //Calculate the number of installments paid

  const countInstallments = !!invoiceList
    ? invoiceList.reduce(function (n, item) {
        return n + (item.paidAt !== null);
      }, 0)
    : null;
  //Calculate the number of CheckIns made
  const countCheckIn = !!invoiceList
    ? invoiceList.reduce(function (n, item) {
        return n + item.checkinCount;
      }, 0)
    : null;

  //Calculate the number of CheckIns made Not Present
  const countCheckInNoPresent = !!invoiceList
    ? invoiceList.reduce(function (n, item) {
        return n + item.checkinCountNoPresent;
      }, 0)
    : null;

  const dropdownCustomer =
    subscription?.customer?.uuid ===
    subscription?.invoicingCustomer?.uuid ? null : (
      <Dropdown.Item href={getCustomerUrl(subscription?.customer?.uuid)}>
        {addBsArrowRight("Beneficiario")}
      </Dropdown.Item>
    );

  return (
    <>
      <Row>
        <Col xs={8}>
          <h3 className="text-center"> Elenco Pagamenti Abbonamento</h3>
        </Col>
        <Col xs={4}>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <ButtonGroup>
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="outline-primary"
              >
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item
                  href={getSubscriptionDetailUrl(subscription?.uuid)}
                >
                  {addBsArrowRight("Abbonamento")}
                </Dropdown.Item>
                <Dropdown.Item
                  href={getCustomerUrl(subscription?.invoicingCustomer?.uuid)}
                >
                  {addBsArrowRight("Cliente")}
                </Dropdown.Item>
                {dropdownCustomer}
                <Dropdown.Item href={getPlanUrl(subscription?.plan?.uuid)}>
                  {addBsArrowRight("Piano")}
                </Dropdown.Item>
                <Dropdown.Item
                  href={getSubscriptionCheckinsListUrl(subscription?.uuid)}
                >
                  {addBsArrowRight("Elenco Check-In")}
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6> Nome: </h6>
          <span>{subscription?.invoicingCustomer?.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{subscription?.invoicingCustomer?.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{subscription?.invoicingCustomer?.email}</span>
        </Col>
      </Row>
      <hr />

      <h5> Abbonamento: Data Inizio {subscription?.startDate}</h5>
      <Row>
        <Col>
          <h6>Piano</h6>
          <span>{subscription?.plan?.name}</span>
        </Col>
        <Col>
          <h6>Servizio di Pagamento</h6>
          <span>{subscription?.paymentType}</span>
        </Col>
        <Col>
          <h6>Stato</h6>
          <span>{subscriptionItalianState[subscription?.state]}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Rate pagate</h6>
          <span> {countInstallments}</span>
        </Col>
        <Col>
          <h6>CheckIn effettuati</h6>
          <span> {countCheckIn}</span>
        </Col>
        <Col>
          <h6>CheckIn Non Presente</h6>
          <span> {countCheckInNoPresent}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Rate scadute</h6>
          <span> {invoiceCounterExpired}</span>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <Table striped bordered hover size="sm" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => {
                const { key, ...restHeaderGroupProps } =
                  headerGroup.getHeaderGroupProps();
                return (
                  <tr key={key} {...restHeaderGroupProps}>
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumnProps } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <th key={key} {...restColumnProps}>
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? constants.DESCENDING_SORT_SIMBOL
                                : constants.ASCENDING_SORT_SIMBOL
                              : ""}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();
                return (
                  <tr key={key} {...restRowProps}>
                    {row.cells.map((cell) => {
                      const { key, ...restCellProps } = cell.getCellProps();
                      return (
                        <td key={key} {...restCellProps}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

export default SubscriptionInvoicesListForm;
