import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import React, { useState } from "react";
import { creditItalianState, loadCreditItalianState } from "../api/utils";

import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import MainTable from "./MainTable";
import Row from "react-bootstrap/Row";
import urls from "../api/urls";
import { useNavigate } from "react-router-dom";

function buildUrlListCredit(page, filter, order = "") {
  let params;
  params = new URLSearchParams({
    page,
    filter,
    order,
  });

  return `${urls.URL_LIST_CREDIT}?${params.toString()}`;
}

function lookUpFieldSort(val) {
  const lookup = {
    startDate: "start_date",
    creditType: "credit_type__name",
    planName: "plan__name",
    checkinCount: "count_checkin",
    customerFullName: "customer_fullname",
    stateIt: "state",
    lastCheckIn: "last_checkin",
  };
  let result = lookup[val];

  result = typeof result === "undefined" ? "" : result;
  return result;
}

function loadQueryDescription(filter) {
  //Build description of query
  var arrayFilter = filter.split(":");
  let queryCredit = "";
  switch (arrayFilter[0]) {
    case "state":
      queryCredit = `Stato - ${creditItalianState[arrayFilter[1]]}`;
      break;
    case "type":
      queryCredit = `Tipo -${arrayFilter[1]}`;
      break;
    default:
  }

  return `Filtro: ${queryCredit}`;
}

function CreditList({
  columns,
  data,
  listCreditType,
  paginator,
  filter,
  order,
}) {
  //---------------------------------------------------
  const [typeOrder, setTypeOrder] = useState(order);
  const navigate = useNavigate();

  let page = paginator?.page;
  let hasNext = paginator?.hasNext;
  let hasPrev = paginator?.hasPrev;

  const arrayCreditItalianState = loadCreditItalianState();
  const queryCredit = loadQueryDescription(filter);

  let url;
  //----------------------------------------------------------
  url = buildUrlListCredit(page + 1, filter, order);
  const showNextPage = true;
  const nextPage = showNextPage ? (
    <Button variant="outline-info" disabled={!hasNext} action="true" href={url}>
      Avanti{" "}
      <Badge variant="info">
        <AiFillCaretRight />
      </Badge>
    </Button>
  ) : null;

  //----------------------------------------------------------
  const showPreviusPage = true;
  url = buildUrlListCredit(page - 1, filter, order);
  const previusPage = showPreviusPage ? (
    <Button variant="outline-info" disabled={!hasPrev} action="true" href={url}>
      <Badge variant="info">
        <AiFillCaretLeft />
      </Badge>{" "}
      Indietro
    </Button>
  ) : null;

  const handleSelect = (e, eventKey) => {
    url = buildUrlListCredit("1", e);
    navigate(url);
  };

  const handleColumn = (col) => {
    // Get column name
    const fieldSort = lookUpFieldSort(col?.id);
    //Check if the column contains sortable data
    if (fieldSort !== "") {
      let stringOrder = "";
      // Check if it is the same column chose earlier
      if (typeOrder === fieldSort) {
        //Swap the sort type from the previous choice
        const typeOrderFirstChar = typeOrder.substr(0, 1);
        if (typeOrderFirstChar === "-") {
          stringOrder = "";
        } else if (typeOrderFirstChar !== "-") {
          stringOrder = "-";
        }
      }
      // Set the sort parameter
      const paramOrder = `${stringOrder}${fieldSort}`;
      // Save the sort parameter
      setTypeOrder(paramOrder);
      //Call up the list of credits
      if (paramOrder !== "") {
        url = buildUrlListCredit("1", filter, paramOrder);
        navigate(url);
      }
    }
  };

  return (
    <>
      <h3 className="text-center">Elenco Crediti</h3>
      <hr></hr>
      <Row>
        <Col xs={8}>
          <ButtonGroup size="sm">
            {nextPage} <Col></Col>
            {previusPage} <Col></Col>
          </ButtonGroup>
          <ButtonGroup size="sm">
            <Col xs={3}>
              <div style={{ textAlign: "center" }}>
                <DropdownButton
                  onSelect={handleSelect}
                  title="Stato"
                  id="sm-nested-dropdown"
                  size="sm"
                  variant="outline-primary"
                >
                  {arrayCreditItalianState.map((s, index) => (
                    <Dropdown.Item
                      key={index}
                      value={s.key}
                      eventKey={`state:${s.key}`}
                    >
                      {s.state}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
            </Col>
          </ButtonGroup>
          <ButtonGroup size="sm">
            <Col xs={3}>
              <div style={{ textAlign: "center" }}>
                <DropdownButton
                  onSelect={handleSelect}
                  title="Tipo"
                  id="sm-nested-dropdown"
                  size="sm"
                  variant="outline-primary"
                >
                  {listCreditType.map((s, index) => (
                    <Dropdown.Item
                      key={index}
                      value={s.key}
                      eventKey={`type:${s.name}`}
                    >
                      {s.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
            </Col>
          </ButtonGroup>
        </Col>
        <Col xs={4}>
          <Alert key={1} variant="outline-info" size="sm-12">
            {queryCredit}
          </Alert>
        </Col>
      </Row>
      <Row>{MainTable(columns, data, handleColumn)}</Row>
      <Row>
        <Col xs={8}>
          <ButtonGroup size="sm">
            {nextPage} <Col></Col>
            {previusPage} <Col></Col>
          </ButtonGroup>
        </Col>
        <Col xs={4}></Col>
      </Row>
      <hr></hr>
    </>
  );
}

export default CreditList;
