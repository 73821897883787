import CustomerListForm from "../components/CustomerListForm";
import { ErrorMessage } from "../components/ErrorMessage";
import { QUERY_ALL_CUSTOMER } from "../api/queries";
import React from "react";
import { getCustomerUrl } from "../api/urls";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

const columns = [
  {
    Header: "Cognome",
    accessor: "last",
  },
  {
    Header: "Nome",
    accessor: "first",
    filter: "fuzzyText",
  },
  {
    Header: "Email",
    accessor: "email",
    filter: "fuzzyText",
  },
];

function CustomerListPage() {
  const { loading, error, data } = useQuery(QUERY_ALL_CUSTOMER);
  const navigate = useNavigate();

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  function openCustomer(values) {
    navigate(getCustomerUrl(values.uuid));
  }

  function onCreatedCustomer(customer) {
    navigate(getCustomerUrl(customer?.uuid));
  }

  let customerData = data?.allCustomer ? data?.allCustomer : [];
  const referralSourcesList = data?.referralSources;
  return (
    <>
      <CustomerListForm
        data={customerData}
        columns={columns}
        openCustomer={openCustomer}
        onCreatedCustomer={onCreatedCustomer}
        globalFilter={false}
        referralSourcesList={referralSourcesList}
      />
    </>
  );
}
export default CustomerListPage;
