import { Navigate } from "react-router-dom";
import React from "react";
import auth from "../api/auth";
import urls from "../api/urls";

function MainPage() {
  let redirectPath = urls.URL_SUBSCRIPTION_NEW;
  if (!auth.isAuthenticated()) {
    redirectPath = urls.URL_LOGIN_PATH;
  }

  return (
    <Navigate
      to={{
        pathname: redirectPath,
      }}
    />
  );
}

export default MainPage;
