import {
  MUTATION_SAFE_CANCEL_INVOICE,
  MUTATION_UPDATE_INVOICE,
} from "../api/mutations";
import React, { useState } from "react";
import { getCheckinDetailUrl, getReceiptDetailUrl } from "../../api/urls";
import { useMutation, useQuery } from "@apollo/client";

import { ErrorMessage } from "../../components/ErrorMessage";
import { GET_INVOICE } from "../api/queries";
import { Navigate } from "react-router";
import ReceiptUpdateForm from "../components/ReceiptUpdateForm";
import { useParams } from "react-router-dom";

function ReceiptUpdatePage() {
  let { receiptUuid } = useParams();

  const [
    updateReceiptMutation,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_UPDATE_INVOICE);

  const [
    handleReceiptMutation,
    { loading: handleLoading, error: handleError, data: handleData },
  ] = useMutation(MUTATION_SAFE_CANCEL_INVOICE);

  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);

  const { loading, error, data } = useQuery(GET_INVOICE, {
    variables: {
      invoiceUuid: receiptUuid,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error || handleError || mutationError) {
    return <ErrorMessage errors={[error]} />;
  }
  const currentLocation = data?.userSetting?.currentLocation;
  const receiptData = data?.getInvoice;
  const receiptItem = data?.getItemsForInvoice[0];
  const creditNote = data?.getCreditNoteForInvoice;
  const checkin = data?.getInvoice?.checkin;

  let redirectHandleCompleted = null;
  if (!!handleData) {
    redirectHandleCompleted = (
      <Navigate
        to={{
          pathname: getCheckinDetailUrl(checkin?.uuid),
        }}
      />
    );
  }

  let redirectContinueToList = null;
  if (!!shouldRedirectToList) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: getReceiptDetailUrl(receiptData?.uuid),
        }}
      />
    );
  }

  return (
    <>
      <ReceiptUpdateForm
        receiptData={receiptData}
        receiptItem={receiptItem}
        creditNote={creditNote}
        receiptCheckin={checkin}
        //
        updateReceipt={updateReceiptMutation}
        handleReceipt={handleReceiptMutation}
        creating={mutationLoading}
        completed={!!mutationData}
        createError={mutationError}
        handling={handleLoading}
        modalContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
        currentLocation={currentLocation}
      />
      {redirectHandleCompleted}
      {redirectContinueToList}
    </>
  );
}

export default ReceiptUpdatePage;
