import React, { useState } from "react";

function ErrorFallback({ error, resetErrorBoundary }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div role="alert">
      <p>Errore Frontend:</p>
      <pre>
        <code>{error.message}</code>
      </pre>
      {isExpanded && (
        <pre>
          <code>{error.stack}</code>
        </pre>
      )}
      <button onClick={() => setIsExpanded(!isExpanded)}>Stack trace</button>
      <button onClick={() => resetErrorBoundary()}>Try again</button>
    </div>
  );
}
export default ErrorFallback;
