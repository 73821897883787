import React from "react";
import CustomerCheckInDetailForm from "../components/CustomerCheckInDetailForm";
import { QUERY_GET_CHECK_IN } from "../api/queries";
import { useQuery, useMutation } from "@apollo/client";

import { ErrorMessage } from "../components/ErrorMessage";
import { useParams } from "react-router-dom";
import { MUTATION_UPDATE_STATE_CHECKIN } from "../api/mutations";
import constants from "../constants";

function CustomerCheckInDetailPage() {
  let { checkinUuid } = useParams();

  const {
    loading,
    error,
    data: queryData,
  } = useQuery(QUERY_GET_CHECK_IN, {
    variables: {
      checkinUuid: checkinUuid,
    },
  });
  const [
    updateStateCheckIn,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_UPDATE_STATE_CHECKIN);

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error | mutationError) {
    return <ErrorMessage errors={[error]} />;
  }

  const currentLocation = queryData?.userSetting?.currentLocation;

  const stateCheckIn =
    queryData?.checkin?.checkInStatus ===
    constants.CHECK_IN_STATUS_TYPE_PENDING;

  return (
    <>
      <CustomerCheckInDetailForm
        data={queryData}
        currentLocation={currentLocation}
        updateStateCheckIn={updateStateCheckIn}
        creating={mutationLoading}
        completed={!!mutationData}
        stateCheckIn={stateCheckIn}
      />
    </>
  );
}

export default CustomerCheckInDetailPage;
