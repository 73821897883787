import StateIcon from "../src/components/StateIcon";
const SENTRY_DSN =
  "https://a63b56e41be3426fa56737f4f6295a2a@o394190.ingest.sentry.io/5244019";
const GOOGLE_ANALYTICS_ID = "G-JY5MWJ9NT7";

// Location pre-loaded with a fixture
const DEFAULT_LOCATION_UUID = "6a37a25e-a669-47e1-9a0a-a5a8d3954acc";
// UUID of a subscription funding source
const CHECKIN_FUNDING_SUBSCRIPTION = "01dfa586-6488-45b1-9d08-e1c99272786a";
// UUID of a credit funding source
const CHECKIN_FUNDING_CREDIT = "b8b8b5ce-1f79-4040-840a-b64fda1cb863";
// UUID of a prepaid package funding source
const CHECKIN_FUNDING_PREPAID_PACKAGE = "ed36b5a5-4f6d-426e-99eb-38b345c3669e";
// UUID of a paid access funding source
const CHECKIN_FUNDING_PAID_ACCESS = "f413f177-b497-48c1-9cf3-04f393cca23c";
// UUID of a consultant funding source
const CHECKIN_FUNDING_CONSULTANT = "2681b26e-35e1-4436-980e-9bb6e841ecbe";
// UUID of a Station for loaded arrears Check-In
const DEFAULT_STATION_UUID = "c58a218d-2a60-4f2a-be51-aebb49e0358f";
// UUID of a Worker for loaded arrears Check-In
const DEFAULT_WORKER_UUID = "dd786c06-5abc-4a2d-b8ee-659171f268f7";
const ErrorCodes = {
  STRIPE_REQUIRES_PAYMENT_METHOD: "stripe_requires_payment_method",
  STRIPE_REQUIRES_ACTION: "stripe_requires_action",
  GENERIC_ERROR: "generic_error",
  CUSTOMER_NOT_FOUND: "non_existing_customer",
};

const STRIPE_PAYMENT_METHOD = "STRIPE";
const STRIPE_STATUS_SUBSCRIPTION_CANCELED = "CANCELED";

const REGEX_CF =
  /\s*$|^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
const VALIDATION_MANDATORY_FIELD = "Campo obbligatorio";
const MISSING_EMAIL_MARKER = "medex.net";
const STRING_NOT_FOUND = -1;
const PRODUCT_HAIR_REMOVAL = "b4dc693a-c760-41da-9cdd-780d392a6562";
const FOUNDING_SOURCE_FREE_TRIAL = "64b53803-4893-490b-b6db-71eb2d9b4c36";
const FOUNDING_SOURCE_GIFT = "34662001-4f96-4de9-9409-e4b24e981c83";

const paymentProviders = {
  STRIPE: "stripe",
  PAGODIL: "pagodil",
};
const TYPE_CHECKINLIST_SUBSCRIPTION = "s";
const TYPE_CHECKINLIST_FOUNDINGSOURCE = "a";
const TYPE_CHECKINLIST_CREDIT = "c";
const TYPE_CHECKINLIST_PREPAID_PACKAGE = "p";
const ASCENDING_SORT_SIMBOL = " 🔼";
const DESCENDING_SORT_SIMBOL = " 🔽";

const MANDATORY_NOTES = 1;
const NO_MANDATORY_NOTES = 0;

const SUBSCRIPTION_STATE_ACTIVE = "ACTIVE";
const SUBSCRIPTION_STATE_PENDING = "PENDING";
const SUBSCRIPTION_STATE_PAUSED = "PAUSED";
const SUBSCRIPTION_STATE_SCHEDULED = "SCHEDULED";
const SUBSCRIPTION_STATE_CANCELED = "CANCELED";
const SUBSCRIPTION_STATE_NEEDS_AUTH = "NEEDS_AUTH";

const SUBSCRIPTION_STATEPAID_PAID = "PAID";
const SUBSCRIPTION_STATEPAID_UNPAID = "UNPAID";

const CREDIT_STATE_ACTIVE = "ACTIVE";
const CREDIT_STATE_CLOSED = "CLOSED";
const CREDIT_STATE_EXPIRED = "EXPIRED";
const CREDIT_STATE_CANCELED = "CANCELED";

const CREDIT_STATE_ACTIVE_KEY = 1;
const CREDIT_STATE_CLOSED_KEY = 2;
const CREDIT_STATE_EXPIRED_KEY = 3;
const CREDIT_STATE_CANCELED_KEY = 5;

const PREPAID_PACKAGE_STATE_ACTIVE = "ACTIVE";
const PREPAID_PACKAGE_STATE_CLOSED = "CLOSED";
const PREPAID_PACKAGE_STATE_EXPIRED = "EXPIRED";
const PREPAID_PACKAGE_STATE_CANCELED = "CANCELED";

const PREPAID_PACKAGE_STATE_ACTIVE_KEY = 1;
const PREPAID_PACKAGE_STATE_CLOSED_KEY = 2;
const PREPAID_PACKAGE_STATE_EXPIRED_KEY = 3;
const PREPAID_PACKAGE_STATE_CANCELED_KEY = 5;

const PAYMENT_STATUS_TYPE_PENDING = "PENDING";
const PAYMENT_STATUS_TYPE_COMPLETED = "COMPLETED";

const CHECK_IN_STATUS_TYPE_PENDING = "PENDING";
const CHECK_IN_STATUS_TYPE_COMPLETED = "COMPLETED";

const STATUS_PENDING = (
  <StateIcon
    nameIcon="PENDING"
    // colorIc="light"
    objType="Icon"
  ></StateIcon>
);
const WEEKLY = "Settimanale";
const MONTHLY = "Mensile";
const QUARTERLY = "Trimestrale";
const FEATURE_FLAG_FOR_SIGNATURE_CONTRACT =
  "enable_requirement_for_signature_contract";
const FEATURE_FLAG_FOR_SIGNATURE_CONSENT =
  "enable_requirement_for_signature_consent";

const GROUPS_MANAGEMENT_INVOICE_EXPIRED = "Management Invoice Expired";
const GROUPS_MANAGEMENT_STRIPE_MENU = "StripeMenu";
const GROUPS_MANAGEMENT_CORRISPETTIVI = "Management Corrispettivi";
const GROUPS_DELETE_SIGNED_DOCUMNTS = "Delete signed documents";
const GROUPS_MANAGEMENT_INSERT_COUNTRY = "Management Insert Country";
const DOCUMENT_PRIVACY_CONSENT_CANCEL = 1;
const DOCUMENT_LASER_CONSENT_CANCEL = 2;
const DOCUMENT_CONTRACT_DOC_CANCEL = 3;
const DOCUMENT_WITHDRAWAL_DOC_CANCEL = 4;
const exportedConstants = {
  ErrorCodes,
  DEFAULT_LOCATION_UUID,
  CHECKIN_FUNDING_SUBSCRIPTION,
  CHECKIN_FUNDING_CREDIT,
  CHECKIN_FUNDING_PREPAID_PACKAGE,
  CHECKIN_FUNDING_PAID_ACCESS,
  SENTRY_DSN,
  REGEX_CF,
  VALIDATION_MANDATORY_FIELD,
  DEFAULT_STATION_UUID,
  DEFAULT_WORKER_UUID,
  MISSING_EMAIL_MARKER,
  STRING_NOT_FOUND,
  PRODUCT_HAIR_REMOVAL,
  FOUNDING_SOURCE_FREE_TRIAL,
  FOUNDING_SOURCE_GIFT,
  paymentProviders,
  STRIPE_PAYMENT_METHOD,
  STRIPE_STATUS_SUBSCRIPTION_CANCELED,
  TYPE_CHECKINLIST_SUBSCRIPTION,
  TYPE_CHECKINLIST_FOUNDINGSOURCE,
  TYPE_CHECKINLIST_CREDIT,
  TYPE_CHECKINLIST_PREPAID_PACKAGE,
  ASCENDING_SORT_SIMBOL,
  DESCENDING_SORT_SIMBOL,
  GOOGLE_ANALYTICS_ID,
  MANDATORY_NOTES,
  NO_MANDATORY_NOTES,
  SUBSCRIPTION_STATE_ACTIVE,
  SUBSCRIPTION_STATE_PENDING,
  SUBSCRIPTION_STATE_PAUSED,
  SUBSCRIPTION_STATE_SCHEDULED,
  SUBSCRIPTION_STATE_CANCELED,
  SUBSCRIPTION_STATE_NEEDS_AUTH,
  SUBSCRIPTION_STATEPAID_PAID,
  SUBSCRIPTION_STATEPAID_UNPAID,
  CREDIT_STATE_ACTIVE,
  CREDIT_STATE_CLOSED,
  CREDIT_STATE_EXPIRED,
  CREDIT_STATE_CANCELED,
  CREDIT_STATE_ACTIVE_KEY,
  CREDIT_STATE_CLOSED_KEY,
  CREDIT_STATE_EXPIRED_KEY,
  CREDIT_STATE_CANCELED_KEY,
  PAYMENT_STATUS_TYPE_PENDING,
  PAYMENT_STATUS_TYPE_COMPLETED,
  CHECK_IN_STATUS_TYPE_PENDING,
  CHECK_IN_STATUS_TYPE_COMPLETED,
  CHECKIN_FUNDING_CONSULTANT,
  STATUS_PENDING,
  WEEKLY,
  MONTHLY,
  QUARTERLY,
  FEATURE_FLAG_FOR_SIGNATURE_CONSENT,
  FEATURE_FLAG_FOR_SIGNATURE_CONTRACT,
  GROUPS_MANAGEMENT_INVOICE_EXPIRED,
  GROUPS_MANAGEMENT_STRIPE_MENU,
  PREPAID_PACKAGE_STATE_ACTIVE,
  PREPAID_PACKAGE_STATE_CLOSED,
  PREPAID_PACKAGE_STATE_EXPIRED,
  PREPAID_PACKAGE_STATE_CANCELED,
  PREPAID_PACKAGE_STATE_ACTIVE_KEY,
  PREPAID_PACKAGE_STATE_CLOSED_KEY,
  PREPAID_PACKAGE_STATE_EXPIRED_KEY,
  PREPAID_PACKAGE_STATE_CANCELED_KEY,
  GROUPS_MANAGEMENT_CORRISPETTIVI,
  DOCUMENT_PRIVACY_CONSENT_CANCEL,
  DOCUMENT_LASER_CONSENT_CANCEL,
  GROUPS_DELETE_SIGNED_DOCUMNTS,
  DOCUMENT_CONTRACT_DOC_CANCEL,
  DOCUMENT_WITHDRAWAL_DOC_CANCEL,
  GROUPS_MANAGEMENT_INSERT_COUNTRY,
};

export default exportedConstants;
