import "./App.css";

import {
  getCustomerUrl,
  getPlanUrl,
  getSubscriptionDetailUrl,
  getSubscriptionInvoicesListUrl,
  getSubscriptionStripeUrl,
  getInvoiceDetailUrl,
} from "../api/urls";

import ActionsButton from "../components/ActionsButton";
import { ErrorMessage } from "../components/ErrorMessage";
import React from "react";
import { SUBSCRIPTION_UNPAID_LIST } from "../api/queries";
import StateIcon from "../components/StateIcon";
import UnpaidSubscriptionsList from "../components/UnpaidSubscriptionsList";
import { useQuery } from "@apollo/client";
import { isUserInGroup } from "../api/utils";
import { formatDateIso, formatTimeIso, diffDays } from "../api/date-utils";
import constants from "../constants";

const popoverLinksList = (cell) => {
  const linksList = [
    {
      title: "Visualizza Rata Abbonamento",
      url: getInvoiceDetailUrl(cell?.stripeInvoiceId, cell?.subscriptionUuid),
    },
    {
      title: "Visualizza Abbonamento",
      url: getSubscriptionDetailUrl(cell?.subscriptionUuid),
    },
    {
      title: "Visualizza Cliente",
      url: getCustomerUrl(cell?.invoicingCustomerUuid),
    },
    {
      title: "Visualizza Elenco Pagamenti",
      url: getSubscriptionInvoicesListUrl(cell?.subscriptionUuid),
    },
    {
      title: "Visualizza Piano",
      url: getPlanUrl(cell?.planUuid),
    },
  ];

  if (cell.isManagementStripeMenu) {
    linksList.splice(1, 0, {
      title: "Visualizza Abbon. STRIPE",
      url: getSubscriptionStripeUrl(cell?.subscriptionStripeId),
    });
  }

  return linksList;
};

function UnpaidSubscriptionsListPage() {
  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Data Abbonamento
          </div>
        ),
        accessor: "subscriptiondateStart",
        width: 20,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },

      {
        Header: () => (
          <div>
            Beneficiario
            <div>(Titolare) </div>
          </div>
        ),
        accessor: "customerName",
        width: 30,
        Cell: ({ cell }) => (
          <div>
            {cell?.row?.original.customerFullName}
            <div>{cell?.row?.original.invoicingCustomerFullName}</div>
          </div>
        ),
      },
      {
        Header: "Piano",
        accessor: "planName",
        width: 30,
        Cell: ({ cell }) => (
          <div>
            {cell?.row?.original.subscriptionState}
            {cell?.row?.original.planName}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Rata
          </div>
        ),
        accessor: "attemptedAmount",
        width: 8,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Giorni Insoluto
          </div>
        ),
        accessor: "passedDays",
        width: 10,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Prossimo Tentativo Addebito
            <div>(Ultimo Tentativo Addebito) </div>
          </div>
        ),
        accessor: "nextPaymentAttempt",
        width: 35,
        Cell: ({ cell }) => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            {cell?.row?.original?.nextPaymentAttempt}
            <div>{cell?.row?.original?.lastPaymentAttempt}</div>
          </div>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Motivo
            <div>(Numero Tentativo Addebito) </div>
          </div>
        ),
        accessor: "rasonMessage",
        width: 35,
        Cell: ({ cell }) => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            {cell?.row?.original?.rasonMessage}
            <div>{cell?.row?.original?.attemptCount}</div>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "menu",
        width: 10,
        Cell: ({ cell }) => {
          const links = popoverLinksList(cell?.row?.original);
          return <ActionsButton linksList={links} />;
        },
      },
    ],
    []
  );

  const { loading, error, data } = useQuery(SUBSCRIPTION_UNPAID_LIST);

  if (loading) {
    return <p>Loading..</p>;
  }

  let unpaidList = null;
  if (!!data) {
    const isManagementStripeMenu = isUserInGroup(
      data?.userSetting,
      constants.GROUPS_MANAGEMENT_STRIPE_MENU
    );
    unpaidList = data?.subscriptionUnpaidList.result.map((s) => {
      const sub = s?.subscription;
      const customerFullName = `${sub?.customer?.last} ${sub?.customer?.first}`;
      const invoiceCustomerFullName =
        sub?.invoicingCustomer?.uuid === sub?.customer?.uuid
          ? ""
          : `(${sub?.invoicingCustomer?.last} ${sub?.invoicingCustomer?.first})`;
      const subscriptionState = (
        <StateIcon nameIcon={sub?.state} objType="Icon"></StateIcon>
      );
      const reasonMessage = !!s?.reason ? s?.reason : "";

      const passedDays = !!s?.invoiceCreated
        ? diffDays(new Date(s?.invoiceCreated), new Date())
        : "";

      let nextPaymentAttempt = !!s?.nextPaymentAttempt
        ? `${formatDateIso(
            new Date(s?.nextPaymentAttempt)
          )} ore ${formatTimeIso(new Date(s?.nextPaymentAttempt)).substring(
            0,
            8
          )}`
        : "Tentativi esauriti";

      nextPaymentAttempt = reasonMessage === "" ? "" : nextPaymentAttempt;

      const attemptCount = !!s?.invoiceAttemptCount
        ? ` (${s?.invoiceAttemptCount}) `
        : "";

      const lastPaymentAttempt = !!s?.invoiceChargedAt
        ? `(${formatDateIso(new Date(s?.invoiceChargedAt))})`
        : "";

      const attemptedAmount = `${sub?.plan?.price * sub?.quantity}€ `;

      return {
        subscriptiondateStart: sub?.startDate,
        invoicingCustomerFullName: invoiceCustomerFullName,
        customerFullName: customerFullName,
        subscriptionState: subscriptionState,
        planName: sub?.plan?.name,
        attemptedAmount: attemptedAmount,
        invoicingCustomerUuid: sub?.invoicingCustomer?.uuid,
        subscriptionUuid: sub?.uuid,
        planUuid: sub?.plan?.uuid,
        subscriptionStripeId: sub?.stripeSubscriptionId,
        passedDays: passedDays,
        nextPaymentAttempt: nextPaymentAttempt,
        attemptCount: attemptCount,
        rasonMessage: reasonMessage,
        lastPaymentAttempt: lastPaymentAttempt,
        stripeInvoiceId: s?.stripeInvoiceId,
        isManagementStripeMenu: isManagementStripeMenu,
      };
    });
  } else {
    const unpaidData = [
      {
        subscriptiondateStart: null,
        invoicingCustomerFullName: null,
        subscriptionState: null,
        customerFullName: null,
        planName: null,
        attemptedAmount: null,
        invoicingCustomerUuid: null,
        subscriptionUuid: null,
        planUuid: null,
        subscriptionStripeId: null,
        passedDays: null,
        stateInsoluto: null,
        nextPaymentAttempt: null,
        attemptCount: null,
        rasonMessage: null,
        lastPaymentAttempt: null,
        isManagementStripeMenu: null,
      },
    ];

    unpaidList = unpaidData;
  }

  return (
    <>
      <UnpaidSubscriptionsList columns={columns} unpaidList={unpaidList} />
      <ErrorMessage errors={[error, data?.subscriptionUnpaidList?.error]} />
    </>
  );
}

export default UnpaidSubscriptionsListPage;
