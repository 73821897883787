import React, { useEffect, useState } from "react";

import { BsCheckLg } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Spinner from "react-bootstrap/Spinner";
import { isEmptyObject } from "../../api/utils";

function isFilterPresent(selectedFilters) {
  // Loop through all the keys in the object
  for (const key in selectedFilters) {
    // If the value of the current key is not false,
    // return false immediately
    let found = !!selectedFilters[key].enabled;
    found = found && !selectedFilters[key].noFilters;
    if (found) {
      return true;
    }
  }
  return false;
}

function FilterButton({
  name,
  filtersList = {}, // Key value name:bool
  isLoading = false,
  singleSelection = false,
  onFilterChange = () => {},
}) {
  const [selectedFilters, setSelectedFilters] = useState(filtersList);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!isEmptyObject(selectedFilters)) {
      // Only set selectedFilters the first time
      return;
    }
    setSelectedFilters(filtersList);
  }, [filtersList, selectedFilters]);

  const handleApply = () => {
    setShow((prev) => !prev);
  };
  // Handles the change in filter
  const handleSelectFilter = (ev, filterName) => {
    const checked = ev.target.checked;

    const newFilterValue = {
      ...selectedFilters[filterName],
      enabled: !!checked,
      value: filtersList[filterName]?.value,
    };
    const newSelectedFilters = {
      ...selectedFilters,
      [filterName]: newFilterValue,
    };
    if (singleSelection) {
      Object.entries(newSelectedFilters).forEach(([key, value]) => {
        if (key !== filterName) {
          newSelectedFilters[key].enabled = false;
        }
      });
    }

    setSelectedFilters(newSelectedFilters);
  };

  const handleReset = (ev) => {
    ev.preventDefault();
    let resetFilters = { ...selectedFilters };
    Object.keys(resetFilters).forEach((v) => (resetFilters[v].enabled = false));
    setSelectedFilters(resetFilters);
    onFilterChange(resetFilters);
    handleApply();
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{name}</Popover.Header>
      <Popover.Body>
        <Form>
          {Object.keys(selectedFilters).map((filterName, index) => {
            return (
              <Form.Check
                type={singleSelection ? "radio" : "checkbox"}
                label={filterName}
                key={index}
                checked={!!selectedFilters[filterName]?.enabled}
                onChange={(ev) => {
                  return handleSelectFilter(ev, filterName);
                }}
              />
            );
          })}
        </Form>
        <Button
          variant="primary"
          size="sm"
          onClick={(ev) => {
            ev.preventDefault();
            onFilterChange(selectedFilters);
            handleApply();
          }}
        >
          Applica
        </Button>
        {singleSelection ? null : (
          <Button variant="light" size="sm" onClick={handleReset}>
            Reset
          </Button>
        )}
      </Popover.Body>
    </Popover>
  );

  const spinner = isLoading ? (
    <Spinner animation="border" variant="primary" size="sm" />
  ) : null;

  const isFilter = isFilterPresent(selectedFilters);
  const checkIcon = isFilter ? <BsCheckLg /> : null;
  const buttonVariant = isFilter ? "info" : "light";

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={popover}
      rootClose={true}
      show={show}
      onToggle={handleApply}
    >
      <Button variant={buttonVariant} disabled={isLoading}>
        {checkIcon} {name} {spinner}
      </Button>
    </OverlayTrigger>
  );
}

export default FilterButton;
