import Col from "react-bootstrap/Col";
import { ErrorMessage } from "../../components/ErrorMessage";
import { QUERY_ALL_PLANS } from "../../api/queries";
import React from "react";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import { useQuery } from "@apollo/client";

function PlanModule({ onChange }) {
  const { loading, error, data } = useQuery(QUERY_ALL_PLANS);
  const planOptions = data?.allPlans.map((element) => {
    return { value: element, label: element.name };
  });
  const defaultValue = planOptions?.[0];
  return (
    <section data-testid="plan-select">
      <Row>
        <Col>
          <Row>
            <Col>
              <h3>Piano</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                className="plan-select"
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: "5",
                  }),
                }}
                isLoading={loading}
                options={planOptions}
                defaultValue={defaultValue}
                onChange={(selected) => onChange(selected?.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ErrorMessage errors={[error]} />
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}

export default PlanModule;
