import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import {
  useTable,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
} from "react-table";
import { lookUpCadenceType } from "../api/utils";
import Card from "react-bootstrap/Card";
import constants from "../constants";

const ACTIVE = "Attivato";
const NOT_ACTIVE = "Disattivato";

function PlanDetail({ planData, listProducts, columns }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: listProducts,
      },
      useSortBy,
      useFlexLayout,
      useResizeColumns
    );

  const status = planData?.status ? NOT_ACTIVE : ACTIVE;
  return (
    <>
      <h4 className="text-center"> Piano Pagamento </h4>
      <hr></hr>
      <Row>
        <Col>
          <h5 className="text-center">
            ({planData?.shortName}) - {planData?.name}
          </h5>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6> Cadenza pagamenti </h6>
          <span>{lookUpCadenceType(planData?.cadence)} </span>
        </Col>
        <Col>
          <h6>Rata mensile</h6>
          <span>{planData?.price}</span>
        </Col>
        <Col>
          <h6>Stato</h6>
          <span>{status}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6> Numero minimo di rate </h6>
          <span>{planData?.minPaymentsRequired} </span>
        </Col>
        <Col>
          <h6>Cadenza Check-In</h6>
          <span>{lookUpCadenceType(planData?.checkinCadence)} </span>
        </Col>
        <Col>
          <h6>Numero Check-In {lookUpCadenceType(planData?.checkinCadence)}</h6>
          <span>{planData?.maxCheckinsAllowed}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Card>
        <Card.Body>
          <Card.Title style={{ fontSize: 15, fontWeight: "bold" }}>
            URL Contratto
          </Card.Title>
          <Card.Text>{planData?.agreementType?.signingDocumentUrl}</Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Title style={{ fontSize: 15, fontWeight: "bold" }}>
            URL Disdetta
          </Card.Title>
          <Card.Text>
            {planData?.cancelAgreementType?.signingDocumentUrl}
          </Card.Text>
        </Card.Body>
      </Card>
      <div className="mt-4" />
      <div className="mt-4" />
      <Card>
        <Card.Body>
          <Card.Title style={{ fontSize: 15, fontWeight: "bold" }}>
            Note
          </Card.Title>
          <Card.Text>{planData?.notes}</Card.Text>
        </Card.Body>
      </Card>
      <div className="mt-4" />
      <Row>
        <Col></Col>
        <Col>
          <div style={{ textAlign: "left" }}>
            <Col>
              <Table striped bordered hover size="sm" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => {
                    const { key, ...restHeaderGroupProps } =
                      headerGroup.getHeaderGroupProps();
                    return (
                      <tr key={key} {...restHeaderGroupProps}>
                        {headerGroup.headers.map((column) => {
                          const { key, ...restColumnProps } =
                            column.getHeaderProps(
                              column.getSortByToggleProps()
                            );
                          return (
                            <th key={key} {...restColumnProps}>
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? constants.DESCENDING_SORT_SIMBOL
                                    : constants.ASCENDING_SORT_SIMBOL
                                  : ""}
                              </span>
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    const { key, ...restRowProps } = row.getRowProps();
                    return (
                      <tr key={key} {...restRowProps}>
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();
                          return (
                            <td key={key} {...restCellProps}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
            <Col></Col>
          </div>
        </Col>
        <Col></Col>
      </Row>
    </>
  );
}
export default PlanDetail;
