import newSubscriptionActions from "../actions/newSubscription";

export default function newSubscriptionReducer(state, action) {
  switch (action.type) {
    case newSubscriptionActions.SET_RECEIVING_CUSTOMER:
      return {
        ...state,
        receivingCustomer: action.payload,
      };
    case newSubscriptionActions.SET_INVOICING_CUSTOMER:
      return {
        ...state,
        invoicingCustomer: action.payload,
      };
    case newSubscriptionActions.SET_PAYMENT_PROVIDER:
      return {
        ...state,
        paymentProvider: action.payload,
      };
    case newSubscriptionActions.SET_STRIPE_PAYMENT_METHOD:
      return {
        ...state,
        stripePaymentMethod: action.payload,
      };
    case newSubscriptionActions.SET_PLAN:
      return {
        ...state,
        plan: action.payload,
      };
    case newSubscriptionActions.SET_PAID:
      return {
        ...state,
        unpaid: false,
      };
    case newSubscriptionActions.SET_CREATED:
      return {
        ...state,
        latestInvoice: action.payload.latestInvoice,
        unpaid: action.payload.unpaid,
        uuid: action.payload.uuid,
      };
    case newSubscriptionActions.SET_SCHEDULED:
      return {
        ...state,
        scheduledDate: action.payload,
      };
    case newSubscriptionActions.SET_SCHEDULED_ENABLED:
      return {
        ...state,
        scheduledDateEnabled: action.payload,
      };
    case newSubscriptionActions.SET_ACTIVATED:
      return state;
    default:
      return state;
  }
}
