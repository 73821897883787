import { allCounties, loadCitiesForCounty } from "../api/utils";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import PhoneNumber from "./PhoneNumber";
import PropTypes from "prop-types";
import React from "react";
import Row from "react-bootstrap/Row";
import { useFormContext } from "react-hook-form";

const countyList = allCounties();

function CustomerInput({ fullInfo, prefix = "" }) {
  const {
    register,
    control,
    setValue,
    watch,

    formState: { errors },
  } = useFormContext();
  const watchCounty = watch("county");
  const availableCities = loadCitiesForCounty(watchCounty);

  let prefixer = (name) => `${prefix}${name}`;

  let AddressInput = (
    <>
      <Row>
        <Form.Group as={Col} controlId="fieldRoad">
          <CustomerInputField
            label="Via"
            name={prefixer("road")}
          ></CustomerInputField>
        </Form.Group>
        <Form.Group as={Col} controlId="fieldHouseNumber">
          <CustomerInputField
            label="Numero Civico"
            name={prefixer("houseNumber")}
            maxLength="8"
          ></CustomerInputField>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="fieldCounty">
          <CustomerInputField
            label="Provincia"
            name={prefixer("county")}
            as="select"
          >
            {countyList.map(({ code, name }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </CustomerInputField>
        </Form.Group>
        <Form.Group as={Col} controlId="fieldCity">
          <CustomerInputField label="Città" name={prefixer("city")} as="select">
            {availableCities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </CustomerInputField>
        </Form.Group>
        <Form.Group as={Col} controlId="fieldZipCode">
          <CustomerInputField
            label="CAP"
            name={prefixer("postcode")}
            maxLength="5"
            onChange={(e) => {
              let digits_only = (string) =>
                [...string].every((c) => "0123456789".includes(c));
              let v = e.target.value;
              if (!digits_only(e.target.value)) {
                v = "";
              }
              setValue(prefixer("postcode"), v);
            }}
          ></CustomerInputField>
        </Form.Group>
      </Row>
    </>
  );
  if (!fullInfo) {
    AddressInput = null;
  }

  return (
    <>
      <Row>
        <Form.Group as={Col} controlId="fieldFirstName">
          <CustomerInputField
            label="Nome"
            name={prefixer("first")}
            register={register}
            errors={errors}
          ></CustomerInputField>
        </Form.Group>
        <Form.Group as={Col} controlId="fieldLastName">
          <CustomerInputField
            label="Cognome"
            name={prefixer("last")}
            register={register}
            errors={errors}
          ></CustomerInputField>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="fieldEmail">
          <CustomerInputField
            label="Email"
            name={prefixer("email")}
            register={register}
            errors={errors}
          ></CustomerInputField>
        </Form.Group>
        <Form.Group as={Col} controlId="fieldTaxID">
          <CustomerInputField
            label="Codice Fiscale"
            name={prefixer("taxId")}
            register={register}
            errors={errors}
          ></CustomerInputField>
        </Form.Group>
      </Row>
      <h6>Telefono</h6>
      <Row>
        <PhoneNumber control={control} errors={errors} prefix={prefix} />
      </Row>
      {AddressInput}
    </>
  );
}

function CustomerInputField(props) {
  const {
    register,

    formState: { errors },
  } = useFormContext();

  let cascadeProps = { ...props };
  delete cascadeProps.errors;
  delete cascadeProps.register;

  return (
    <>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        type="text"
        name={props.name}
        placeholder={props.label}
        {...register(props.name)}
        isInvalid={!!errors[props.name]}
        {...cascadeProps}
      />
      <Form.Control.Feedback type="invalid">
        {errors[props.name] && errors[props.name].message}
      </Form.Control.Feedback>
    </>
  );
}

CustomerInput.propTypes = {
  fullInfo: PropTypes.bool,
};

export { CustomerInput, CustomerInputField };
