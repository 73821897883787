import React from "react";
import { BsArrowRight } from "react-icons/bs";

function addBsArrowRight(text) {
  if (!text) {
    return null;
  }
  return (
    <>
      {text} <BsArrowRight />
    </>
  );
}

export default addBsArrowRight;
