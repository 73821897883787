import "./App.css";

import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { ErrorMessage } from "../components/ErrorMessage";
import { MUTATION_UPDATE_SUBSCRIPTION } from "../api/mutations";
import { Navigate } from "react-router";
import { QUERY_GET_STRIPE_SUBSCRIPTION } from "../api/queries";
import { QUERY_GET_SUBSCRIPTION_UPDATE } from "../api/queries";
import SubscriptionUpdateForm from "../components/SubscriptionUpdateForm";
import { getSubscriptionDetailUrl } from "../api/urls";
import { useParams } from "react-router-dom";

function SubscriptionUpdatePage() {
  const [completed, setCompleted] = useState(false);
  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);

  const [
    updateSubscriptionMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(MUTATION_UPDATE_SUBSCRIPTION, {
    onCompleted: () => {
      setCompleted(true);
    },
  });

  let { subscriptionUuid } = useParams();
  const LIMIT_MAX_RECORD = 3;

  const { loading, error, data } = useQuery(QUERY_GET_SUBSCRIPTION_UPDATE, {
    variables: {
      subscriptionUuid: subscriptionUuid,
      checkInMaxLimit: LIMIT_MAX_RECORD,
    },
  });
  const { loading: payLoading, data: payData } = useQuery(
    QUERY_GET_STRIPE_SUBSCRIPTION,
    {
      variables: {
        subscriptionUuid: subscriptionUuid,
      },
    }
  );

  if (loading || payLoading) {
    return <p>Loading..</p>;
  }

  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  if (mutationError) {
    return <ErrorMessage errors={[mutationError]} />;
  }

  let subscriptionData = data?.subscription;

  const currentLocation = data?.userSetting?.currentLocation;
  const isSameLocation =
    currentLocation?.uuid === data?.subscription?.location?.uuid ? true : false;

  let redirectContinueToList = null;
  if (!!shouldRedirectToList || !isSameLocation) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: `${getSubscriptionDetailUrl(subscriptionUuid)}`,
        }}
      />
    );
  }

  return (
    <>
      <SubscriptionUpdateForm
        data={subscriptionData}
        payData={payData}
        updateSubscription={updateSubscriptionMutation}
        updating={mutationLoading}
        completed={completed}
        updateError={mutationError}
        modalContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
        currentLocation={currentLocation}
      />
      {redirectContinueToList}
    </>
  );
}

export default SubscriptionUpdatePage;
