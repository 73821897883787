import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";

import Button from "react-bootstrap/Button";
//import { Elements } from "@stripe/react-stripe-js";
import { ErrorMessage } from "../../components/ErrorMessage";
import Form from "react-bootstrap/Form";
import { MUTATION_CREATE_SETUP_INTENT } from "../../api/mutations";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import StripeElementsWrapper from "../StripeElementsWrapper";
//import Stripe from "../../api/stripe";
//import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "@apollo/client";

const CARD_ELEMENT_OPTIONS = {
  style: {
    width: "100%",

    base: {
      fontSize: "16px",
      color: "#424770",
      fontFamily: "Open Sans, sans-serif",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#c23d4b",
    },
  },
};

function CreateStripePaymentComponent({ onCreated, customer }) {
  const [addCardError, setAddCardError] = useState(null);
  const [addCardLoading, setAddCardLoading] = useState(false);
  const [createSetupIntent, { error: mutationError }] = useMutation(
    MUTATION_CREATE_SETUP_INTENT
  );
  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setAddCardLoading(true);

      const result = await createSetupIntent({
        variables: {
          customerUuid: customer.uuid,
        },
      });
      const clientSecret = result?.data?.createStripeSetupIntent?.clientSecret;
      if (!clientSecret) {
        const clientSecretErrors = result?.errors || result?.data?.error;
        throw new Error("Errore aggiunta carta " + clientSecretErrors);
      }

      const cardInput = elements.getElement(CardElement);

      const stripeResult = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardInput,
          billing_details: {
            name: `${customer.last} ${customer.first}`,
            email: customer.email,
          },
        },
      });
      const resultStatus = stripeResult?.setupIntent?.status;

      if (stripeResult?.error) {
        throw new Error(stripeResult.error.message, stripeResult.error);
      }

      if (resultStatus !== "succeeded") {
        throw new Error(`Errore aggiunta carta. Status = ${resultStatus}`);
      }
      setAddCardLoading(false);
      onCreated(customer, stripeResult?.setupIntent);
    } catch (error) {
      setAddCardLoading(false);
      setAddCardError(error);
    }
  };

  const buttonSpinner = <Spinner animation="border" variant="light" />;

  return (
    <Form onSubmit={onSubmit} data-testid="create-stripe-payment-modal">
      <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} />
      <ErrorMessage errors={[addCardError, mutationError]} />
      <Button variant="primary" type="submit">
        {addCardLoading ? buttonSpinner : "Aggiungi"}
      </Button>
    </Form>
  );
}

function CreateStripePaymentModal({ onHide, show, onCreated, customer }) {
  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Aggiungi Carta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateStripePaymentComponent
          onCreated={onCreated}
          customer={customer}
        />
      </Modal.Body>
    </Modal>
  );
}

const WrappedCreateStripePaymentModal = (props) => (
  <StripeElementsWrapper>
    <CreateStripePaymentModal {...props} />
  </StripeElementsWrapper>
);

export {
  WrappedCreateStripePaymentModal as CreateStripePaymentModal,
  CreateStripePaymentComponent,
};
