import { ErrorMessage } from "../ErrorMessage";
import { FormProvider, useForm } from "react-hook-form";
import {
  customerValidationType,
  getCustomerValidationResolver,
} from "../validators";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { CustomerInput } from "../CustomerInput";
import Form from "react-bootstrap/Form";
import FormElement from "../FormElement";
import PropTypes from "prop-types";
import React from "react";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "../../api/utils";

// Component

function CustomerCreateForm(props) {
  const {
    onSubmit = () => {},
    creating = false,
    completed = false,
    errors = null,
    defaultValues = {},
    referralSourcesList = [],
    validation = customerValidationType.RECEIVING,
    fullInfo = true,
  } = props;

  const formValidationResolver = getCustomerValidationResolver(validation);

  const useFormMethods = useForm({
    resolver: formValidationResolver,
    defaultValues: {
      phoneNumber: "",
      county: "",
      city: "",
      ...defaultValues,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
    setValue,
  } = useFormMethods;
  let creatingSuccess = (
    <Alert variant="success"> Registrazione effettuata</Alert>
  );
  const buttonSpinner = <Spinner animation="border" variant="light" />;

  return (
    <FormProvider {...useFormMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CustomerInput
          fullInfo={fullInfo}
          register={register}
          errors={formErrors}
          control={control}
          setValue={setValue}
        />

        <Row>
          <Form.Group as={Col} xs={6} controlId="notes">
            <Form.Label>Note</Form.Label>
            <Form.Control as="textarea" rows="1" {...register("notes")} />
          </Form.Group>
          <Form.Group as={Col} xs={6} controlId="referralSource">
            <FormElement
              label="Fonte Contatto"
              name="referralSource"
              register={register}
              errors={formErrors}
              controlOptions={{ as: "select" }}
            >
              {referralSourcesList.map((p) => (
                <option key={p.uuid} value={p.uuid}>
                  {p.name}
                </option>
              ))}
            </FormElement>
          </Form.Group>
        </Row>

        <Row>
          <ErrorMessage errors={[errors]} />
        </Row>

        <Row>{completed && !errors ? creatingSuccess : null}</Row>
        <Button
          variant={isEmpty(errors) ? "primary" : "danger"}
          type="submit"
          size="lg"
          disabled={creating}
        >
          {creating ? buttonSpinner : "Crea"}
        </Button>
      </Form>
    </FormProvider>
  );
}

CustomerCreateForm.propTypes = {
  // Triggered when the create customer button is tapped
  onSubmit: PropTypes.func,
  // Creation in progress
  isCreating: PropTypes.bool,
  // Creation complered
  isCompleted: PropTypes.bool,
  // Triggered when an error is triggered
  onCreateError: PropTypes.func,
  // FormData to preload
  defaultValues: PropTypes.object,
  // Array of referral sources to display
  referralSourcesList: PropTypes.array,
  // Used to only require a subset of the data
  light: PropTypes.bool,
};

export { CustomerCreateForm };
