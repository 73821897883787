import { FaBars, FaLock, FaPause, FaPlay, FaStop } from "react-icons/fa";

import { MdSchedule } from "react-icons/md";
import PropTypes from "prop-types";
import React from "react";

const statusPropsBtn = {
  size: "1.1em",
};

const statusPropsIcs = {
  size: "1em",
  color: "#999999", //grey
};

function StateIcon(props) {
  let nameIcon = props.nameIcon;
  //let colorIc = props.colorIc;
  let objType = props.objType;
  let icc;
  let statusProps = objType === "Icon" ? statusPropsIcs : statusPropsBtn;

  switch (nameIcon) {
    case "ACTIVE":
      icc = <FaPlay title="Abbonameto attivo" {...statusProps} />;
      break;
    case "PENDING":
      icc = (
        <FaBars
          title="Creazione abbonamento non completata. Contattare l'amministrazione per sbloccarlo"
          {...statusProps}
        />
      );
      break;
    case "PAUSED":
      icc = <FaPause title="Abbonamento in pausa" {...statusProps} />;
      break;
    case "SCHEDULED":
      icc = <MdSchedule {...statusProps} />;
      break;
    case "CANCELED":
      icc = <FaStop title="Abbonamento cancellato" {...statusProps} />;
      break;
    case "NEEDS_AUTH":
      icc = (
        <FaLock
          title="Il cliente deve autorizzare l'abbonamento con la banca (3DSecure)"
          {...statusProps}
        />
      );
      break;
    default:
      icc = <FaPlay {...statusProps} />;
  }

  return <>{icc} </>;
}
StateIcon.propTypes = {
  nameIcon: PropTypes.string,
  objType: PropTypes.string,
};
export default StateIcon;
