import "./App.css";

import React, { useState } from "react";
import {
  getInvoiceStripeUrl,
  getSubscriptionDetailUrl,
  getCheckinDetailUrl,
} from "../api/urls";

import ActionsButton from "../components/ActionsButton";
import { ErrorMessage } from "../components/ErrorMessage";
import { Navigate } from "react-router";
import { QUERY_GET_SUBSCRIPTION } from "../api/queries";
import { QUERY_GET_SUBSCRIPTION_PAYMENT } from "../api/queries";
import SubscriptionInvoicesListForm from "../components/SubscriptionInvoicesListForm";
import { getInvoiceDetailUrl } from "../api/urls";
import { loadListInvoice, isUserInGroup } from "../api/utils";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import constants from "../constants";

function loadListInvoiceSubscription(
  invoiceList,
  subscription,
  isManagementStripeMenu
) {
  let listInvoiceSubscription = invoiceList.map((s) => {
    return {
      dateEnd: s?.dateEnd,
      invPaid: s.invPaid,
      invoicePaid: s.invoicePaid,
      dateStart: s?.dateStart,
      statusDetail: s?.statusDetail,
      paidAt: s.paidAt,
      paymentIntentError: s?.paymentIntentError,
      checkinCount: s?.checkinCount,
      stripeInvoiceId: s?.stripeInvoiceId,
      subscriptionUuid: subscription?.uuid,
      checkinCountNoPresent: s?.checkinCountNoPresent,
      item: s.item,
      index: s.index,
      isManagementStripeMenu: isManagementStripeMenu,
      invoiceCheckinUuid: s.invoice_checkin_uuid,
      invoiceCheckinDate: s.invoice_checkin_date,
    };
  });
  return listInvoiceSubscription;
}

const popoverLinksList = (cell) => {
  let linksList = [];
  linksList = [
    ...linksList,
    {
      title: "Visualizza Dettaglio Rata",
      url: getInvoiceDetailUrl(cell?.stripeInvoiceId, cell?.subscriptionUuid),
    },
  ];
  if (cell.isManagementStripeMenu) {
    linksList = [
      ...linksList,
      {
        title: "Visualizza Rata STRIPE",
        url: getInvoiceStripeUrl(cell?.stripeInvoiceId),
      },
    ];
  }
  if (!!cell?.invoiceCheckinDate) {
    linksList = [
      ...linksList,
      {
        title: "Visualizza Check-In",
        url: getCheckinDetailUrl(cell?.invoiceCheckinUuid),
      },
    ];
  }

  return linksList;
};

function SubscriptionInvoicesListPage() {
  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "index",
        width: 10,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Rata dal",
        accessor: "dateStart",
        width: 23,
      },
      {
        Header: "Rata al",
        accessor: "dateEnd",
        width: 23,
      },
      {
        Header: "Data Pagamento",
        accessor: "paidAt",
        width: 23,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Importo
          </div>
        ),
        accessor: "invPaid",
        width: 15,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Numero CheckIn
          </div>
        ),
        accessor: "checkinCount",
        width: 20,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Stato Pagamento",
        accessor: "invoicePaid",
        width: 80,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Data CheckIn
          </div>
        ),
        accessor: "invoiceCheckinDate",
        width: 20,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "",
        accessor: "menu",
        width: 15,
        Cell: ({ cell }) => {
          const links = popoverLinksList(cell?.row?.original);
          return <ActionsButton linksList={links} />;
        },
      },
    ],
    []
  );
  let { subscriptionUuid } = useParams();
  const { loading, error, data } = useQuery(QUERY_GET_SUBSCRIPTION, {
    variables: {
      subscriptionUuid: subscriptionUuid,
    },
  });
  const { loading: payLoading, data: payData } = useQuery(
    QUERY_GET_SUBSCRIPTION_PAYMENT,
    {
      variables: {
        subscriptionUuid: subscriptionUuid,
      },
    }
  );

  if (loading || payLoading) {
    return <p>Loading..</p>;
  }

  if (error) {
    return <ErrorMessage errors={[error]} />;
  }
  const isManagementStripeMenu = isUserInGroup(
    data?.userSetting,
    constants.GROUPS_MANAGEMENT_STRIPE_MENU
  );

  let listInvoiceSubscription = [
    {
      dateEnd: null,
      invPaid: null,
      invoicePaid: null,
      dateStart: null,
      statusDetail: null,
      paidAt: null,
      paymentIntentError: null,
      stripeInvoiceId: null,
      subscriptionUuid: null,
      item: null,
      index: null,
      isManagementStripeMenu: null,
      invoiceCheckinUuid: null,
      invoiceCheckinDate: null,
    },
  ];

  const dataAvailable =
    !!payData &&
    !!payData?.invoiceForSubscription?.result &&
    !!payData?.stripeSubscription?.result;

  if (dataAvailable) {
    const invoiceList = loadListInvoice(
      payData?.invoiceForSubscription?.result
    );
    listInvoiceSubscription = loadListInvoiceSubscription(
      invoiceList,
      data?.subscription,
      isManagementStripeMenu
    );
  }

  let redirectContinueToList = null;
  if (!!shouldRedirectToList) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: getSubscriptionDetailUrl(subscriptionUuid),
        }}
      />
    );
  }

  return (
    <>
      <SubscriptionInvoicesListForm
        columns={columns}
        invoiceList={listInvoiceSubscription}
        subscription={data?.subscription}
        invoiceCounterExpired={data?.invoiceCounterExpired}
        ContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
      />
      {redirectContinueToList}
    </>
  );
}

export default SubscriptionInvoicesListPage;
