import { BsEmojiTear, BsFilePerson } from "react-icons/bs";
import {
  GET_LEAD_AND_ACTIVITIES_BY_UUID,
  GET_USER_SETTING,
} from "../../api/queries";
import { useMutation, useQuery } from "@apollo/client";

import Button from "react-bootstrap/Button";
import { MUTATION_AUTOASSIGN_LEAD } from "../../api/mutations";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";

const getError = (mutationError, queryError, currentUserUsername) => {
  if (mutationError) return mutationError;
  if (queryError) return queryError;
  if (!currentUserUsername)
    return new Error("Current user username is unavailable");
  return null;
};

const renderTooltip = (error, isCurrentUser) => (props) => {
  const tooltipText = error
    ? error.message
    : isCurrentUser
    ? "La lead è già assegnata all'utente corrente"
    : "";

  return (
    <Tooltip
      id="button-tooltip"
      {...props}
      style={{ display: tooltipText ? "block" : "none" }}
    >
      {tooltipText}
    </Tooltip>
  );
};

function LeadAssignButton({ leadUuid, leadUsername }) {
  const [autoassignLead, { loading: mutationLoading, error: mutationError }] =
    useMutation(MUTATION_AUTOASSIGN_LEAD, {
      refetchQueries: (mutationResult) => [
        {
          query: GET_LEAD_AND_ACTIVITIES_BY_UUID,
          variables: { uuid: mutationResult?.data?.updateLead?.lead?.uuid },
        },
      ],
    });

  const { data: userData, error: queryError } = useQuery(GET_USER_SETTING, {
    fetchPolicy: "cache-first",
  });

  const currentUserUsername = userData?.getUserSetting?.user?.username;
  const isCurrentUser = currentUserUsername === leadUsername;

  const error = getError(mutationError, queryError, currentUserUsername);

  const onClick = async () => {
    if (!error && !isCurrentUser) {
      await autoassignLead({ variables: { uuid: leadUuid } });
    }
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={renderTooltip(error, isCurrentUser)}
    >
      <Button
        size="sm"
        variant="light"
        onClick={onClick}
        disabled={mutationLoading || isCurrentUser || !!error}
      >
        {mutationLoading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : error ? (
          <BsEmojiTear />
        ) : (
          <BsFilePerson />
        )}{" "}
        Assegnami
      </Button>
    </OverlayTrigger>
  );
}

export default LeadAssignButton;
