import React, { useContext, useEffect } from "react";

import Alert from "react-bootstrap/Alert";
import Auth from "../api/auth";
import { MUTATION_TOKEN_DELETE } from "../api/mutations";
import { Navigate } from "react-router-dom";
import actions from "../actions/auth";
import { store } from "../store";
import { useMutation } from "@apollo/client";

// This component just redirects
function LogoutPage(props) {
  const { dispatch } = useContext(store);
  const [
    tokenDeleteMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(MUTATION_TOKEN_DELETE);

  useEffect(() => {
    async function logout() {
      const returnValue = await tokenDeleteMutation();
      if (!returnValue.data.deleteTokenCookie.deleted) {
        console.warn("JWT Cookie not deleted");
      }
      dispatch({ type: actions.ACTION_AUTH_LOG_OUT });
      Auth.logout();
    }
    logout();
  });

  if (mutationLoading) {
    return <h3>Loading</h3>;
  }
  if (mutationError) {
    return <Alert variant="danger">mutationError</Alert>;
  }

  return <Navigate to="/login" />;
}

export default LogoutPage;
