import {
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import React from "react";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import constants from "../constants";
import urls from "../api/urls";
import { useNavigate } from "react-router-dom";

function buildUrlListCheckIn(param) {
  return `${urls.URL_LIST_CHECK_IN}?page=${param}`;
}

function CheckInListForm({ columns, data, paginator }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy,
      useFlexLayout,
      useResizeColumns
    );

  let navigate = useNavigate();

  let page = paginator?.page;
  let pageSize = paginator?.pageSize;
  let totPages = paginator?.totPages;
  let hasNext = paginator?.hasNext;
  let hasPrev = paginator?.hasPrev;

  let tabPage = [];
  let i = 1;
  while (i <= totPages) {
    tabPage.push(i);
    i++;
  }

  const setPageSize = (pSize) => {
    const url = buildUrlListCheckIn(page);
    pageSize = pSize;
    navigate(url);
  };

  const gotoPage = (page) => {
    const url = buildUrlListCheckIn(page);
    navigate(url);
  };

  let url;
  //----------------------------------------------------------
  url = buildUrlListCheckIn(page + 1);
  const showNextPage = true;
  const nextPage = showNextPage ? (
    <Button
      size="sm"
      variant="outline-info"
      disabled={!hasNext}
      action="true"
      href={url}
    >
      Avanti {">"}
    </Button>
  ) : null;

  //----------------------------------------------------------
  const showPreviusPage = true;
  url = buildUrlListCheckIn(page - 1);
  const previusPage = showPreviusPage ? (
    <Button
      size="sm"
      variant="outline-info"
      disabled={!hasPrev}
      action="true"
      href={url}
    >
      {"<"} Indietro
    </Button>
  ) : null;
  //----------------------------------------------------------
  const showFirstPage = false;
  url = buildUrlListCheckIn("1");
  const firstPage = showFirstPage ? (
    <Button
      size="sm"
      variant="outline-info"
      disabled={!hasPrev}
      action="true"
      href={url}
    >
      {"<<"} Inizio
    </Button>
  ) : null;

  //----------------------------------------------------------
  const showLastPage = false;
  url = buildUrlListCheckIn(totPages);
  const lastPage = showLastPage ? (
    <Button
      size="sm"
      variant="outline-info"
      disabled={!hasNext}
      action="true"
      href={url}
    >
      Fine {">>"}
    </Button>
  ) : null;

  //----------------------------------------------------------
  const showGoPage = false;
  const goPage = showGoPage ? (
    <select
      value={page}
      onChange={(e) => {
        gotoPage(Number(e.target.value));
      }}
    >
      {tabPage.map((pageNumber) => (
        <option key={pageNumber} value={pageNumber}>
          {pageNumber}
        </option>
      ))}
    </select>
  ) : null;

  const labelGoPage = showGoPage ? <span>Vai alla Pagina</span> : null;

  //----------------------------------------------------------
  const showSetPageSize = false;
  const SetPageSize = showSetPageSize ? (
    <select
      value={pageSize}
      onChange={(e) => {
        setPageSize(Number(e.target.value));
      }}
    >
      {[10, 20, 25, 30, 40, 50, 75, 100, 150, 200].map((pageSize) => (
        <option key={pageSize} value={pageSize}>
          {pageSize}
        </option>
      ))}
    </select>
  ) : null;

  const labelSetPageSize = showSetPageSize ? <span>Righe X pag.</span> : null;

  return (
    <>
      <h3 className="text-center">Elenco Check-Ins</h3>
      <Row>
        <Col>{nextPage}</Col>
        <Col>{previusPage}</Col>
        <Col>{firstPage}</Col>
        <Col>{lastPage}</Col>
        <Col>{labelGoPage}</Col>
        <Col>
          <Col>{goPage}</Col>
        </Col>
        <Col>{labelSetPageSize}</Col>
        <Col>{SetPageSize}</Col>
      </Row>

      <Table striped bordered hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps(
                    column.getSortByToggleProps()
                  );
                  return (
                    <th key={key} {...restColumnProps}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? constants.DESCENDING_SORT_SIMBOL
                            : constants.ASCENDING_SORT_SIMBOL
                          : ""}
                      </span>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <tr key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  return (
                    <td key={key} {...restCellProps}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col>{nextPage}</Col>
        <Col>{previusPage}</Col>
        <Col>{firstPage}</Col>
        <Col>{lastPage}</Col>
        <Col>{labelGoPage}</Col>
        <Col>
          <Col>{goPage}</Col>
        </Col>
        <Col>{labelSetPageSize}</Col>
        <Col>{SetPageSize}</Col>
      </Row>
      <hr />
    </>
  );
}

export default CheckInListForm;
