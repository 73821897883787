import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import dropDownHeaderView from "../../components/DropDownHeaderView";
import addBsArrowRight from "../../components/AddBsArrowRight";
import {
  getCheckinDetailUrl,
  getCustomerUrl,
  getReceiptUpdateUrl,
} from "../../api/urls";

function ReceiptDetailForm({
  receipt,
  receiptItem,
  creditNote,
  checkin,
  currentLocation,
}) {
  const isDisabled = true;
  const isSameLocation =
    currentLocation?.uuid === receipt?.location?.uuid ? true : false;

  const creditNoteData = (
    <>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Numero Rimborso </h6>
          <span>{creditNote?.progressiveNumber} </span>
        </Col>
        <Col>
          <h6>Data rimborso </h6>
          <span>{creditNote?.date} </span>
        </Col>
        <Col>
          <h6>Data invio MEF </h6>
          <span>{creditNote?.sdiSentDate} </span>
        </Col>
        <Col></Col>
      </Row>
    </>
  );
  let modifyButtonActive = (
    <Dropdown.Item href={getReceiptUpdateUrl(receipt?.uuid)}>
      Modifica Ricevuta
    </Dropdown.Item>
  );
  let modifyButtonDisabled = (
    <Dropdown.Item
      href={getReceiptUpdateUrl(receipt?.uuid)}
      disabled={isDisabled}
    >
      Modifica Ricevuta
    </Dropdown.Item>
  );
  const modifyButton = !isSameLocation
    ? modifyButtonDisabled
    : modifyButtonActive;
  return (
    <>
      <Row>
        <Col xs={11}>
          <h4 className="text-center"> Dettaglio Ricevuta </h4>
        </Col>
        <Col xs={1}>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <ButtonGroup>
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="primary"
              >
                {modifyButton}
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item href={getCustomerUrl(receipt?.customer?.uuid)}>
                  {addBsArrowRight("Cliente")}
                </Dropdown.Item>
                <Dropdown.Item href={getCheckinDetailUrl(checkin?.uuid)}>
                  {addBsArrowRight("CheckIn")}
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6> Nome: </h6>
          <span>{receipt?.customer?.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{receipt?.customer?.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{receipt?.customer?.email}</span>
        </Col>
        <Col>
          <h6>Codice Fiscale</h6>
          <span>{receipt?.customer?.taxId}</span>
        </Col>
      </Row>
      <hr />
      <h5> Dati Pagamento</h5>

      <div className="mt-4" />
      <Row>
        <Col>
          <h6> Costo Unitario: </h6>
          <span>{receiptItem?.articlePrice / receiptItem?.quantity}€ </span>
        </Col>
        <Col>
          <h6>N. Trattamenti</h6>
          <span>{Math.trunc(receiptItem?.quantity)}</span>
        </Col>
        <Col>
          <h6>Totale da pagare</h6>
          <span>{receiptItem?.articlePrice}€</span>
        </Col>
        <Col>
          <h6> Trattamento</h6>
          <span>{receiptItem?.product?.name} </span>
        </Col>
      </Row>

      <div className="mt-4" />
      <hr />
      <Row>
        <Col>
          <h6>Numero ricevuta </h6>
          <span>{receipt?.progressiveNumber} </span>
        </Col>
        <Col>
          <h6>Data ricevuta </h6>
          <span>{receipt?.date} </span>
        </Col>
        <Col>
          <h6>Data invio MEF </h6>
          <span>{receipt?.sdiSentDate} </span>
        </Col>
        <Col></Col>
      </Row>
      {!!creditNote ? creditNoteData : null}
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Note</h6>
        </Col>
        <Col>
          <textarea
            disabled
            id="notes"
            name="Notes"
            rows="2"
            cols="125"
            value={!!receipt?.internalNotes ? receipt?.internalNotes : ""}
          ></textarea>
        </Col>
      </Row>
    </>
  );
}

export default ReceiptDetailForm;
