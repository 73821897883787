import {
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";

import Table from "react-bootstrap/Table";

function MainTable(columns, data, handleColumn) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy,
      useFlexLayout,
      useResizeColumns
    );
  const mainTable = (
    <Table striped hover size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => {
          const { key: headerGroupKey, ...headerGroupProps } =
            headerGroup.getHeaderGroupProps();
          return (
            <tr key={headerGroupKey} {...headerGroupProps}>
              {headerGroup.headers.map((column) => {
                const { key: columnKey, ...columnProps } =
                  column.getHeaderProps(column.getSortByToggleProps());
                return (
                  <th
                    key={columnKey}
                    {...columnProps}
                    onClick={() => {
                      handleColumn(column);
                    }}
                  >
                    {column.render("Header")}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const { key: rowKey, ...rowProps } = row.getRowProps();
          return (
            <tr key={rowKey} {...rowProps}>
              {row.cells.map((cell) => {
                const { key: cellKey, ...cellProps } = cell.getCellProps();
                return (
                  <td key={cellKey} {...cellProps}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
  return mainTable;
}
export default MainTable;
