import { CustomerSearch } from "../../components/customer/CustomerModule";
import React from "react";
import { customerValidationType } from "../../components/validators";
import { getCustomerUrl } from "../../api/urls";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

function NavBarSearch() {
  const navigate = useNavigate();

  let className = "customer-navbar-search";
  let fullInfo = true;

  function onChange(values) {
    console.log(navigate);
    navigate(getCustomerUrl(values.uuid));
  }

  const NavBarSearchWrapper = styled.div`
    min-width: 25rem;
  `;

  return (
    <NavBarSearchWrapper>
      <CustomerSearch
        className={className}
        validation={customerValidationType.RECEIVING}
        fullInfo={fullInfo}
        onSetCustomer={onChange}
      />
    </NavBarSearchWrapper>
  );
}

export { NavBarSearch };
