import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import React from "react";

function FormElement(props) {
  const register = props.register;
  const errors = { ...props.errors };
  const controlOptions = props.controlOptions;
  return (
    <>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        type={props.type}
        placeholder={props.label}
        {...register(props.name)}
        isInvalid={!!errors[props.name]}
        {...controlOptions}
      >
        {props.children}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {errors[props.name] && errors[props.name].message}
      </Form.Control.Feedback>
    </>
  );
}
FormElement.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  controlOptions: PropTypes.object,
};

export default FormElement;
