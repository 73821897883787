import React from "react";
import "./App.css";
import { ErrorMessage } from "../components/ErrorMessage";
import PlanDetail from "../components/PlanDetail";
import { useParams } from "react-router-dom";
import { QUERY_PLAN } from "../api/queries";
import { useQuery } from "@apollo/client";

function PlanDetailPage() {
  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Elenco Trattamenti Previsti
          </div>
        ),
        accessor: "product.name",
        width: 30,
      },
    ],
    []
  );

  let { guid } = useParams();
  const { loading, error, data } = useQuery(QUERY_PLAN, {
    variables: {
      planUuid: guid,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  const planData = data?.plan;
  const listProducts = data?.productsForPlan;

  return (
    <PlanDetail
      planData={planData}
      listProducts={listProducts}
      columns={columns}
    />
  );
}

export default PlanDetailPage;
