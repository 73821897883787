const actions = {
  SET_INVOICING_CUSTOMER: "SET_INVOICING_CUSTOMER",
  SET_RECEIVING_CUSTOMER: "SET_RECEIVING_CUSTOMER",
  SET_PAYMENT_PROVIDER: "SET_PAYMENT_PROVIDER",
  SET_STRIPE_PAYMENT_METHOD: "SET_STRIPE_PAYMENT_METHOD",
  SET_UNPAID: "SET_UNPAID",
  SET_PAID: "SET_PAID",
  SET_PLAN: "SET_PLAN",
  SET_CREATED: "SET_CREATED",
  SET_ACTIVATED: "SET_ACTIVATED",
  SET_SCHEDULED: "SET_SCHEDULED",
  SET_SCHEDULED_ENABLED: "SET_SCHEDULED_ENABLED",
};

export default actions;
