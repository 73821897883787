import "./App.css";

import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import CustomerUpdateForm from "../components/CustomerUpdateForm";
import { ErrorMessage } from "../components/ErrorMessage";
import {
  MUTATION_UPDATE_CUSTOMER,
  MUTATION_UPDATE_CUSTOMER_COUNTRY,
} from "../api/mutations";
import { Navigate } from "react-router";
import { QUERY_CUSTOMER } from "../api/queries";
import { extractApplicationError } from "../components/ErrorMessage";
import { getCustomerUrl } from "../api/urls";
import { useParams } from "react-router-dom";

function CustomerUpdatePage() {
  const [completed, setCompleted] = useState(false);
  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);
  let { customerUuid } = useParams();
  const guid = customerUuid;

  // Mutation for general customer update
  const [
    updateCustomerMutation,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(MUTATION_UPDATE_CUSTOMER, {
    onCompleted: () => {
      setCompleted(true);
    },
  });

  // Mutation for country update
  const [
    updateCustomerCountry,
    { loading: countryUpdateLoading, error: countryUpdateError },
  ] = useMutation(MUTATION_UPDATE_CUSTOMER_COUNTRY, {
    refetchQueries: [
      {
        query: QUERY_CUSTOMER,
        variables: { customerUuid: guid },
      },
    ],
  });

  const { loading, error, data } = useQuery(QUERY_CUSTOMER, {
    variables: {
      customerUuid: customerUuid,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  let customerData = data?.customer;
  const referralSources = data?.referralSources;

  let redirectContinueToList = null;
  if (!!shouldRedirectToList) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: `${getCustomerUrl(guid)}`,
        }}
      />
    );
  }

  // Combine all possible errors
  const updateError =
    extractApplicationError(mutationData) ||
    mutationError ||
    countryUpdateError;

  return (
    <>
      <CustomerUpdateForm
        data={customerData}
        updateData={mutationData}
        updateCustomer={updateCustomerMutation}
        updateCustomerCountry={updateCustomerCountry}
        updating={mutationLoading}
        updatingCountry={countryUpdateLoading}
        completed={completed}
        updateError={updateError}
        modalContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
        referralSources={referralSources}
        userSetting={data?.userSetting}
      />
      {redirectContinueToList}
    </>
  );
}

export default CustomerUpdatePage;
