import React, { useContext, useState } from "react";

import Alert from "react-bootstrap/Alert";
import Auth from "../api/auth";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { MUTATION_TOKEN_AUTH } from "../api/mutations";
import { Navigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import actions from "../actions/auth";
import { store } from "../store";
import urls from "../api/urls";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";

const REDIRECT_TO_DEFAULT = urls.URL_SUBSCRIPTION_NEW;

function LoginPage() {
  const { dispatch } = useContext(store);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [genericError, setGenericError] = useState(null);
  const [redirectionURL, setRedirectionURL] = useState(null);

  const [
    tokenAuthMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(MUTATION_TOKEN_AUTH);

  const location = useLocation();

  const redirectIfAuthenticated = () => {
    if (!Auth.isAuthenticated()) {
      return;
    }
    const urlParams = new URLSearchParams(location.search);
    const redirect_to = urlParams.get("redirect_to");
    let redirection_url = redirect_to ? redirect_to : REDIRECT_TO_DEFAULT;
    //TODO: Make sure only relative URLs are allowed
    setRedirectionURL(redirection_url);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    try {
      const returnValue = await tokenAuthMutation({
        variables: {
          username: data.username,
          password: data.password,
        },
      });
      const token = returnValue?.data?.tokenAuth.token;
      if (!token) {
        setGenericError("Impossibile autenticarsi. Token mancante");
      }
      Auth.setAccessToken(token);
      dispatch({ type: actions.ACTION_AUTH_LOG_IN });
      redirectIfAuthenticated();
    } catch (e) {
      // Error is caught by mutationError
      console.log(e);
    }
  };

  const buttonSpinner = <Spinner animation="border" variant="light" />;

  if (redirectionURL) {
    return <Navigate to={redirectionURL} />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h1>Medex Pay Login</h1>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          placeholder="peppino"
          autoComplete="username"
          {...register("username", { required: true })}
        />
        {errors.username ? (
          <Alert variant="warning">
            {errors.username?.type === "required" && "Username richiesto"}
          </Alert>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          {...register("password", { required: true })}
        />
        {errors.password ? (
          <Alert variant="warning">
            {errors.password?.type === "required" && "Password richiesta"}
          </Alert>
        ) : null}
      </Form.Group>

      <Button variant="primary" type="submit">
        {!mutationLoading ? "Login" : buttonSpinner}
      </Button>
      {mutationError ? (
        <Alert variant="danger">
          {" "}
          Errore autenticazione: {mutationError.toString()}
        </Alert>
      ) : null}
      {genericError ? (
        <Alert variant="danger"> Errore autenticazione: {genericError}</Alert>
      ) : null}
    </Form>
  );
}

export default LoginPage;
