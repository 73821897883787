import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useQuery } from "@apollo/client";
import { QUERY_SUBSCRIPTION_AFTER_CREATION } from "../api/queries";
import { useParams } from "react-router-dom";
import { ErrorMessage } from "../components/ErrorMessage";
import { ContractLink } from "../components/ContractLink";
import urls from "../api/urls";

function SubscriptionCreatedPage() {
  const urlParams = useParams();
  const skip = !urlParams.guid;
  const { error, data } = useQuery(QUERY_SUBSCRIPTION_AFTER_CREATION, {
    variables: {
      subscriptionUuid: urlParams.guid,
    },
    skip,
  });

  const subscriptionData = data?.subscription;
  const contractLinkParams = {
    signatureFormType: "NewContract",
    first: subscriptionData?.invoicingCustomer?.first,
    last: subscriptionData?.invoicingCustomer?.last,
    taxId: subscriptionData?.invoicingCustomer?.taxId,
    email: subscriptionData?.invoicingCustomer?.email,
    paymentType: subscriptionData?.paymentType,
    locationName: subscriptionData?.location.name,
    amount: subscriptionData?.plan?.price,
    agreementContract:
      subscriptionData?.plan?.agreementType?.signingDocumentUrl,
    subscriptionUuid: subscriptionData?.uuid,
    invoicingCustomerUuid: subscriptionData?.invoicingCustomer?.uuid,
  };
  const signContract = (
    <ContractLink subscription={contractLinkParams}></ContractLink>
  );

  return (
    <>
      <Row className="justify-content-md-center">
        <h1>Abbonamento Creato</h1>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={5}>
          <Row>
            <Col>
              <Button variant="primary" href={urls.URL_SUBSCRIPTION_NEW}>
                Nuovo Abbonamento
              </Button>
            </Col>
            <Col>{signContract}</Col>
          </Row>

          <Row>
            <Col>
              <ErrorMessage errors={[error]} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default SubscriptionCreatedPage;
