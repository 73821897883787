import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import CreditUpdateForm from "../components/CreditUpdateForm";
import { ErrorMessage } from "../components/ErrorMessage";
import { MUTATION_DELETE_CREDIT } from "../api/mutations";
import { MUTATION_UPDATE_CREDIT } from "../api/mutations";
import { Navigate } from "react-router";
import { QUERY_CREDIT_UPDATE } from "../api/queries";
import { getCreditDetailUrl } from "../api/urls";
import { useParams } from "react-router-dom";

function loadListCheckIn(checkInCredit) {
  let foundingSource;
  let listCheckIn = checkInCredit.map((s) => {
    foundingSource = !!s?.credit?.plan?.shortName
      ? (foundingSource = s?.credit?.plan?.shortName)
      : (foundingSource = s?.checkinFundingSource?.name);

    return {
      uuid: s?.uuid,
      checkInDate: s?.date,
      checkInPlanShortName: foundingSource,
      checkInProductName: s?.product?.name,
      checkInProductUuid: s?.product?.uuid,
    };
  });
  return listCheckIn;
}

function CreditUpdatePage() {
  let { creditUuid } = useParams();

  const [
    updateCreditMutation,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_UPDATE_CREDIT);

  const [
    deleteCreditMutation,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useMutation(MUTATION_DELETE_CREDIT);

  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);
  const { loading, error, data } = useQuery(QUERY_CREDIT_UPDATE, {
    variables: {
      creditUuid: creditUuid,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error | deleteError) {
    return <ErrorMessage errors={[error]} />;
  }

  const currentLocation = data?.userSetting?.currentLocation;
  const isSameLocation =
    currentLocation?.uuid === data?.credit?.location?.uuid ? true : false;
  const counterCheckIn = data?.checkInCounter;
  const checkinList = loadListCheckIn(data?.lastCheckInCredit);
  let firstCheckin;
  let lastCheckin;
  if (checkinList.length > 0) {
    lastCheckin = checkinList[0].checkInDate;
    firstCheckin = checkinList[checkinList.length - 1].checkInDate;
  }

  let redirectContinueToList = null;
  if (!!shouldRedirectToList || !isSameLocation) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: getCreditDetailUrl(creditUuid),
        }}
      />
    );
  }

  let redirectDeleteCompleted = null;

  if (!!deleteData) {
    redirectDeleteCompleted = (
      <Navigate
        to={{
          pathname: getCreditDetailUrl(creditUuid),
        }}
      />
    );
  }

  return (
    <>
      <CreditUpdateForm
        data={data}
        firstCheckin={firstCheckin}
        lastCheckin={lastCheckin}
        counterCheckIn={counterCheckIn}
        checkInNotPresentCounter={data?.checkInNotPresentCounter}
        deleteCredit={deleteCreditMutation}
        updateCredit={updateCreditMutation}
        creating={mutationLoading}
        completed={!!mutationData}
        createError={mutationError}
        deleting={deleteLoading}
        modalContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
      />
      {redirectDeleteCompleted}
      {redirectContinueToList}
    </>
  );
}

export default CreditUpdatePage;
