import { useMutation, useQuery } from "@apollo/client";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { LOCATION_DROPDOWN_DATA } from "../../api/queries";
import { MUTATION_UPDATE_USER_SETTING } from "../../api/mutations";
import { client } from "../../api/apollo_client";

function getErrorDropdown(error) {
  return (
    <DropdownButton
      id="dropdown-basic-button"
      title="Locations non disponibili"
      size="md"
      variant="warning"
      onSelect={() => {}}
      onClick={() => {}}
    >
      <Dropdown.Item>{error?.message}</Dropdown.Item>
    </DropdownButton>
  );
}

function LocationSelectDropdown() {
  const { loading, error, data } = useQuery(LOCATION_DROPDOWN_DATA);
  const [updateUserSetting] = useMutation(MUTATION_UPDATE_USER_SETTING);

  if (loading) {
    // Nothing gest displayed when loading
    return null;
  }
  if (error) {
    return getErrorDropdown(error);
  }
  const currentLocation = data["userSetting"]["currentLocation"];
  const locationList = data["locationsForUser"];
  const onlyOneLocation = locationList.length <= 1;
  /* if (onlyOneLocation) {
    // User cannot switch locations
    return null;
  }*/

  async function onSelect(eventKey, event) {
    const locationUUID = eventKey;
    if (onlyOneLocation) {
      /* Defensive programming
       No need to handle select if there is only one location
       available
      */
      return;
    }
    const result = await updateUserSetting({
      variables: {
        userSettingInput: {
          currentLocationUuid: locationUUID,
        },
      },
    });
    const error = result["data"]["updateUserSetting"]["error"];
    if (error) {
      throw new Error(error);
    }
    // Resets the cache to force a reload of all queries with a new location
    client.resetStore();
  }

  return (
    <DropdownButton
      id="dropdown-basic-button"
      title={currentLocation.name}
      size="md"
      variant="secondary"
      onSelect={onSelect}
      disabled={onlyOneLocation}
    >
      {locationList.map((location, index) => {
        const isCurrent = location.uuid === currentLocation.uuid;
        return (
          <Dropdown.Item
            key={location.uuid}
            eventKey={location.uuid}
            active={isCurrent}
          >
            {location.name}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
}

export default LocationSelectDropdown;
