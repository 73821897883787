import { gql } from "@apollo/client";

export const GET_CHECKIN_PRODUCT_PRICE = gql`
  query getCheckinProductPrice($checkinUuid: UUID) {
    productPrice: getProductPriceForCheckin(checkinUuid: $checkinUuid)
    checkin(checkinUuid: $checkinUuid) {
      uuid
      date
      productZones
      customer {
        uuid
        last
        first
        email
        taxId
        address {
          road
          city
          county
          postcode
          houseNumber
        }
      }
      product {
        uuid
        name
      }
    }
  }
`;

export const GET_INVOICE = gql`
  query getInvoice($invoiceUuid: UUID) {
    getInvoice(invoiceUuid: $invoiceUuid) {
      uuid
      status
      progressiveNumber
      sector
      date
      isLast
      customer {
        uuid
        last
        first
        email
        taxId
      }
      notes
      internalNotes
      amount
      vatAmount
      paymentType
      sdiSentDate
      receiptDestinationEmail
      documentType
      creditNoteId
      location {
        uuid
      }
      checkin {
        uuid
      }
    }
    getItemsForInvoice(invoiceUuid: $invoiceUuid) {
      quantity
      articleDescription
      unitPrice
      articlePrice
      vatRate
      vatAmount
      vat
      product {
        uuid
        name
      }
    }
    getCreditNoteForInvoice(invoiceUuid: $invoiceUuid) {
      uuid
      date
      status
      progressiveNumber
      sector
      notes
      internalNotes
      sdiSentDate
      receiptDestinationEmail
      isLast
    }
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
    }
  }
`;

export const GET_PRODUCT_PRICE_FOR_CHECKIN = gql`
  query getProductPriceForCheckin($checkinUuid: UUID) {
    price: getProductPriceForCheckin(checkinUuid: $checkinUuid)
  }
`;
