import { CustomerRow } from "../components/subscription/Common";
import {
  MUTATION_ACTIVATE_SUBSCRIPTION,
  MUTATION_CREATE_SUBSCRIPTION_V2,
} from "../api/mutations";
import React, { useContext, useState } from "react";
import { generateIdempotencyKey, removeEmptyFields } from "../api/utils";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { CustomerModule } from "../components/customer/CustomerModule";
import { ErrorMessage } from "../components/ErrorMessage";
import Form from "react-bootstrap/Form";
import { PaymentMethodModule } from "../components/subscription/PaymentMethodModule";
import PlanModule from "../components/subscription/PlanModule";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import StripeElementsWrapper from "../components/StripeElementsWrapper";
import actions from "../actions/newSubscription";
import { strict as assert } from "assert";
import { customerValidationType } from "../components/validators";
import { store } from "../store";
import urls from "../api/urls";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import { validationSubscriptionState } from "../components/validators";

// Invoicing customer Section
function Customer({
  customer,
  onChange,
  title,
  fullInfo,
  className,
  validation = customerValidationType.INVOICING,
}) {
  return (
    <CustomerRow>
      <Row>
        <Col>
          <h3>{title}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomerModule
            className={className}
            customer={customer}
            onSetCustomer={onChange}
            validation={validation}
            fullInfo={fullInfo}
          />
        </Col>
      </Row>
    </CustomerRow>
  );
}

// Receiving customer Section
function ReceivingCustomer({ customer, onChange }) {
  const [showReceivingCustomer, setShowReceivingCustomer] = useState(false);
  const receivingValidation = customerValidationType.RECEIVING;
  const receivingCustomer = (
    <Customer
      title="Cliente che usufruisce"
      validation={receivingValidation}
      fullInfo={false}
      customer={customer}
      onChange={onChange}
      className="receiving-customer"
    />
  );
  return (
    <>
      <Row>
        <Col>
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Il cliente che usufruisce è diverso da quello che paga"
            data-testid="receiving-customer-enable"
            size="lg"
            onChange={() => {
              setShowReceivingCustomer(!showReceivingCustomer);
            }}
          />
        </Col>
      </Row>
      {showReceivingCustomer ? receivingCustomer : null}
    </>
  );
}

// Creates a new Subscription from scratch
async function createNewSubscription(
  subscriptionState,
  createSubscriptionMutation
) {
  const idempotencyKey = generateIdempotencyKey();
  assert(!!idempotencyKey, "Missing idempotency key");

  const mutationVariables = {
    variables: {
      subscription: removeEmptyFields({
        invoicingCustomer: subscriptionState.invoicingCustomer.uuid,
        customer: subscriptionState?.receivingCustomer?.uuid,
        paymentType:
          subscriptionState.paymentProvider.paymentType.toUpperCase(),
        stripePaymentMethod: subscriptionState?.paymentProvider?.id, // this is Null for PagoDIL
        plan: subscriptionState.plan.uuid,
        quantity: 1,
        idempotencyKey,
        scheduledDate: subscriptionState.scheduledDate,
      }),
    },
  };

  const returnValue = await createSubscriptionMutation(mutationVariables);
  const result = returnValue?.data?.createSubscriptionV2?.result;
  let error =
    returnValue?.data?.createSubscriptionV2?.error || returnValue?.errors;
  return { result, error };
}

function SubscriptionPage() {
  // History hook
  const navigate = useNavigate();
  // State Management Hook
  const {
    dispatch,
    state: { newSubscription: subscriptionState },
  } = useContext(store);

  const stripe = useStripe();

  const [subscriptionCreationInProgress, setSubscriptionCreationInProgress] =
    useState(false);

  // Create Subscription Mutation Hook
  const [
    createSubscriptionMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(MUTATION_CREATE_SUBSCRIPTION_V2);

  // Activate Existing subscription Hook
  const [activateSubcriptionMutation] = useMutation(
    MUTATION_ACTIVATE_SUBSCRIPTION
  );

  const [subscriptionError, setSubcriptionError] = useState();

  // Payment Method
  const paymentMethod = subscriptionState.invoicingCustomer ? (
    <>
      <hr />
      <PaymentMethodModule
        paymentProvider={subscriptionState.paymentProvider}
        customer={subscriptionState.invoicingCustomer}
        onChange={(selectedProvider) => {
          dispatch({
            type: actions.SET_PAYMENT_PROVIDER,
            payload: selectedProvider,
          });
        }}
      />
    </>
  ) : null;

  // Form submission
  const createSubscription = async (e) => {
    e.preventDefault();
    try {
      setSubscriptionCreationInProgress(true);
      // Validate subscription state here
      await validationSubscriptionState.validate(subscriptionState);

      // New subscription
      let { result: creationResult, error } = await createNewSubscription(
        subscriptionState,
        createSubscriptionMutation
      );
      // ErrorCheck
      if (error) {
        throw error;
      }
      // SCA if necessary
      if (creationResult.requiresSca) {
        const confirmResult = await stripe.confirmCardPayment(
          creationResult.stripeScaSecret,
          {
            payment_method: subscriptionState?.paymentProvider?.id,
            setup_future_usage: "off_session",
          }
        );
        error = confirmResult.error;
        // ErrorCheck
        if (error) {
          throw error;
        }
      }

      // Subscription activation
      const activationResult = await activateSubcriptionMutation({
        variables: {
          subscriptionUUID: creationResult.subscription.uuid,
        },
      });

      // ErrorCheck
      if (activationResult.error) {
        throw error;
      }

      assert(!!creationResult);
      setSubscriptionCreationInProgress(false);

      const confirmationPageUrl = `${urls.URL_SUBSCRIPTION_NEW_CONFIRMATION}/${creationResult.subscription.uuid}`;
      navigate(confirmationPageUrl);
    } catch (error) {
      setSubscriptionCreationInProgress(false);
      setSubcriptionError(error);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <h1>Nuovo abbonamento</h1>
        </Col>
      </Row>
      <Customer
        customer={subscriptionState.invoicingCustomer}
        fullInfo={true}
        className="invoicing-customer"
        onChange={(customer) => {
          dispatch({
            type: actions.SET_INVOICING_CUSTOMER,
            payload: customer,
          });
        }}
      />
      <ReceivingCustomer
        customer={subscriptionState.receivingCustomer}
        onChange={(customer) => {
          dispatch({
            type: actions.SET_RECEIVING_CUSTOMER,
            payload: customer,
          });
        }}
      />
      {paymentMethod}
      <hr />
      <PlanModule
        onChange={(plan) => {
          dispatch({
            type: actions.SET_PLAN,
            payload: plan,
          });
        }}
      />
      {/* <hr />
      <Row>
        <Col>
          <ScheduledSubscription
            onOpenClose={(open) => {
              dispatch({
                type: actions.SET_SCHEDULED_ENABLED,
                payload: open,
              });
            }}
            onChange={(newDate) => {
              dispatch({
                type: actions.SET_SCHEDULED,
                payload: newDate,
              });
            }}
          />
        </Col>
      </Row>
      <hr /> */}
      {/* TODO: Add Notes components*/}
      <Row>
        <div className="mt-4" />
        <div className="mt-4" />
      </Row>
      <Row>
        <Col>
          <Button variant="primary" onClick={createSubscription}>
            {mutationLoading || subscriptionCreationInProgress ? (
              <Spinner animation="border" variant="light" />
            ) : (
              "Crea Abbonamento"
            )}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ErrorMessage errors={[subscriptionError, mutationError]} />
        </Col>
      </Row>
    </>
  );
}

const WrappedSubscriptionPage = () => (
  <StripeElementsWrapper>
    <SubscriptionPage />
  </StripeElementsWrapper>
);

export { WrappedSubscriptionPage as SubscriptionPage };
