import React, { useState } from "react";

import { BiCreditCard } from "react-icons/bi";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import { CreateStripePaymentModal } from "./CreateStripePaymentModal";
import { ErrorMessage } from "../../components/ErrorMessage";
import PropTypes from "prop-types";
import { QUERY_STRIPE_PAYMENT_METHODS_FOR_CUSTOMER } from "../../api/queries";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import ToggleButton from "react-bootstrap/ToggleButton";
import constants from "../../constants";
import styled from "@emotion/styled/macro";
import { useQuery } from "@apollo/client";

const ButtonText = styled.span`
  margin-left: 0.5rem;
`;

function PaymentMethodStripe({ customer, onChange }) {
  const [createCardModalShow, setCreateCardModalShow] = useState(false);

  const { loading, error, data, refetch } = useQuery(
    QUERY_STRIPE_PAYMENT_METHODS_FOR_CUSTOMER,
    {
      variables: {
        customerUuid: customer.uuid,
      },
    }
  );
  // Do the query to check for existing payment methods
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }
  if (loading) {
    return <div>Loading..</div>;
  }
  // Payment Selector
  const paymentList = data?.stripePaymentMethodsForCustomer;
  let paymentSelector = null;
  if (paymentList.length > 0) {
    const paymentSelectorData = paymentList.map((p) => {
      return {
        value: p.id,
        label: `**** ${p.paymentVisibleIdentifier}`,
      };
    });
    paymentSelector = (
      <Col className="select-stripe-payment-method">
        <Select
          options={paymentSelectorData}
          styles={{
            menu: (base) => ({
              ...base,
              zIndex: "5",
            }),
          }}
          onChange={({ value }) => {
            onChange({
              paymentType: "stripe",
              id: value,
            });
          }}
        />
      </Col>
    );
  }

  return (
    <>
      <Row>
        {paymentSelector}
        <Col>
          <Button variant="light" style={{ verticalAlign: "baseline" }}>
            <BiCreditCard size={24} />
            <ButtonText
              onClick={() => {
                setCreateCardModalShow(true);
              }}
            >
              Aggiungi carta
            </ButtonText>
          </Button>
        </Col>
      </Row>
      <CreateStripePaymentModal
        show={createCardModalShow}
        onHide={() => {
          setCreateCardModalShow(false);
        }}
        customer={customer}
        onCreated={(customer, setupIntent) => {
          refetch();
          setCreateCardModalShow(false);
        }}
      />
    </>
  );
}

function PaymentMethodPagoDil() {
  return (
    <div>
      Assicurarsi che il pagamento sia stato completato sul terminale PagoDIL
    </div>
  );
}

const paymentProviders = {
  [constants.paymentProviders.STRIPE]: {
    text: "Carta",
    component: PaymentMethodStripe,
  },
  [constants.paymentProviders.PAGODIL]: {
    text: "Pagodil",
    component: PaymentMethodPagoDil,
  },
};

function PaymentMethodModule({
  paymentProvider,
  customer,
  onChange = () => {},
}) {
  const paymentProviderType = paymentProvider.paymentType;
  const PaymentProviderComponent =
    paymentProviders[paymentProviderType].component;

  const providerButtons = Object.keys(paymentProviders).map((key) => {
    return (
      <ToggleButton
        key={key}
        id={key}
        type="radio"
        variant={"outline-secondary"}
        checked={paymentProviderType === key}
        name="radio"
        value={key}
        onChange={(e) => {
          // Only sets paymentType
          onChange({ paymentType: e.currentTarget.value });
        }}
      >
        {paymentProviders[key].text}
      </ToggleButton>
    );
  });

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <h3>Metodo di pagamento</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonGroup>{providerButtons}</ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <PaymentProviderComponent customer={customer} onChange={onChange} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

PaymentMethodModule.propTypes = {
  paymentProvider: PropTypes.shape({
    paymentType: PropTypes.string,
  }).isRequired,
  customer: PropTypes.PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
};

export { PaymentMethodStripe, PaymentMethodPagoDil, PaymentMethodModule };
