import authActions from "../actions/auth";

export default function authReducer(state, action) {
  switch (action.type) {
    // AUTH
    case authActions.ACTION_AUTH_LOG_IN:
      return { ...state, loggedIn: true };
    case authActions.ACTION_AUTH_LOG_OUT:
      return { ...state, loggedIn: false };
    case authActions.ACTION_AUTH_UPDATE:
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
      };
    default:
      return state;
  }
}
