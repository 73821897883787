import React, { useState } from "react";

import styled from "@emotion/styled/macro";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { formatDateIso } from "../../api/date-utils";

// Styles
export const CustomerRow = styled.section`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

// Fading section
export function OptionShowSwitch(props) {
  const { open = false, onChange = () => {} } = props;
  const [showSwitch, setShowSwitch] = useState(open);
  let cascadeProps = { ...props };
  delete cascadeProps.children;

  return (
    <>
      <Row>
        <Col>
          <Form.Check
            type="switch"
            {...cascadeProps}
            onChange={() => {
              setShowSwitch(!showSwitch);
              onChange(!showSwitch);
            }}
          />
        </Col>
      </Row>
      {showSwitch ? props.children : null}
    </>
  );
}

export function ScheduledSubscription({ onChange, onOpenClose, open = false }) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const minDate = formatDateIso(tomorrow);

  return (
    <>
      <OptionShowSwitch
        id="scheduled-subscription-switch"
        label="Abbonamento schedulato 📅"
        data-testid="scheduled-subscription-enable"
        size="lg"
        open={open}
        onChange={(value) => {
          onOpenClose(value);
        }} // Calls onChange with true/false
      >
        <Row>
          <Col>
            <Form.Control
              type="date"
              min={minDate}
              required
              data-testid="scheduled-subscription-date"
              onChange={(e) => {
                const val = e.currentTarget.value;
                if (!val) {
                  onChange(null);
                }
                onChange(e.currentTarget.value);
              }}
            ></Form.Control>
          </Col>
        </Row>
      </OptionShowSwitch>
    </>
  );
}
