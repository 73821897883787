import React, { useState } from "react";
import {
  getCustomerStripeUrl,
  getCustomerUrl,
  getPlanUrl,
  getSubscriptionDetailUrl,
  getSubscriptionStripeUrl,
} from "../api/urls";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ErrorMessage } from "./ErrorMessage";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import StateIcon from "./StateIcon";
import Tooltip from "react-bootstrap/Tooltip";
import addBsArrowRight from "../components/AddBsArrowRight";
import constants from "../constants";
import dropDownHeaderView from "../components/DropDownHeaderView";
import { isUserInGroup } from "../api/utils";
import { formatDateIso, formatTimeIso, diffDays } from "../api/date-utils";

const statusDetailDraft = "draft";

const bodyVoid = (
  <p>
    Questa azione annulla definitivamente l'addebito della rata.
    <br />
    Questo comporta:
    <br />
    1) l'abbonamento non è più nello stato di "Insoluto"
    <br />
    2) il sistema blocca, per questa rata, ulteriori tentativi di addebito
    <br />
    3) l'addebito della rata non potrà più avvenire
    <br />
  </p>
);

const bodyUncollettible = (
  <p>
    Questa azione annulla rende inesigibile l'addebito della rata.
    <br />
    Questo comporta:
    <br />
    1) l'abbonamento non è più nello stato di "Insoluto"
    <br />
    2) il sistema blocca, per questa rata, ulteriori tentativi di addebito
    <br />
    3) l'addebito della rata può avvenire solo manualmente
    <br />
  </p>
);
const bodyRetryCharge = (
  <p>
    Questa azione ritenta l'addebito della rata.
    <br />
    Se l'addebito va a buon fine:
    <br />
    1) l'abbonamento non è più nello stato di "Insoluto"
    <br />
    Se l'addebito non va a buon fine:
    <br />
    1) l'abbonamento rimane nello stato di "Insoluto"
    <br />
    2) il sistema continua periodicamente l'addebito la rata
    <br />
  </p>
);
const bodyRefund = (
  <p>
    Questa azione effettua il rimborso della rata addebitata in precedenza.
    <br />
    Il rimborso effettuato è definitivo e non può essere annullato
    <br />
  </p>
);

const bodyCancelExpired = (
  <p>
    Questa azione effettua l'annullamento della scadenza della rata.
    <br />
    L'annullamento della scadenza effettuato è definitivo e non può essere
    ripristinato.
    <br />
    La rata, dopo l'annullamento della scadenza, torna ad essere utilizzabile in
    un Check-In.
    <br />
  </p>
);

function invoiceCondition(invoiceData) {
  let invoiceState = null;

  const passedDays = !!invoiceData?.created
    ? diffDays(new Date(invoiceData?.created), new Date())
    : "";

  const stateInsoluto = `Insoluta (da ${passedDays} gg)`;

  const nextPaymentAttempt = !!invoiceData?.nextPaymentAttempt
    ? `${formatDateIso(
        new Date(invoiceData?.nextPaymentAttempt)
      )} ore ${formatTimeIso(
        new Date(invoiceData?.nextPaymentAttempt)
      ).substring(0, 8)}`
    : "Tentativi esauriti";

  if (!!invoiceData?.paidAt) {
    invoiceState = (
      <>
        <Row>
          <Col sm={2}>
            <h6> Stato </h6>
            <span>{invoiceData?.invoiceExpired ? "Scaduta" : "Pagata"} </span>
          </Col>
          <Col>
            <h6>Data Pagamento</h6>
            <span>{invoiceData?.paidAt}</span>
          </Col>
        </Row>
      </>
    );
    if (!!invoiceData?.refundedDate) {
      invoiceState = (
        <>
          <Row>
            <Col sm={2}>
              <h6> Stato </h6>
              <span>Rimborsata </span>
            </Col>
            <Col sm={2}>
              <h6>Data Pagamento</h6>
              <span>{invoiceData?.paidAt}</span>
            </Col>
            <Col>
              <h6>Data Rimborso</h6>
              <span>{invoiceData?.refundedDate}</span>
            </Col>
          </Row>
        </>
      );
    }
    return invoiceState;
  }

  if (!!invoiceData?.voidedAt) {
    invoiceState = (
      <>
        <Row>
          <Col sm={2}>
            <h6> Stato </h6>
            <span>{stateInsoluto} </span>
          </Col>
          <Col sm={2}>
            <h6> Condizione </h6>
            <span>Annullata </span>
          </Col>
          <Col>
            <h6>Data Annullamento</h6>
            <span>{invoiceData?.voidedAt}</span>
          </Col>
        </Row>
      </>
    );
    return invoiceState;
  }

  if (!!invoiceData?.markedUncollectibleAt) {
    invoiceState = (
      <>
        <Row>
          <Col sm={2}>
            <h6> Stato </h6>
            <span>{stateInsoluto} </span>
          </Col>
          <Col sm={2}>
            <h6> Condizione </h6>
            <span>Inesigibile </span>
          </Col>
          <Col sm={2}>
            <h6>Data Inesigibilità</h6>
            <span>{invoiceData?.markedUncollectibleAt}</span>
          </Col>
          <Col>
            <h6>Numero Tentativi Addebito</h6>
            <span>{invoiceData?.attemptCount}</span>
          </Col>
          <Col>
            <h6>Prossimo Tentativo Addebito</h6>
            <span>{nextPaymentAttempt}</span>
          </Col>
        </Row>
        <Row>
          <div className="mt-4" />
        </Row>
        <Row>
          <Col>
            <h6>Messaggio di errore</h6>
            <span>{invoiceData?.statusDetail}</span>
          </Col>
        </Row>
      </>
    );
    return invoiceState;
  }

  if (!!invoiceData?.chargedAt) {
    invoiceState = (
      <>
        <Row>
          <Col sm={2}>
            <h6> Stato </h6>
            <span>{stateInsoluto} </span>
          </Col>
          <Col sm={3}>
            <h6> Data ultimo tentativo addebito</h6>
            <span>{invoiceData?.chargedAt} </span>
          </Col>
          <Col>
            <h6>Numero Tentativi Addebito</h6>
            <span>{invoiceData?.attemptCount}</span>
          </Col>
          <Col>
            <h6>Prossimo Tentativo Addebito</h6>
            <span>{nextPaymentAttempt}</span>
          </Col>
        </Row>
        <Row>
          <div className="mt-4" />
        </Row>
        <Row>
          <Col>
            <h6>Messaggio di errore</h6>
            <span>
              {invoiceData?.statusDetail}--{invoiceData?.errorMessage}
            </span>
          </Col>
        </Row>
      </>
    );
  }
  return invoiceState;
}

function invoiceConditionAction(
  userSetting,
  invoiceData,
  retryChargeUpdating,
  voidUpdating,
  markUncollectibleUpdating,
  creditNoteUpdating,
  buttonSpinner,
  setShowModalConfirm,
  isSameLocation
) {
  let invoiceStateAction = null;
  let placement = "bottom";

  const btnRetryCharge = (
    <OverlayTrigger
      key={"btn1"}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-btn1`}>
          <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_ACTIVE}></StateIcon>
          <strong>Riprova</strong> l'addebito della rata sospesa.
        </Tooltip>
      }
    >
      <Button
        variant={"outline-primary"}
        size="sm"
        disabled={retryChargeUpdating || !isSameLocation}
        key={"zb1"}
        onClick={() => {
          setShowModalConfirm({
            show: true,
            typeExec: "retry",
            head: "Addebito Rata",
            body: bodyRetryCharge,
            btnExit: "Esci senza Addebitare",
            btnExec: "Riprova Addebito",
            btnIcon: constants.SUBSCRIPTION_STATE_ACTIVE,
          });
        }}
      >
        <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_ACTIVE}></StateIcon>
        {retryChargeUpdating ? buttonSpinner : "Riprova Addebito"}
      </Button>
    </OverlayTrigger>
  );
  const btnVoid = (
    <OverlayTrigger
      key={"btn1"}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-btn1`}>
          <StateIcon
            nameIcon={constants.SUBSCRIPTION_STATE_CANCELED}
          ></StateIcon>
          <strong>Annulla</strong> definitivamente l'addebito della rata
          sospesa.
        </Tooltip>
      }
    >
      <Button
        variant={"outline-primary"}
        size="sm"
        disabled={voidUpdating || !isSameLocation}
        key={"zb2"}
        onClick={() => {
          setShowModalConfirm({
            show: true,
            typeExec: "void",
            head: "Annulla Rata",
            body: bodyVoid,
            btnExit: "Esci senza Annullare",
            btnExec: "Annulla",
            btnIcon: constants.SUBSCRIPTION_STATE_CANCELED,
          });
        }}
      >
        <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_CANCELED}></StateIcon>
        {voidUpdating ? buttonSpinner : "Annulla"}
      </Button>
    </OverlayTrigger>
  );
  const btnRefund = (
    <OverlayTrigger
      key={"btn1"}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-btn1`}>
          <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_ACTIVE}></StateIcon>
          <strong>Rimborsa</strong> l'addebito della rata.
        </Tooltip>
      }
    >
      <Button
        variant={"outline-primary"}
        size="sm"
        disabled={creditNoteUpdating || !isSameLocation}
        key={"za1"}
        onClick={() => {
          setShowModalConfirm({
            show: true,
            typeExec: "refund",
            head: "Rimborso Rata",
            body: bodyRefund,
            btnExit: "Esci senza Rimborsare",
            btnExec: "Rimborsa",
            btnIcon: constants.SUBSCRIPTION_STATE_ACTIVE,
          });
        }}
      >
        <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_ACTIVE}></StateIcon>
        {creditNoteUpdating ? buttonSpinner : "Rimborsa"}
      </Button>
    </OverlayTrigger>
  );
  let btnUncollettible = (
    <OverlayTrigger
      key={"btn1"}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-btn1`}>
          <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_PAUSED}></StateIcon>
          <strong>Rende Inesigibile</strong> l'addebito della rata.
        </Tooltip>
      }
    >
      <Button
        variant={"outline-primary"}
        size="sm"
        disabled={markUncollectibleUpdating || !isSameLocation}
        key={"zc2"}
        onClick={() => {
          setShowModalConfirm({
            show: true,
            typeExec: "uncollettible",
            head: "Inesigibilità Rata",
            body: bodyUncollettible,
            btnExit: "Esci senza rendere Inesigibile",
            btnExec: "Rendi Inesigibile",
            btnIcon: constants.SUBSCRIPTION_STATE_PAUSED,
          });
        }}
      >
        <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_PAUSED}></StateIcon>
        {markUncollectibleUpdating ? buttonSpinner : "Rendi Inesigibile"}
      </Button>
    </OverlayTrigger>
  );

  const btnCancelExpired = (
    <OverlayTrigger
      key={"btn1"}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-btn1`}>
          <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_ACTIVE}></StateIcon>
          <strong>Annulla</strong> scadenza della rata.
        </Tooltip>
      }
    >
      <Button
        variant={"outline-primary"}
        size="sm"
        disabled={creditNoteUpdating || !isSameLocation}
        // key={"za11"}
        onClick={() => {
          setShowModalConfirm({
            show: true,
            typeExec: "cancelExpired",
            head: "Annulla Scadenza Rata",
            body: bodyCancelExpired,
            btnExit: "Esci senza annullare",
            btnExec: "Annulla Scadenza",
            btnIcon: constants.SUBSCRIPTION_STATE_ACTIVE,
          });
        }}
      >
        <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_ACTIVE}></StateIcon>
        {creditNoteUpdating ? buttonSpinner : "Annulla Scadenza"}
      </Button>
    </OverlayTrigger>
  );

  btnUncollettible = null; // We have decided to no longer mark the installments as "Uncollettible" but to leave the code for future use.

  if (!!invoiceData?.paidAt) {
    const isManagementInvoicesExpired = isUserInGroup(
      userSetting,
      constants.GROUPS_MANAGEMENT_INVOICE_EXPIRED
    );
    invoiceStateAction = (
      <>
        <Row>
          <Col sm={2}>{btnRefund}</Col>
          {invoiceData?.invoiceExpired & isManagementInvoicesExpired ? (
            <Col sm={2}>{btnCancelExpired}</Col>
          ) : null}{" "}
        </Row>
      </>
    );
    if (!!invoiceData?.refundedDate) {
      invoiceStateAction = null;
    }
    return invoiceStateAction;
  }

  if (!!invoiceData?.voidedAt) {
    return null;
  }

  if (!!invoiceData?.markedUncollectibleAt) {
    invoiceStateAction = (
      <>
        <Row>
          <Col sm={2}>{btnRetryCharge}</Col>
          <Col sm={2}>{btnVoid}</Col>
        </Row>
      </>
    );
    return invoiceStateAction;
  }
  if (!!invoiceData?.chargedAt) {
    invoiceStateAction = (
      <>
        <Row>
          <Col sm={2}>{btnRetryCharge}</Col>
          <Col sm={2}>{btnVoid}</Col>
          <Col sm={2}>{btnUncollettible}</Col>
        </Row>
      </>
    );
  }

  if (invoiceData?.statusDetail === statusDetailDraft) {
    invoiceStateAction = (
      <>
        <Row>
          <Col sm={2}>{btnRetryCharge}</Col>
          <Col sm={2}>{btnVoid}</Col>
          <Col sm={2}>{btnUncollettible}</Col>
        </Row>
      </>
    );
  }

  return invoiceStateAction;
}
//-----------------------------------------------------------
function ConfirmModal({ onConfirm, onCancel, show }) {
  // Parameter
  // typeExec, head, body, btnExit, btnExec, btnIcon
  const typeExec = show.typeExec;
  const onClickConfirm = async () => {
    onConfirm(typeExec);
  };

  return (
    <Modal
      show={show.show}
      onHide={onCancel}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{show.head}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{show.body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {show.btnExit}
        </Button>
        <Button variant="danger" onClick={onClickConfirm}>
          <StateIcon nameIcon={show.btnIcon}></StateIcon>
          {show.btnExec}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

//-----------------------------------------------------------
function getLinkCustomerStripe(customerId) {
  if (!customerId) return <></>;
  const item = (
    <Dropdown.Item href={getCustomerStripeUrl(customerId)} target="_blank">
      {addBsArrowRight("Cliente Stripe")}
    </Dropdown.Item>
  );
  return item;
}

function getLinkSubscriptionStripe(subscriptionId) {
  if (!subscriptionId) return <></>;
  const item = (
    <Dropdown.Item
      href={getSubscriptionStripeUrl(subscriptionId)}
      target="_blank"
    >
      {addBsArrowRight("Abbonamento Stripe")}
    </Dropdown.Item>
  );
  return item;
}

function InvoiceDetailForm({
  data,
  invoiceData,
  retryChargeMutation,
  retryChargeUpdating,
  retryChargeError,
  voidInvoiceMutation,
  voidUpdating,
  voidError,
  markUncollectibleInvoiceMutation,
  markUncollectibleUpdating,
  markUncollectibleError,
  creditNoteInvoiceMutation,
  creditNoteUpdating,
  creditNoteError,
  updateExpiredInvoiceMutation,
  expiredInvoiceUpdating,
  expiredInvoiceError,
  completed,
  modalContinueClicked = () => {},
  userSetting,
}) {
  const currentLocation = userSetting?.currentLocation;
  const dataInvoice = invoiceData?.getStripeInvoice?.result;

  const [showModalConfirm, setShowModalConfirm] = useState({
    show: false,
    typeExec: "",
    head: "",
    body: "",
    btnExit: "",
    btnExec: "",
    btnIcon: "",
  });

  const [invoiceError, setInvoiceError] = useState();

  const isManagementStripeMenu = isUserInGroup(
    userSetting,
    constants.GROUPS_MANAGEMENT_STRIPE_MENU
  );

  const buttonSpinner = <Spinner animation="border" variant="light" />;

  const updatingSuccess = (
    <Alert variant="success"> Operazione conclusa </Alert>
  );

  const paySubscription = invoiceData?.stripeSubscription?.result;

  let paymentType = data?.paymentType;

  const isSameLocation =
    currentLocation?.uuid === data?.location?.uuid ? true : false;

  if (data?.paymentType === constants.STRIPE_PAYMENT_METHOD) {
    if (!!invoiceData) {
      let pause_behavior =
        paySubscription?.pauseBehavior === null
          ? ""
          : `-- ${paySubscription?.pauseBehavior}`;
      paymentType = `${data?.paymentType} -- ${paySubscription?.status} ${pause_behavior}`;
    } else {
      paymentType = `${data?.paymentType} -- Query failed`;
    }
  }

  const dropdownCustomer =
    data?.customer?.uuid === data?.invoicingCustomer?.uuid ? null : (
      <Dropdown.Item href={getCustomerUrl(data?.customer?.uuid)}>
        Beneficiario
      </Dropdown.Item>
    );

  const customerStripeId = data?.invoicingCustomer?.stripeId;
  const subscriptionStripeId = data?.stripeSubscriptionId;

  let montlyPayment = (
    <h6>
      (Periodo dal: {dataInvoice?.dateStart} al: {dataInvoice?.dateEnd}
      {" -- "}
      Importo € {!!dataInvoice?.amountDue ? dataInvoice?.amountDue / 100 : null}
      )
    </h6>
  );
  const montlyPaymentSuspend = (
    <h6>
      (Periodo dal: {dataInvoice?.dateStart} al: {dataInvoice?.dateEnd}
      {" -- "}
      {dataInvoice?.errorMessage}
      {" -- "}
      {dataInvoice?.statusDetail})
    </h6>
  );

  montlyPayment =
    dataInvoice?.statusDetail === statusDetailDraft
      ? montlyPaymentSuspend
      : montlyPayment;

  return (
    <>
      <Row>
        <Col xs={8}>
          <h4 className="text-center"> Rata Abbonamento </h4>
        </Col>
        <Col xs={4}>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <ButtonGroup>
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="outline-primary"
              >
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item
                  href={getCustomerUrl(data?.invoicingCustomer?.uuid)}
                >
                  {addBsArrowRight("Titolare")}
                </Dropdown.Item>
                {dropdownCustomer}
                <Dropdown.Item href={getPlanUrl(data?.plan?.uuid)}>
                  {addBsArrowRight("Piano")}
                </Dropdown.Item>
                <Dropdown.Item href={getSubscriptionDetailUrl(data?.uuid)}>
                  {addBsArrowRight("Abbonamento")}
                </Dropdown.Item>
                {isManagementStripeMenu
                  ? getLinkCustomerStripe(customerStripeId)
                  : null}
                {isManagementStripeMenu
                  ? getLinkSubscriptionStripe(subscriptionStripeId)
                  : null}
              </DropdownButton>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
      <h5> Cliente Beneficiario</h5>
      <Row>
        <Col>
          <h6> Nome: </h6>
          <span>{data?.customer?.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{data?.customer?.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{data?.customer?.email}</span>
        </Col>
      </Row>
      <hr />
      <h5> Cliente Titolare Pagamenti</h5>
      <Row>
        <Col>
          <h6> Nome: </h6>
          <span>{data?.invoicingCustomer?.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{data?.invoicingCustomer?.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{data?.invoicingCustomer?.email}</span>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm={2}>
          <h5> Abbonamento </h5>
        </Col>
        <Col>
          <h6> (Sede corrente: {data?.location?.name})</h6>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          <h6> Data Inizio </h6>
          <span>{data?.startDate} </span>
        </Col>
        <Col>
          <h6>Piano</h6>
          <span>{data?.plan?.name}</span>
        </Col>
        <Col>
          <h6>Metodo di pagamento </h6>
          <span>{paymentType}</span>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm={1}>
          <h5> Rata </h5>
        </Col>
        <Col sm={8}>
          {montlyPayment}
          {/* <h6>
            (Periodo dal: {dataInvoice?.dateStart} al: {dataInvoice?.dateEnd}
            {" -- "}
            Importo €{" "}
            {!!dataInvoice?.amountDue ? dataInvoice?.amountDue / 100 : null})
          </h6> */}
        </Col>
      </Row>
      {invoiceCondition(dataInvoice)}
      <Row>
        <div className="mt-4" />
      </Row>
      {!completed
        ? invoiceConditionAction(
            userSetting,
            dataInvoice,
            retryChargeUpdating,
            voidUpdating,
            markUncollectibleUpdating,
            creditNoteUpdating,
            buttonSpinner,
            setShowModalConfirm,
            isSameLocation,
            expiredInvoiceUpdating
          )
        : null}
      <Row>
        <ErrorMessage
          error={[
            invoiceError,
            retryChargeError,
            voidError,
            markUncollectibleError,
            creditNoteError,
            expiredInvoiceError,
          ]}
        />
      </Row>
      <Row>{completed ? updatingSuccess : null}</Row>

      <ConfirmModal
        show={showModalConfirm}
        onConfirm={async (typeExec) => {
          const invoiceId = dataInvoice?.stripeInvoiceId;
          const subscriptionUuid = data?.uuid;
          const invoiceUuid = dataInvoice?.invoiceUuid;
          try {
            switch (typeExec) {
              case "retry":
                const retryChargeData = await retryChargeMutation({
                  variables: {
                    invoiceId,
                  },
                });
                // ErrorCheck
                if (!!retryChargeData?.data?.payStripeInvoice?.error?.message) {
                  alert(
                    retryChargeData?.data?.payStripeInvoice?.error?.message
                  );
                } else {
                  alert("Addebito effettuato con successo");
                }
                break;
              case "void":
                const voidInvoice = await voidInvoiceMutation({
                  variables: {
                    invoiceId,
                    subscriptionUuid,
                  },
                });
                // ErrorCheck
                if (!!voidInvoice?.data?.voidStripeInvoice?.error?.message) {
                  alert(voidInvoice?.data?.voidStripeInvoice?.error?.message);
                } else {
                  alert("La rata è stata annullata");
                }
                break;
              case "refund":
                const creditNoteData = await creditNoteInvoiceMutation({
                  variables: {
                    invoiceId,
                  },
                });
                // ErrorCheck
                if (
                  !!creditNoteData?.data?.creditNoteStripeInvoice?.error
                    ?.message
                ) {
                  alert(
                    creditNoteData?.data?.creditNoteStripeInvoice?.error
                      ?.message
                  );
                } else {
                  alert("La rata è stata rimborsata");
                }
                break;
              case "uncollettible":
                const markUncollectibleData =
                  await markUncollectibleInvoiceMutation({
                    variables: {
                      invoiceId,
                      subscriptionUuid,
                    },
                  });
                // ErrorCheck
                if (
                  !!markUncollectibleData?.data?.markUncollectibleStripeInvoic
                    ?.error?.message
                ) {
                  alert(
                    markUncollectibleData?.data?.markUncollectibleStripeInvoic
                      ?.error?.message
                  );
                } else {
                  alert("La rata è stata resa inesigibile");
                }
                break;
              case "cancelExpired":
                const expired_value = false;

                const cancelExpiredData = await updateExpiredInvoiceMutation({
                  variables: {
                    expired: expired_value,
                    guid: invoiceUuid,
                  },
                });

                if (cancelExpiredData.errors) {
                  const errorMessage = cancelExpiredData.errors
                    .map((e) => e.message)
                    .join(", ");
                  console.error(
                    "Errore durante l'aggiornamento della rata:",
                    errorMessage
                  );
                } else {
                  alert("La rata è stata aggiornata con successo");
                }
                break;
              default:
                const retryChargeData1 = await retryChargeMutation({
                  variables: {
                    invoiceId,
                  },
                });
                // ErrorCheck
                if (
                  !!retryChargeData1?.data?.payStripeInvoice?.error?.message
                ) {
                  alert(
                    retryChargeData1?.data?.payStripeInvoice?.error?.message
                  );
                } else {
                  alert("Addebito effettuato con successo");
                }
            }
          } catch (e) {
            console.error(
              "Errore imprevisto durante l'aggiornamento della rata:",
              e.message
            );
            setInvoiceError(e);
          }
          setShowModalConfirm({ show: false });
        }}
        onCancel={() => {
          setShowModalConfirm({ show: false });
        }}
      />
    </>
  );
}

export default InvoiceDetailForm;
