import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import CustomerCheckInUpdateForm from "../components/CustomerCheckInUpdateForm";
import { ErrorMessage } from "../components/ErrorMessage";
import { MUTATION_DELETE_CHECKIN } from "../api/mutations";
import { MUTATION_UPDATE_CHECKIN } from "../api/mutations";
import { Navigate } from "react-router";
import { QUERY_LOAD_CHECKIN_UPDATE } from "../api/queries";
import constants from "../constants";
import { getCustomerUrl } from "../api/urls";
import { useParams } from "react-router-dom";
import { lookUpCadenceType } from "../api/utils";

function loadTypeCheckInUpdt(
  cfs,
  sub,
  cfsUuid,
  subUuid,
  customerCreditList,
  customerPrepaidPackageList
) {
  //cfs=allCheckinFundingSource
  //sub= customerSubscriptionsEnabled
  //ccr= customerCreditList
  var listTypeChk = [];
  var k;
  const UNCHECKED = 0;
  const CHECKED = 1;
  let checkinCadence;

  for (const customerCredit in customerCreditList) {
    //Check if the Checkin does not belong at credit non enabled
    //In this case need not be displayed the credit
    let customerCreditK = customerCreditList[customerCredit].credit;
    let todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    let dateEnd = new Date(customerCreditK?.endDate);
    dateEnd.setHours(0, 0, 0, 0);

    const remainingCheckins =
      customerCreditK?.sessionQuantity -
      customerCreditList[customerCredit].checkinCount;
    const enabled =
      dateEnd.getTime() <= todayDate.getTime() ||
      customerCreditList[customerCredit].checkinCount >=
        customerCreditK.sessionQuantity ||
      customerCreditK?.state !== constants.CREDIT_STATE_ACTIVE;

    if (enabled & (subUuid !== customerCreditK?.uuid)) continue;

    checkinCadence = lookUpCadenceType(customerCreditK?.plan?.checkinCadence);
    //Load credit,
    if (!subUuid) {
      listTypeChk.push({
        type: constants.TYPE_CHECKINLIST_CREDIT,
        uuid: customerCreditK?.uuid,
        name: customerCreditK?.plan?.name,
        planUuid: customerCreditK?.plan?.uuid,
        zone: customerCreditK?.zonesQuantity,
        startDate: customerCreditK?.startDate,
        enabled: !enabled,
        creditTypeName: customerCreditK.creditType.name,
        checked: UNCHECKED,
        invoices: null,
        checkinCadence: checkinCadence,
        state: customerCreditK?.state,
        remainingCheckins: remainingCheckins,
      });
    } else {
      if (subUuid === customerCreditK.uuid) {
        listTypeChk.push({
          type: constants.TYPE_CHECKINLIST_CREDIT,
          uuid: customerCreditK?.uuid,
          name: customerCreditK?.plan?.name,
          planUuid: customerCreditK?.plan?.uuid,
          zone: customerCreditK?.zonesQuantity,
          startDate: customerCreditK?.startDate,
          enabled: !enabled,
          creditTypeName: customerCreditK.creditType.name,
          checked: CHECKED,
          invoices: null,
          checkinCadence: checkinCadence,
          state: customerCreditK?.state,
          remainingCheckins: remainingCheckins,
        });
      } else {
        listTypeChk.push({
          type: constants.TYPE_CHECKINLIST_CREDIT,
          uuid: customerCreditK?.uuid,
          name: customerCreditK?.plan?.name,
          planUuid: customerCreditK?.plan?.uuid,
          zone: customerCreditK?.zonesQuantity,
          startDate: customerCreditK?.startDate,
          creditTypeName: customerCreditK.creditType.name,
          enabled: !enabled,
          checked: UNCHECKED,
          invoices: null,
          checkinCadence: checkinCadence,
          state: customerCreditK?.state,
          remainingCheckins: remainingCheckins,
        });
      }
    }
  }

  for (const customerPrepaidPakage in customerPrepaidPackageList) {
    //Check if the Checkin does not belong at prepaid pakage non enabled
    //In this case need not be displayed the prepaid package

    let customerPrepaidPakageK =
      customerPrepaidPackageList[customerPrepaidPakage].PrepaidPackage;
    let todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    let dateEnd = new Date(customerPrepaidPakageK?.endDate);
    dateEnd.setHours(0, 0, 0, 0);
    const remainingCheckins =
      customerPrepaidPakageK?.sessionQuantity -
      customerPrepaidPackageList[customerPrepaidPakage].checkinCount;
    const enabled =
      dateEnd.getTime() <= todayDate.getTime() ||
      customerPrepaidPackageList[customerPrepaidPakage].checkinCount >=
        customerPrepaidPakageK.sessionQuantity ||
      customerPrepaidPakageK?.state !== constants.CREDIT_STATE_ACTIVE;

    if (enabled & (subUuid !== customerPrepaidPakageK?.uuid)) continue;

    checkinCadence = lookUpCadenceType(
      customerPrepaidPakageK?.packagePlan?.checkinCadence
    );

    //Load credit,
    if (!subUuid) {
      listTypeChk.push({
        type: constants.TYPE_CHECKINLIST_PREPAID_PACKAGE,
        uuid: customerPrepaidPakageK?.uuid,
        name: customerPrepaidPakageK?.packagePlan?.description,
        planUuid: customerPrepaidPakageK?.packagePlan?.uuid,
        zone: 9,
        startDate: customerPrepaidPakageK?.startDate,
        enabled: !enabled,
        creditTypeName: null,
        checked: UNCHECKED,
        invoices: null,
        checkinCadence: checkinCadence,
        state: customerPrepaidPakageK?.state,
        remainingCheckins: remainingCheckins,
      });
    } else {
      if (subUuid === customerPrepaidPakageK.uuid) {
        listTypeChk.push({
          type: constants.TYPE_CHECKINLIST_PREPAID_PACKAGE,
          uuid: customerPrepaidPakageK?.uuid,
          name: customerPrepaidPakageK?.packagePlan?.description,
          planUuid: customerPrepaidPakageK?.packagePlan?.uuid,
          zone: 9,
          startDate: customerPrepaidPakageK?.startDate,
          enabled: !enabled,
          creditTypeName: null,
          checked: CHECKED,
          invoices: null,
          checkinCadence: checkinCadence,
          state: customerPrepaidPakageK?.state,
          remainingCheckins: remainingCheckins,
        });
      } else {
        listTypeChk.push({
          type: constants.TYPE_CHECKINLIST_PREPAID_PACKAGE,
          uuid: customerPrepaidPakageK?.uuid,
          name: customerPrepaidPakageK?.packagePlan?.description,
          planUuid: customerPrepaidPakageK?.packagePlan?.uuid,
          zone: 9,
          startDate: customerPrepaidPakageK?.startDate,
          creditTypeName: null,
          enabled: !enabled,
          checked: UNCHECKED,
          invoices: null,
          checkinCadence: checkinCadence,
          state: customerPrepaidPakageK?.state,
          remainingCheckins: remainingCheckins,
        });
      }
    }
  }

  for (k in sub) {
    //Check if the Checkin does not belong at subscription non enabled
    //In this case need not be displayed the subscription
    let subK = sub[k]?.subscription;
    const enabled = sub[k].checkinPossible;

    //Load subscription,
    if (!subUuid) {
      listTypeChk.push({
        type: constants.TYPE_CHECKINLIST_SUBSCRIPTION,
        uuid: subK?.uuid,
        name: subK?.plan?.name,
        planUuid: subK?.plan?.uuid,
        zone: subK?.zonesQuantity,
        startDate: subK?.startDate,
        creditTypeName: null,
        enabled: enabled,
        checked: UNCHECKED,
        invoices: sub[k]?.invoices,
        state: subK?.state,
        checkinPossible: sub[k]?.checkinPossible,
        checkinCadence: lookUpCadenceType(subK?.plan?.checkinCadence),
      });
    } else {
      if (subUuid === subK.uuid) {
        listTypeChk.push({
          type: constants.TYPE_CHECKINLIST_SUBSCRIPTION,
          uuid: subK?.uuid,
          name: subK?.plan?.name,
          planUuid: subK?.plan?.uuid,
          zone: subK?.zonesQuantity,
          startDate: subK?.startDate,
          creditTypeName: null,
          enabled: enabled,
          checked: CHECKED,
          invoices: sub[k]?.invoices,
          state: subK?.state,
          checkinPossible: sub[k]?.checkinPossible,
          checkinCadence: lookUpCadenceType(subK?.plan?.checkinCadence),
        });
      } else {
        listTypeChk.push({
          type: constants.TYPE_CHECKINLIST_SUBSCRIPTION,
          uuid: subK?.uuid,
          name: subK?.plan?.name,
          planUuid: subK?.plan?.uuid,
          zone: subK?.zonesQuantity,
          startDate: subK?.startDate,
          creditTypeName: null,
          enabled: enabled,
          checked: UNCHECKED,
          invoices: sub[k]?.invoices,
          state: subK?.state,
          checkinPossible: sub[k]?.checkinPossible,
          checkinCadence: lookUpCadenceType(subK?.plan?.checkinCadence),
        });
      }
    }
  }
  //Load and queued Checkinfundingsource
  for (k in cfs) {
    let cfsK = cfs[k];
    if (typeof subUuid != "undefined") {
      listTypeChk.push({
        type: constants.TYPE_CHECKINLIST_FOUNDINGSOURCE,
        uuid: cfsK?.uuid,
        name: cfsK?.name,
        planUuid: "",
        zone: 1,
        startDate: "",
        enabled: cfsK?.enabled,
        creditTypeName: null,
        checked: UNCHECKED,
        invoices: null,
      });
    } else {
      if (cfsUuid === cfsK?.uuid) {
        listTypeChk.push({
          type: constants.TYPE_CHECKINLIST_FOUNDINGSOURCE,
          uuid: cfsK?.uuid,
          name: cfsK?.name,
          planUuid: "",
          zone: 1,
          startDate: "",
          enabled: cfsK?.enabled,
          creditTypeName: null,
          checked: CHECKED,
          invoices: null,
        });
      } else {
        listTypeChk.push({
          type: constants.TYPE_CHECKINLIST_FOUNDINGSOURCE,
          uuid: cfsK?.uuid,
          name: cfsK?.name,
          planUuid: "",
          zone: 1,
          startDate: "",
          enabled: cfsK?.enabled,
          creditTypeName: null,
          checked: UNCHECKED,
          invoices: null,
        });
      }
    }
  }

  return listTypeChk;
}

function CustomerCheckInUpdatePage() {
  let { checkinUuid } = useParams();

  const [
    updateCheckInMutation,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_UPDATE_CHECKIN);

  const [
    deleteCheckInMutation,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useMutation(MUTATION_DELETE_CHECKIN);

  const ALL_CREDIT = "ALL";
  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);
  const { loading, error, data } = useQuery(QUERY_LOAD_CHECKIN_UPDATE, {
    variables: {
      checkinUuid: checkinUuid,
      listType: ALL_CREDIT,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error | deleteError) {
    return <ErrorMessage errors={[error]} />;
  }
  const currentLocation = data?.userSetting?.currentLocation;
  const isSameLocation =
    currentLocation?.uuid === data?.checkin?.location?.uuid ? true : false;
  const customerUuid = data?.checkin?.customer?.uuid;
  let typeListUuid;
  switch (data?.checkin?.checkinFundingSource?.uuid) {
    case constants.CHECKIN_FUNDING_CREDIT:
      typeListUuid = data?.checkin?.credit?.uuid;
      break;
    case constants.CHECKIN_FUNDING_PREPAID_PACKAGE:
      typeListUuid = data?.checkin?.prepaidPackage?.uuid;
      break;
    case constants.CHECKIN_FUNDING_SUBSCRIPTION:
      typeListUuid = data?.checkin?.subscription?.uuid;
      break;
    default:
      typeListUuid = data?.checkin?.subscription?.uuid;
  }

  const checkInTypeList = loadTypeCheckInUpdt(
    data?.allCheckinFundingSource,
    data?.checkinCustomerSubscriptions,
    data?.checkin?.checkinFundingSource?.uuid,
    typeListUuid,
    data?.customerCreditList,
    data?.customerPrepaidPackageList
  );

  const customerPlanProducts = data?.customerPlanProducts;
  // Build dictionary with initial state
  const CHECKED = 1;
  let initialState = [];

  initialState = checkInTypeList
    .filter((checkInTypeList) => checkInTypeList.checked === CHECKED)
    .map((checkInTypeList) => {
      return {
        radioUuid: checkInTypeList.uuid,
        radioType: checkInTypeList.type,
        planUuid: checkInTypeList.planUuid,
      };
    });

  let redirectDeleteCompleted = null;

  if (!!deleteData) {
    if (!deleteData?.deleteCheckin?.error) {
      redirectDeleteCompleted = (
        <Navigate
          to={{
            pathname: getCustomerUrl(customerUuid),
          }}
        />
      );
    } else {
      return (
        <ErrorMessage errors={[deleteData?.deleteCheckin?.error?.message]} />
      );
    }
  }

  let redirectContinueToList = null;
  if (!!shouldRedirectToList || !isSameLocation) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: getCustomerUrl(customerUuid),
        }}
      />
    );
  }

  return (
    <>
      <CustomerCheckInUpdateForm
        data={data}
        checkInTypeList={checkInTypeList}
        workers={data?.workersList}
        stations={data?.stationsList}
        productsForFundingSource={data?.productsForFundingSource}
        updateCheckin={updateCheckInMutation}
        deleteCheckin={deleteCheckInMutation}
        creating={mutationLoading}
        completed={!!mutationData}
        createError={mutationError}
        deleting={deleteLoading}
        modalContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
        customerPlanProducts={customerPlanProducts}
        initialState={initialState}
        currentLocation={currentLocation}
        productsPrice={data?.listProductsPriceForCheckin}
        checkinWorkerIsEnabled={data?.checkinWorkerIsEnabled}
        customerPrepaidPackageList={data?.customerPrepaidPackageList}
      />
      {redirectDeleteCompleted}
      {redirectContinueToList}
    </>
  );
}

export default CustomerCheckInUpdatePage;
