import Button from "react-bootstrap/Button";
import { ErrorMessage } from "./ErrorMessage";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

const CONFIRM_STRING = "Conferma";
const CANCEL_STRING = "Annulla";

const buttonSpinner = <Spinner animation="border" variant="light" />;

function ConfirmActionModal({
  title,
  message,
  onConfirm,
  onCancel,
  cancelText = CANCEL_STRING,
  confirmText = CONFIRM_STRING,
  loading = false,
  error = null,
  show = false,
}) {
  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal show={show}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          {cancelText}
        </Button>
        <ErrorMessage errors={[error]} />
        <Button variant="danger" onClick={handleConfirm}>
          {loading ? buttonSpinner : confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmActionModal;
