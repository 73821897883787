import { gql } from "@apollo/client";

export const MUTATION_CREATE_INVOICE = gql`
  mutation createInvoice(
    $invoiceCheckinUuid: UUID
    $invoiceDate: Date
    $invoiceNotes: String
    $invoiceInternalNotes: String
    $invoiceSendEmail: String
  ) {
    createInvoiceFromCheckin(
      invoiceCheckinUuid: $invoiceCheckinUuid
      invoiceDate: $invoiceDate
      invoiceNotes: $invoiceNotes
      invoiceInternalNotes: $invoiceInternalNotes
      invoiceSendEmail: $invoiceSendEmail
    ) {
      invoice {
        uuid
        progressiveNumber
        sector
      }
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_SAFE_CANCEL_INVOICE = gql`
  mutation safeCancelInvoice($guid: UUID) {
    safeCancelInvoice(guid: $guid) {
      result
      error {
        message
        code
      }
    }
  }
`;
export const MUTATION_UPDATE_RECEIPT = gql`
  mutation updateReceipt($receipt: ReceiptUpdateInput) {
    updateReceipt(receipt: $receipt) {
      receipt {
        uuid
        notes
        internalNotes
      }
      error {
        message
        code
      }
    }
  }
`;
export const MUTATION_UPDATE_INVOICE = gql`
  mutation updateInvoice(
    $invoiceUuid: UUID
    $invoiceNotes: String
    $invoiceInternalNotes: String
    $invoiceSendEmail: String
  ) {
    updateInvoice(
      invoiceUuid: $invoiceUuid
      invoiceNotes: $invoiceNotes
      invoiceInternalNotes: $invoiceInternalNotes
      invoiceSendEmail: $invoiceSendEmail
    ) {
      invoice {
        uuid
        notes
        internalNotes
      }
      error {
        message
        code
      }
    }
  }
`;
