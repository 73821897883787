import * as yup from "yup";

import React, { useState } from "react";
import {
  getCheckinDetailUrl,
  getCustomerUrl,
  getReceiptDetailUrl,
} from "../../api/urls";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ErrorMessage } from "../../components/ErrorMessage";
import Form from "react-bootstrap/Form";
import FormElement from "../../components/FormElement";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import addBsArrowRight from "../../components/AddBsArrowRight";
import dropDownHeaderView from "../../components/DropDownHeaderView";
import { isEmpty } from "../../api/utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  emailInvio: yup.string().email("Mail non valida"),
});

function ContinueModal({ onContinue, show = false }) {
  return (
    <Modal show={show} onHide={onContinue}>
      <Modal.Header closeButton>
        <Modal.Title>Emissione Ricevuta</Modal.Title>
      </Modal.Header>
      <Modal.Body>La Ricevuta è stata emessa correttamente</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onContinue}>
          Continua
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ConfirmDeleteModal({ onConfirm, onCancel, show }) {
  const notesDelete = (
    <p>
      Questa azione elimina in modo definitivo la ricevuta. E' possibile
      riemettere la nuova Ricevuta
    </p>
  );
  const notesRembursement = (
    <p>
      Questa azione emette un Rimborso per l'intero importo della ricevuta. Il
      Check-in da cui è stata emessa la ricevuta non è più valido.
    </p>
  );
  const notesDeleteRembursement = (
    <p>
      Questa azione annulla il Rimborso fatto in precedenza. Il Check-in da cui
      è stata emessa la ricevuta è di nuovo valido.
    </p>
  );
  let exitLabel;
  let notes;
  switch (show.title) {
    case "Annulla ricevuta":
      exitLabel = "Annullare";
      notes = notesDelete;
      break;
    case "Rimborsa ricevuta":
      exitLabel = "Rimborsare";
      notes = notesRembursement;
      break;
    case "Annulla rimborso ricevuta":
      exitLabel = "Annullare il rimborso";
      notes = notesDeleteRembursement;
      break;
    default:
  }

  return (
    <Modal
      show={show.show}
      onHide={onCancel}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{show.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{notes}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {`Esci senza ${exitLabel}`}
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          {show.title}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ReceiptUpdateForm({
  receiptData,
  receiptItem,
  creditNote,
  receiptCheckin,
  //
  updateReceipt,
  handleReceipt,
  creating,
  completed,
  createError,
  handlingCompleted = () => {},
  modalContinueClicked = () => {},
  currentLocation,
}) {
  const [showModalDelete, setShowModalDelete] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [showModalContinue, setShowModalContinue] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const checkin = receiptCheckin;
  // Form hooks
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      internalNotes: receiptData?.internalNotes,
    },
  });
  const isSameLocation =
    currentLocation?.uuid === receiptData?.location?.uuid ? true : false;

  const isLastReceipt = receiptData?.isLast;
  const isLastCreditNote = !!creditNote?.isLast ? creditNote?.isLast : false;

  const onSubmit = async (formData) => {
    const invoiceUuid = receiptData?.uuid;
    const internalNotes =
      formData?.internalNotes?.trim() === "" ? null : formData.internalNotes;
    const result = await updateReceipt({
      variables: {
        invoiceUuid: invoiceUuid,
        invoiceInternalNotes: internalNotes,
      },
    });

    if (!result?.errors) {
      setShowModalContinue(true);
    }
  };

  function handleChangeCheck() {
    setShowEmail(!showEmail);
  }

  const onDelete = async () => {
    const result = await handleReceipt({
      variables: {
        guid: receiptData.uuid,
      },
    });
    if (!!result.data) {
      handlingCompleted(result);
    }
  };

  const buttonSpinner = <Spinner animation="border" variant="light" />;
  const creatingSuccess = (
    <Alert variant="success"> Registrazione effettuata</Alert>
  );
  let btnUpdate = (
    <Form.Group as={Col} controlId="update">
      <Col>
        <Button
          variant={isEmpty(errors) ? "primary" : "danger"}
          type="submit"
          disabled={creating}
        >
          {creating ? buttonSpinner : "Aggiorna"}
        </Button>
      </Col>
    </Form.Group>
  );

  let fieldEmail = (
    <>
      <Form.Check
        type="checkbox"
        id="z1"
        label="Il cliente desidera la ricevuta?"
        onChange={(e) => {
          handleChangeCheck();
        }}
      ></Form.Check>
      <Col xs={12}>
        <Row>
          <Form.Group as={Col} controlId="sendEmail">
            <FormElement
              label="Email per invio documento fiscale"
              key={"z5"}
              name="sendEmail"
              register={register}
              errors={errors}
              controlOptions={{ type: "email" }}
            ></FormElement>
          </Form.Group>
        </Row>
      </Col>
    </>
  );

  let typeMutation = null;
  if (isLastReceipt) typeMutation = "Annulla ricevuta";
  else if (!!receiptData.creditNoteId === false)
    typeMutation = "Rimborsa ricevuta";
  else if (!!receiptData.creditNoteId && isLastCreditNote)
    typeMutation = "Annulla rimborso ricevuta";

  let btnDelete = (
    <Dropdown.Item
      onClick={() => {
        setShowModalDelete({
          show: true,
          title: typeMutation,
        });
      }}
      disabled={!isSameLocation}
    >
      <div
        style={{
          color: "red",
        }}
      >
        <span role="img" aria-label="danger">
          ⚠️
        </span>{" "}
        {typeMutation}
      </div>
    </Dropdown.Item>
  );

  btnDelete = !!typeMutation ? btnDelete : null;

  const creditNoteData = (
    <>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Numero Rimborso </h6>
          <span>{creditNote?.progressiveNumber} </span>
        </Col>
        <Col>
          <h6>Data rimborso </h6>
          <span>{creditNote?.date} </span>
        </Col>
        <Col>
          <h6>Data invio MEF </h6>
          <span>{creditNote?.sdiSentDate} </span>
        </Col>
        <Col></Col>
      </Row>
    </>
  );

  return (
    <>
      <Form
        onSubmit={(e) => {
          return handleSubmit(onSubmit)(e);
        }}
      >
        <Row>
          <Col xs={11}>
            <h4 className="text-center"> Modifica Ricevuta </h4>
          </Col>
          <Col xs={1}>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <ButtonGroup>
                {btnUpdate}
                <DropdownButton
                  menualign="right"
                  title="Azioni"
                  id="dropdown-menu-align-right"
                  variant="outline-secondary"
                >
                  <Dropdown.Item>{btnDelete}</Dropdown.Item>
                  <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                  <Dropdown.Item
                    href={getCustomerUrl(receiptData?.customer?.uuid)}
                  >
                    {addBsArrowRight("Cliente")}
                  </Dropdown.Item>
                  <Dropdown.Item href={getCheckinDetailUrl(checkin?.uuid)}>
                    {addBsArrowRight("CheckIn")}
                  </Dropdown.Item>
                  <Dropdown.Item href={getReceiptDetailUrl(receiptData?.uuid)}>
                    {addBsArrowRight("Ricevuta")}
                  </Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
        <h5> Cliente</h5>
        <Row>
          <Col>
            <h6> Nome: </h6>
            <span>{receiptData?.customer?.first} </span>
          </Col>
          <Col>
            <h6>Cognome</h6>
            <span>{receiptData?.customer?.last}</span>
          </Col>
          <Col>
            <h6>Mail</h6>
            <span>{receiptData?.customer?.email}</span>
          </Col>
          <Col>
            <h6>Codice Fiscale</h6>
            <span>{receiptData?.customer?.taxId}</span>
          </Col>
        </Row>
        <hr />
        <h5> Dati Pagamento</h5>

        <div className="mt-4" />
        <Row>
          <Col>
            <h6> Costo Unitario: </h6>
            <span>{receiptItem?.articlePrice}€ </span>
          </Col>
          <Col>
            <h6>N. Trattamenti</h6>
            <span>{Math.trunc(receiptItem?.quantity)}</span>
          </Col>
          <Col>
            <h6>Totale da pagare</h6>
            <span>{receiptData?.amount}€</span>
          </Col>
          <Col>
            <h6> Trattamento</h6>
            <span>{receiptItem?.articleDescription} </span>
          </Col>
        </Row>
        <div className="mt-4" />

        <Row>
          <Col>
            <h6>Numero ricevuta </h6>
            <span>{receiptData?.progressiveNumber} </span>
          </Col>
          <Col>
            <h6>Data ricevuta </h6>
            <span>{receiptData?.date} </span>
          </Col>
          <Col>
            <h6>Data invio MEF </h6>
            <span>{receiptData?.sdiSentDate} </span>
          </Col>
          <Col></Col>
        </Row>
        {!!creditNote ? creditNoteData : null}
        <hr />

        <Row>{showEmail ? fieldEmail : null}</Row>
        <div className="mt-4" />
        <Row></Row>
        <Row>
          <Form.Group as={Col} controlId="internalNotes">
            <FormElement
              label="Note interne (non riportate in ricevuta)"
              key={"z6"}
              name="internalNotes"
              register={register}
              errors={errors}
              controlOptions={{
                rows: "2",
                as: "textarea",
                placeholder: "",
              }}
            ></FormElement>
          </Form.Group>
        </Row>
        <Row>
          <ErrorMessage errors={[createError]} />
        </Row>
        <Row>{completed ? creatingSuccess : null}</Row>
      </Form>
      <ConfirmDeleteModal
        show={showModalDelete}
        onConfirm={onDelete}
        onCancel={() => {
          setShowModalDelete({
            show: false,
            title: "",
          });
        }}
      />
      <ContinueModal
        show={showModalContinue}
        onContinue={() => {
          setShowModalContinue(false);
          modalContinueClicked();
        }}
      />
    </>
  );
}

export default ReceiptUpdateForm;
