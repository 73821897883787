import React, { useEffect, useState } from "react";
import { MUTATION_UPDATE_PAYMENT_INVOICE } from "../api/mutations";
import constants from "../constants";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Alert from "react-bootstrap/Alert";

function SumUpCallBackPage() {
  const [updatePaymentInvoiceMutation] = useMutation(
    MUTATION_UPDATE_PAYMENT_INVOICE
  );
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showPaymentSuccessFalse, setShowPaymentSuccessFalse] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paymentSuccess = urlParams.get("payment_success");
    if (paymentSuccess === "true") {
      updatePaymentInvoiceMutation({
        variables: {
          paymentStatus: constants.PAYMENT_STATUS_TYPE_COMPLETED,
          paymentCode: urlParams.get("smp-tx-code"),
          checkinUuid: urlParams.get("checkInUuid"),
        },
      });

      setShowPaymentSuccess(true);
    }
    if (paymentSuccess === "false") {
      setShowPaymentSuccessFalse(true);
    }
  }, [updatePaymentInvoiceMutation, location]);

  const paymentSuccessTrue = showPaymentSuccess ? (
    <Alert variant="success">Pagamento effettuato con successo</Alert>
  ) : null;

  const paymentSuccessFalse = showPaymentSuccessFalse ? (
    <Alert variant="success">Il pagamento non è andato a buon fine</Alert>
  ) : null;

  return (
    <>
      {paymentSuccessTrue}
      {paymentSuccessFalse}
    </>
  );
}

export default SumUpCallBackPage;
