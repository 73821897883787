import * as yup from "yup";

import React, { useEffect, useState } from "react";
import {
  isEmpty,
  loadCheckinList,
  loadListProducts,
  sumUpUrlConstruction,
} from "../api/utils";
import {
  formatDateIso,
  formatLocalTime,
  formatTimeIso,
} from "../api/date-utils";
import {
  getCheckinDetailUrl,
  getCustomerUrl,
  getReceiptDetailUrl,
} from "../api/urls";

import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ErrorMessage } from "./ErrorMessage";
import Form from "react-bootstrap/Form";
import FormElement from "./FormElement";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import StateIcon from "../components/StateIcon";
import addBsArrowRight from "../components/AddBsArrowRight";
import constants from "../constants";
import dropDownHeaderView from "../components/DropDownHeaderView";
import styled from "@emotion/styled/macro";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Styles
const EmojiSpan = styled.span`
  font-size: 1.1 rem;
`;

const VALIDATION_MANDATORY_FIELD = "Campo obbligatorio";
const schema = yup.object().shape({
  date: yup.date().max(new Date(), "La data non può essere nel futuro"),
  time: yup.string().required(VALIDATION_MANDATORY_FIELD),
  product: yup.string(),
  productZones: yup
    .number()
    .positive("Il numero deve essere positivo")
    .typeError("Deve essere un numero"),
  mandatoryNotes: yup.number(),
  notes: yup.string().when("mandatoryNotes", (mandatoryNotes, schema) => {
    return schema.test({
      test: (notes) => !(notes === "" && mandatoryNotes === 1),
      message: "Nelle note occorre indicare il motivo",
    });
  }),
});

function load_Workers(workersList, workerData) {
  //Handles table elements disabled
  let listWorker = workersList.map((worker) => {
    return {
      uuid: worker?.uuid,
      last: worker?.last,
      first: worker?.first,
    };
  });

  const isWorkerUUID = !!workerData?.uuid;
  listWorker.push({
    uuid: isWorkerUUID ? workerData?.uuid : constants.DEFAULT_WORKER_UUID,
    last: isWorkerUUID ? workerData?.last : "",
    first: isWorkerUUID ? workerData?.first : "",
  });
  return listWorker;
}

function load_Products(productList, productData) {
  //Handles table elements disabled
  let listProduct = productList.map((product) => {
    return {
      uuid: product?.uuid,
      name: product?.name,
    };
  });

  const attualProduct = listProduct.find((p) => p.uuid === productData.uuid);

  if (!!attualProduct === false) {
    listProduct.push({
      uuid: productData?.uuid,
      name: productData?.name,
    });
  }
  return listProduct;
}

function load_Station(stationList, stationData) {
  //Handles table elements disabled
  let listStation = stationList.map((station) => {
    return {
      uuid: station?.uuid,
      name: station?.name,
    };
  });

  const isTableDataUUID = !!stationData?.uuid;
  listStation.push({
    uuid: isTableDataUUID ? stationData?.uuid : constants.DEFAULT_STATION_UUID,
    name: isTableDataUUID ? stationData?.name : "",
  });
  return listStation;
}

function findHasZones(mapProducts, formProduct, formProductZones) {
  //Check if the chosen treatment foresees the application in zones
  const product = !!mapProducts.find(
    (products) => products.uuid === formProduct && products.hasZones
  );
  return product ? formProductZones : 1;
}
function findNumZones(mapProducts, attualProduct) {
  //Check if the chosen treatment foresees the application in zones
  const numZones = !!mapProducts.find(
    (products) => products.uuid === attualProduct && products.hasZones
  );
  const constNum = numZones
    ? Array.from({ length: 15 }, (_, i) => i + 1)
    : Array.from({ length: 1 }, (_, i) => i + 1);

  return constNum;
}

function ConfirmDeleteModal({ onConfirm, onCancel, show = false }) {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Annulla Check-In</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Questa azione elimina in modo definitivo i dati del check-in
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Annulla operazione
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Annulla Check-In
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ContinueModal({ onContinue, show }) {
  let btnContinua = (
    <Button variant="primary" onClick={onContinue}>
      Continua
    </Button>
  );
  let body = (
    <Modal.Body>
      L'aggiornamento del Check-In è stato registrato correttamente.
    </Modal.Body>
  );
  if (!!show.checkinUuid && !!show?.invoiceUuid) {
    btnContinua = (
      <Button variant="primary" href={getReceiptDetailUrl(show.invoiceUuid)}>
        Visualizza Ricevuta
      </Button>
    );

    body = (
      <Modal.Body>
        <p>
          Il Check-In è stato registrato correttamente. <br></br>Il trattamento
          è a pagamento ed è stata emessa anche la ricevuta.
        </p>
      </Modal.Body>
    );
  }
  return (
    <Modal show={show.show} onHide={onContinue}>
      <Modal.Header closeButton>
        <Modal.Title>Aggiornamento Check-In</Modal.Title>
      </Modal.Header>
      {body}
      <Modal.Footer>{btnContinua}</Modal.Footer>
    </Modal>
  );
}

function CustomerCheckInUpdateForm({
  data,
  checkInTypeList,
  workers,
  stations,
  productsForFundingSource,
  updateCheckin,
  deleteCheckin,
  creating,
  completed,
  createError,
  deleteCompleted = () => {},
  modalContinueClicked = () => {},
  customerPlanProducts,
  initialState,
  currentLocation,
  productsPrice,
  checkinWorkerIsEnabled,
  customerPrepaidPackageList,
}) {
  const isDisabled = true;
  const checkindata = data?.checkin;
  const invoice = data?.getInvoiceForCheckin[0];

  let checkInUuid = null;
  let invoiceUuid = null;
  let constNum = Array.from({ length: 15 }, (_, i) => i + 1);
  const stateCheckIn =
    checkindata?.checkInStatus === constants.CHECK_IN_STATUS_TYPE_PENDING;
  const [showModalContinue, setShowModalContinue] = useState({
    show: false,
    checkinUuid: null,
    invoiceUuid: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [radioValue, setRadioValue] = useState([]);
  let [showAlertInvoice, setShowAlertInvoice] = useState(false);
  const [changeProduct, setChangeProduct] = useState();
  const [enableSubmitCheckin, setEnableSubmitCheckin] = useState(true);
  const [msgValue, setMsgValue] = useState(null);

  //Load select list products
  const mapProducts = loadListProducts(
    customerPlanProducts,
    productsForFundingSource,
    radioValue,
    customerPrepaidPackageList
  );

  useEffect(() => {
    setRadioValue({
      radioUuid: initialState[0].radioUuid,
      radioType: initialState[0].radioType,
      planUuid: initialState[0].planUuid,
    });
    setChangeProduct(checkindata?.product?.uuid);
  }, [initialState, checkindata]);

  //---------------------------------------
  const checkinList = loadCheckinList(checkInTypeList, radioValue.radioUuid);

  let showPrice =
    (checkinList[0].checkinFundingSource ===
      constants.CHECKIN_FUNDING_PAID_ACCESS) &
    checkindata?.isPresent
      ? true
      : false;

  const isSameLocation =
    currentLocation?.uuid === checkindata?.location?.uuid ? true : false;

  let checkinFundingSourceForControl = constants.NO_MANDATORY_NOTES;
  if (checkinList[0].checkinFundingSourceForControl)
    checkinFundingSourceForControl = constants.MANDATORY_NOTES;

  // Form hooks
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      date: checkindata?.date,
      time: formatLocalTime(checkindata?.time),
      subscription: checkindata?.subscription?.plan?.uuid,
      worker: !!checkindata?.worker?.uuid
        ? checkindata?.worker?.uuid
        : constants.DEFAULT_WORKER_UUID,
      station: !!checkindata?.station?.uuid
        ? checkindata?.station?.uuid
        : constants.DEFAULT_STATION_UUID,
      product: checkindata?.product?.uuid,
      productZones: checkindata?.productZones,
      mandatoryNotes: checkinFundingSourceForControl,
      notes: checkindata?.notes,
      present: checkindata?.isPresent,
    },
  });

  if (showPrice) calculateTotalPrice();

  let groupShowingPrice = (
    <>
      <Col>
        <FormElement
          label="Prezzo"
          name="price"
          register={register}
          errors={errors}
          controlOptions={{ disabled: isDisabled }}
        ></FormElement>
      </Col>
      <Col>
        <FormElement
          label="Costo Totale"
          name="totalPrice"
          register={register}
          errors={errors}
          controlOptions={{ disabled: isDisabled }}
        ></FormElement>
      </Col>
    </>
  );

  const onDelete = async () => {
    const result = await deleteCheckin({
      variables: {
        guid: checkindata.uuid,
      },
    });
    if (result.data) {
      deleteCompleted(result);
    }
  };

  const buttonSpinner = <Spinner animation="border" variant="light" />;
  const creatingSuccess = (
    <Alert variant="success"> Registrazione effettuata</Alert>
  );

  const onSubmit = async (formData) => {
    setMsgValue(null);
    const listCheckin = loadCheckinList(checkInTypeList, radioValue.radioUuid);
    const subscription = listCheckin[0].subscription;
    const checkinfundingsource = listCheckin[0].checkinFundingSource;
    const credit = listCheckin[0].credit;
    const prepaidPackage = listCheckin[0].prepaidPackage;
    const productZonesCheckin = !!formData.productZones
      ? formData.productZones
      : checkindata?.productZones;
    //Check if the chosen treatment foresees the application in zones
    const numZones = findHasZones(
      mapProducts,
      formData.product,
      productZonesCheckin
    );
    const present = !formData.present ? false : true;
    const dateCheckin = !!formData.date
      ? formatDateIso(formData.date)
      : checkindata?.date;
    const productCheckin = !!formData.product
      ? formData.product
      : checkindata?.product?.uuid;
    // Load the invoices table
    let invoices = [];
    let foundCheckInTypeList;
    let count = 0;
    if (!!subscription) {
      foundCheckInTypeList = checkInTypeList.find(
        (obj) => obj.uuid === subscription
      );
      if (foundCheckInTypeList.checkinCadence === constants.QUARTERLY) {
        for (const invoiceIndex in foundCheckInTypeList?.invoices) {
          if (count >= 3) {
            break;
          }
          invoices[invoiceIndex] =
            foundCheckInTypeList?.invoices[invoiceIndex]?.uuid.toString();
          count++;
        }
      } else invoices[0] = foundCheckInTypeList?.invoices[0]?.uuid.toString();

      const shouldExecute =
        foundCheckInTypeList?.checkinCadence === constants.QUARTERLY &&
        checkindata?.subscription?.uuid !== subscription &&
        count < 3;

      if (shouldExecute) {
        setMsgValue(
          <Alert variant="danger">
            {"Non ci sono abbastanza rate pagate per l'abbonamento"}
          </Alert>
        );
        return;
      }
    }
    const checkin = {
      date: dateCheckin,
      time: formatTimeIso(formData.time),
      subscription: subscription,
      product: productCheckin,
      worker: formData.worker,
      station: formData.station,
      productZones: numZones,
      notes: formData.notes,
      checkinfundingsource: checkinfundingsource,
      credit: credit,
      isPresent: present,
      invoices: invoices,
      prepaidPackage: prepaidPackage,
    };
    setEnableSubmitCheckin(false);
    setMsgValue(null);
    const result = await updateCheckin({
      variables: {
        checkin,
        guid: checkindata.uuid,
      },
    });
    if (!!result?.data?.updateCheckin?.error) {
      setMsgValue(
        <Alert variant="danger">
          {result?.data?.updateCheckin?.error?.message}
        </Alert>
      );
      return;
    } else {
      if (
        checkinfundingsource === constants.CHECKIN_FUNDING_PAID_ACCESS &&
        !!invoice === false
      ) {
        checkInUuid = result?.data?.updateCheckin?.result?.checkin?.uuid;
        invoiceUuid = result?.data?.updateCheckin?.result?.invoiceUuid;
      }

      if (!present) {
        setShowModalContinue({
          show: true,
          checkinUuid: null,
          invoiceUuid: null,
        });
        return;
      }

      if (showPrice) {
        const dictResult = calculateInvoicePrice();
        const title = !dictResult.hasZones
          ? `Cliente: ${checkindata?.customer.first} ${checkindata?.customer.last} - Trattamento: ${dictResult.productName}`
          : `Cliente: ${checkindata?.customer.first} ${checkindata?.customer.last} - Trattamento: ${dictResult.productName} - N. Zone: ${dictResult.zonesNum}`;
        const sumUpUrl = sumUpUrlConstruction(
          dictResult.priceTotal,
          checkindata?.customer.email,
          checkindata?.customer.phoneNumber,
          title,
          checkInUuid
        );
        window.location.href = sumUpUrl;
      } else {
        setShowAlertInvoice(false);
        setShowModalContinue({
          show: true,
          checkinUuid: checkInUuid,
          invoiceUuid: invoiceUuid,
        });
      }
    }
  };

  function handleChangeRadio(s) {
    setRadioValue({
      radioUuid: s.uuid,
      radioType: s.type,
      planUuid: s.planUuid,
    });
    //Removes any previous error reports
    clearErrors("notes");
    const checkinList = loadCheckinList(checkInTypeList, s.uuid);
    //Check if the notes are required or not
    if (checkinList[0].checkinFundingSourceForControl) {
      setValue("mandatoryNotes", constants.MANDATORY_NOTES);
    } else {
      setValue("mandatoryNotes", constants.NO_MANDATORY_NOTES);
    }
    const mapRadioVal = {
      radioUuid: s.uuid,
      radioType: s.type,
      planUuid: s.planUuid,
    };

    const productList = loadListProducts(
      customerPlanProducts,
      productsForFundingSource,
      mapRadioVal,
      customerPrepaidPackageList
    );

    const uuidProduct = getValues("product");
    const attualProduct = productList.find(
      (products) => products.uuid === uuidProduct
    );
    const newProductUuid = !!attualProduct
      ? attualProduct.uuid
      : productList[0].uuid;
    // Set product default
    setValue("product", newProductUuid);
    setChangeProduct(newProductUuid);
    constNum = findNumZones(productList, newProductUuid);

    showPrice =
      checkinList[0].checkinFundingSource ===
      constants.CHECKIN_FUNDING_PAID_ACCESS
        ? true
        : false;

    if (showPrice) calculateTotalPrice(newProductUuid);

    // Check if receipt exists
    setShowAlertInvoice(
      checkinList[0].checkinFundingSource ===
        constants.CHECKIN_FUNDING_PAID_ACCESS
        ? true
        : false && !!invoice === true
    );
  }

  let radioSub = null;
  if (checkInTypeList.length === 0) {
    radioSub = (
      <Alert key="nosub" variant="info">
        Non ci sono abbonamenti
      </Alert>
    );
  } else {
    radioSub = (
      <Form.Group controlId="typeCheckIns" disabled={isDisabled}>
        {checkInTypeList.map((s) => {
          let typeRadio;
          const status = (
            <StateIcon nameIcon={s?.state} objType="Icon"></StateIcon>
          );
          let disabled = false;
          let numRate = null;
          switch (s?.type) {
            case constants.TYPE_CHECKINLIST_SUBSCRIPTION:
              const numInvoices = !!s?.invoices ? s?.invoices.length : 0;
              let msgEmoj;
              switch (numInvoices) {
                case 0:
                  msgEmoj = "Non ci sono rate pagate per effettuare il CheckIn";
                  break;
                case 1:
                  if (s.checkinCadence === constants.QUARTERLY) {
                    msgEmoj =
                      "Non ci sono abbastanza rata pagate per effettuare il CheckIn";
                  } else {
                    msgEmoj = "C'è una rata pagata per effettuare il CheckIn";
                  }
                  break;
                case 2:
                  if (s.checkinCadence === constants.QUARTERLY) {
                    msgEmoj =
                      "Non ci sono abbastanza rata pagate per effettuare il CheckIn";
                  } else {
                    msgEmoj =
                      "Ci sono due rata pagate per effettuare il CheckIn";
                  }
                  break;
                default:
                  msgEmoj = `Ci sono ${numInvoices} rata pagate per effettuare CheckIn`;
              }
              numRate = <Badge variant="secondary">{numInvoices}</Badge>;
              typeRadio = (
                <EmojiSpan title={msgEmoj}>
                  <span>
                    {numRate} {status} {s?.startDate} {s.name}
                  </span>
                </EmojiSpan>
              );
              //disabled = !s.checkinPossible;
              break;
            case constants.TYPE_CHECKINLIST_CREDIT:
              numRate = (
                <Badge variant="secondary">{s.remainingCheckins}</Badge>
              );
              typeRadio = (
                <span>
                  {numRate} {status} {s?.startDate} Credito: {s?.startDate}{" "}
                  {s?.creditTypeName} - {s?.name}
                </span>
              );
              disabled = !s.enabled;
              break;
            case constants.TYPE_CHECKINLIST_PREPAID_PACKAGE:
              numRate = (
                <Badge variant="secondary">{s.remainingCheckins}</Badge>
              );

              typeRadio = (
                <span>
                  {numRate} {status} {s?.startDate} - ${s?.name}
                </span>
              );
              disabled = !s.enabled;
              break;
            case constants.TYPE_CHECKINLIST_FOUNDINGSOURCE:
              typeRadio = s?.enabled
                ? ` ${s?.name}`
                : ` ${s?.name} - Disabilitato`;
              break;
            default:
          }
          // Check if receipt exists
          //----------------------------------------------------------
          //If the CheckIn is a non-present or consultative one it does
          //not have the execution time, so it cannot be changed
          //to another type of checkIn
          //----------------------------------------------------------
          // Check if is present
          if (checkindata?.isPresent) {
            if (
              checkindata.checkinFundingSource.uuid ===
              constants.CHECKIN_FUNDING_PAID_ACCESS
            ) {
              // Check if receipt exists
              disabled = !!invoice ? true : !s.enabled;
            }
            // Check if consultant
            if (
              checkindata.checkinFundingSource.uuid ===
              constants.CHECKIN_FUNDING_CONSULTANT
            ) {
              disabled = true;
            }
          } else disabled = true;

          return (
            <Form.Check
              id={s.uuid}
              type="radio"
              key={s.uuid}
              value={s.uuid}
              label={typeRadio}
              // style={{ backgroundColor: "yellow", borderColor: "green" }}
              style={{ borderColor: "green" }}
              {...register("typeCheckIns")}
              defaultChecked={s.checked === 1}
              disabled={disabled}
              onChange={(e) => {
                handleChangeRadio(s);
              }}
            ></Form.Check>
          );
        })}
      </Form.Group>
    );
  }

  let btnUpdate = (
    <Form.Group as={Col} controlId="update">
      <Col>
        <Button
          variant={isEmpty(errors) ? "primary" : "danger"}
          type="submit"
          disabled={creating || !enableSubmitCheckin || stateCheckIn}
        >
          {creating ? buttonSpinner : "Aggiorna"}
        </Button>
      </Col>
    </Form.Group>
  );

  let btnDelete = (
    <Dropdown.Item
      onClick={() => {
        setShowModal(true);
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: "130%",
          color: "red",
        }}
      >
        <span role="img" aria-label="danger">
          ⚠️
        </span>{" "}
        Annulla
      </div>
    </Dropdown.Item>
  );

  const showReceipt =
    (checkinList[0]?.checkinFundingSource ===
      constants.CHECKIN_FUNDING_PAID_ACCESS) &
    !!data?.getInvoiceForCheckin[0] ? (
      <Dropdown.Item
        href={getReceiptDetailUrl(data?.getInvoiceForCheckin[0]?.uuid)}
      >
        {addBsArrowRight("Ricevuta")}
      </Dropdown.Item>
    ) : null;

  const disableForPaidAccess =
    (checkinList[0].checkinFundingSource ===
      constants.CHECKIN_FUNDING_PAID_ACCESS) &
    !!invoice;

  btnDelete = disableForPaidAccess ? null : btnDelete;

  let workerList, stationList, productList;

  if (isSameLocation) {
    workerList = !checkinWorkerIsEnabled
      ? load_Workers(workers, checkindata?.worker)
      : workers;
    stationList = !checkindata?.station?.enabled
      ? load_Station(stations, checkindata?.station)
      : stations;
  } else {
    workerList = load_Workers(workers, checkindata?.worker);
    stationList = load_Station(stations, checkindata?.station);
  }

  productList =
    (!checkindata?.product?.enabled || customerPlanProducts.length === 0) &&
    initialState[0].radioUuid === radioValue.radioUuid
      ? load_Products(mapProducts, checkindata?.product)
      : mapProducts;

  // check if the Check-In has been loaded as overdue
  const disabledWorker =
    checkindata?.worker?.uuid === constants.DEFAULT_WORKER_UUID ||
    !!checkindata?.worker?.uuid === false ||
    !isSameLocation
      ? true
      : false;

  const disabledStation =
    checkindata?.station?.uuid === constants.DEFAULT_STATION_UUID ||
    !!checkindata?.station?.uuid === false ||
    !isSameLocation
      ? true
      : false;

  if (disabledWorker || disabledStation) {
    btnUpdate = <Col></Col>;
    btnDelete = <Col></Col>;
  }

  const alertIssueInvoice = (
    <Alert variant="info">
      <p>
        E' un Accesso a Pagamento.{" "}
        <font size="+2">
          <b>La registrazione deve essere effettuata da Tablet.</b>
        </font>
        <br></br>
        Con l'aggiornamento del Check-In verrà emessa contestualmente la
        ricevuta di pagamento.
      </p>
    </Alert>
  );

  //A "present" Check-In can only be cancelled.
  //Modification made after the division of the Check-In into "Open" and "Closed"
  const isPresent = (
    <Form.Group as={Col} xs={3} controlId="present">
      <FormElement
        label="Presente"
        name="present"
        register={register}
        errors={errors}
        controlOptions={{ as: "select", disabled: true }}
      >
        <option key="1" value="true">
          Si
        </option>
        <option key="0" value="false">
          No
        </option>
      </FormElement>
    </Form.Group>
  );

  function calculateTotalPrice(productUuid = null, numZones = null) {
    const zonesNum = !!numZones ? numZones : getValues("productZones");
    const uuidProduct = !!productUuid ? productUuid : getValues("product");
    const hasZones = !!mapProducts.find(
      (products) => products.uuid === uuidProduct && products.hasZones
    );
    let numMax = zonesNum;
    if (!hasZones) {
      numMax = 1;
      setValue("productZones", 1);
    }
    if (showPrice) {
      const attualPrice = productsPrice.find(
        (priceForProduct) => priceForProduct.product.uuid === uuidProduct
      );

      if (!!attualPrice) {
        const price = (attualPrice.price * 1).toFixed(2);
        setValue("price", price);
        const priceTotal = (price * numMax).toFixed(2);
        setValue("totalPrice", priceTotal);
      }
    }
  }
  function onChangeProduct(event) {
    setValue("productZones", 1);
    setChangeProduct(event.target.value);
    calculateTotalPrice(event.target.value);
    constNum = findNumZones(mapProducts, changeProduct);
  }

  function onChangeProductZones(event) {
    calculateTotalPrice(null, event.target.value);
  }

  function calculateInvoicePrice(productUuid = null, numZones = null) {
    let priceTotal = 0;
    const zonesNum = !!numZones ? numZones : getValues("productZones");
    const uuidProduct = !!productUuid ? productUuid : getValues("product");
    const hasZones = !!mapProducts.find(
      (products) => products.uuid === uuidProduct && products.hasZones
    );
    const productName = mapProducts.find(
      (products) => products.uuid === uuidProduct
    );
    let numMax = zonesNum;
    if (!hasZones) {
      numMax = 1;
    }
    if (showPrice) {
      const attualPrice = productsPrice.find(
        (priceForProduct) => priceForProduct.product.uuid === uuidProduct
      );

      if (!!attualPrice) {
        const price = (attualPrice.price * 1).toFixed(2);
        priceTotal = (price * numMax).toFixed(2);
      }
    }
    const dictResult = {
      hasZones: hasZones,
      zonesNum: numMax,
      productName: productName.name,
      priceTotal: priceTotal,
    };
    return dictResult;
  }

  const alertCheckinIsOpen = stateCheckIn ? (
    <Alert variant="danger">
      <p>Non si può modificare un Check-In aperto.</p>
    </Alert>
  ) : null;

  return (
    <>
      <Form
        onSubmit={(e) => {
          return handleSubmit(onSubmit)(e);
        }}
      >
        <Row>
          <Col xs={9}>
            <h4 className="text-center">
              Modifica Check-In {stateCheckIn ? `  (Aperto)` : null}
            </h4>
          </Col>
          <Col xs={3}>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <ButtonGroup>
                {btnUpdate}
                <DropdownButton
                  menualign="right"
                  title="Azioni"
                  id="dropdown-menu-align-right"
                  variant="outline-secondary"
                >
                  {btnDelete}
                  <Dropdown.Divider />
                  <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                  <Dropdown.Item
                    href={getCustomerUrl(checkindata?.customer?.uuid)}
                  >
                    {addBsArrowRight("Cliente")}
                  </Dropdown.Item>
                  <Dropdown.Item href={getCheckinDetailUrl(checkindata?.uuid)}>
                    {addBsArrowRight("Check-In")}
                  </Dropdown.Item>
                  {showReceipt}
                </DropdownButton>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6>Nome: </h6>
            <span>{checkindata.customer.first} </span>
          </Col>
          <Col>
            <h6>Cognome</h6>
            <span>{checkindata.customer.last}</span>
          </Col>
          <Col>
            <h6>Mail</h6>
            <span>{checkindata.customer.email}</span>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={6}>
            <Form.Label>Tipo Check-In:</Form.Label>
            {radioSub}
          </Col>
        </Row>
        <hr />
        <Row>
          <Form.Group as={Col} xs={3} controlId="date">
            <FormElement
              label="Data Check-In"
              type="date"
              name="date"
              register={register}
              errors={errors}
              controlOptions={{ disabled: disableForPaidAccess }}
            ></FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={3} controlId="time">
            <FormElement
              label="Ora Check-In"
              type="time"
              name="time"
              controlOptions={{
                pattern: "[0-9]{2}:[0-9]{2}",
              }}
              register={register}
              errors={errors}
            ></FormElement>
          </Form.Group>
          {!showPrice ? isPresent : null}
          <Col>
            <Form.Group xs={6} controlId="currentLocation">
              <Form.Label> Sede </Form.Label>
              <Row>
                <Col>
                  <Form.Label> {checkindata?.location?.name} </Form.Label>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col} xs={6} controlId="worker">
            <FormElement
              label="Estetista"
              name="worker"
              register={register}
              errors={errors}
              controlOptions={{ as: "select", disabled: disabledWorker }}
            >
              {workerList.map((w) => (
                <option key={w.uuid} value={w.uuid}>
                  {`${w.last} ${w.first}`}
                </option>
              ))}
            </FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={6} controlId="station">
            <FormElement
              label="Box"
              name="station"
              register={register}
              errors={errors}
              controlOptions={{ as: "select", disabled: disabledStation }}
            >
              {stationList.map((s) => (
                <option key={s.uuid} value={s.uuid}>
                  {s.name}
                </option>
              ))}
            </FormElement>
          </Form.Group>
        </Row>
        <Row md={4}>
          <Form.Group as={Col} xs={6} controlId="product">
            <FormElement
              label="Trattamenti"
              name="product"
              register={register}
              errors={errors}
              controlOptions={{
                as: "select",
                disabled: disableForPaidAccess,
                onChange: (e) => {
                  return onChangeProduct(e);
                },
              }}
            >
              {productList.map((p) => (
                <option key={p.uuid} value={p.uuid}>
                  {p.name}
                </option>
              ))}
            </FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={2} controlId="productZones">
            <FormElement
              label="Numero Zone"
              type="number"
              name="productZones"
              register={register}
              errors={errors}
              controlOptions={{
                as: "select",
                disabled: disableForPaidAccess,
                onChange: (e) => {
                  return onChangeProductZones(e);
                },
              }}
            >
              {constNum.map((p) => (
                <option key={`pz${p}`} value={p}>
                  {p}
                </option>
              ))}
            </FormElement>
          </Form.Group>
          {showPrice ? groupShowingPrice : null}
        </Row>
        <Row>
          <Form.Group as={Col} controlId="notes">
            <FormElement
              label="Note"
              name="notes"
              register={register}
              errors={errors}
              controlOptions={{ rows: "2", as: "textarea" }}
            ></FormElement>
          </Form.Group>
        </Row>
        <Row>
          <ErrorMessage errors={[createError]} />
        </Row>
        <Row>{showAlertInvoice ? alertIssueInvoice : null}</Row>
        <Row>{stateCheckIn ? alertCheckinIsOpen : null}</Row>
        <Row>{completed && msgValue === "" ? creatingSuccess : null}</Row>
        <Row>{msgValue !== "" ? msgValue : null}</Row>
        <Row>
          <Col>
            <Form.Control
              type="number"
              name="mandatoryNotes"
              {...register("mandatoryNotes")}
              hidden
            ></Form.Control>
          </Col>
        </Row>
      </Form>
      <ConfirmDeleteModal
        show={showModal}
        onConfirm={onDelete}
        onCancel={() => {
          setShowModal(false);
        }}
      />
      <ContinueModal
        show={showModalContinue}
        onContinue={() => {
          setShowModalContinue({ show: false, checkinUuid: checkInUuid });
          modalContinueClicked();
        }}
      />
    </>
  );
}

export default CustomerCheckInUpdateForm;
