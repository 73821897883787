//import constants from "./constants.js";
//const LOGIN_URL = `${constants.API_ROOT}/auth/token/obtain/`;
import { jwtDecode } from "jwt-decode";
const LOCAL_STORAGE_AUTH_KEY = "auth";

class Auth {
  constructor() {
    this.authData = null;
  }
  isAuthenticated = () => {
    // Checks if authData is in memory
    if (!this.authData) {
      let storedAuth = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
      // If not, tries ot load it from Local storage
      if (storedAuth) {
        this.authData = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_AUTH_KEY)
        );
      }
    }
    if (!!this.authData?.isAuth) {
      return true;
    }
    return false;
  };

  setAccessToken = (accessToken) => {
    const token = jwtDecode(accessToken);
    this.authData = {
      username: token.username,
      isAuth: true,
    };

    // Saves data
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(this.authData));
  };
  getUsername = () => {
    const username = this?.authData?.username;
    if (!username) {
      return username;
    }
    return null;
  };

  logout() {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
    this.authData = null;
  }
}
const auth_var = new Auth();
export default auth_var;
