import { ErrorMessage } from "./ErrorMessage";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import addBsArrowRight from "../components/AddBsArrowRight";

function generateAgreementLink(agreement, agreementUrl) {
  // Mapping
  const params = {
    nome: agreement.first,
    cognome: agreement.last,
    codice_fiscale: agreement.taxId,
    uuid: agreement.customerUuid,
    email: agreement.email,
  };

  const url = new URL(agreementUrl);
  for (const [key, value] of Object.entries(params)) {
    if (value !== null) url.searchParams.append(key, value);
  }

  return url.toString();
}

function AgreementLink({ agreement }) {
  if (!agreement?.privacyDocumentUrl) {
    const ERROR_NO_TYPE_URL = "Manca il documento per il consenso privacy";
    return <ErrorMessage errors={[ERROR_NO_TYPE_URL]} />;
  }
  if (!agreement?.laserDocumentUrl) {
    const ERROR_NO_TYPE_URL =
      "Manca il documento per il consenso al trattamento laser";
    return <ErrorMessage errors={[ERROR_NO_TYPE_URL]} />;
  }
  const privacyUrl = generateAgreementLink(
    agreement,
    agreement.privacyDocumentUrl
  );
  const laserUrl = generateAgreementLink(agreement, agreement.laserDocumentUrl);

  const isSignedDocumentPrivacy = !!agreement.privacy ? false : true;
  const isSignedDocumentLaser = !!agreement.laser ? false : true;
  const agrLink = (
    <>
      <Dropdown.Item href={privacyUrl} disabled={!isSignedDocumentPrivacy}>
        {addBsArrowRight("Consenso Privacy")}
      </Dropdown.Item>
      <Dropdown.Item href={laserUrl} disabled={!isSignedDocumentLaser}>
        {addBsArrowRight("Consenso Trattamento Laser")}
      </Dropdown.Item>
    </>
  );

  return agrLink;
}

export { AgreementLink, generateAgreementLink };
