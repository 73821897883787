import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import Button from "react-bootstrap/Button";
import { CreateStripePaymentComponent } from "./CreateStripePaymentModal";
import { ErrorMessage } from "../ErrorMessage";
import Form from "react-bootstrap/Form";
import { MUTATION_UPDATE_STRIPE_PAYMENT_METHOD } from "../../api/mutations";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { QUERY_STRIPE_PAYMENT_METHODS_FOR_CUSTOMER } from "../../api/queries";
import Spinner from "react-bootstrap/Spinner";
import StripeElementsWrapper from "../StripeElementsWrapper";

const NEW_CARD = "new";

function isWithin24Hours(input_date) {
  const now = new Date();
  const created = new Date(input_date);
  const diff = now - created;
  const oneDay = 24 * 60 * 60 * 1000;

  return diff < oneDay;
}

function UpdateStripePaymentModal({
  customer,
  show,
  onHide,
  subscription,
  stripeSubscription,
}) {
  const [selected, setSelected] = useState(
    stripeSubscription?.paymentMethod?.id
  );
  const [updatePaymentError, setUpdatePaymentError] = useState(null);

  const {
    loading,
    error: loadingPaymentMethodsError,
    data,
    refetch,
  } = useQuery(QUERY_STRIPE_PAYMENT_METHODS_FOR_CUSTOMER, {
    variables: {
      customerUuid: customer.uuid,
    },
  });
  const [
    updatePaymentMethod,
    { loading: mutationLoading, error: updatePaymentMethodMutationError },
  ] = useMutation(MUTATION_UPDATE_STRIPE_PAYMENT_METHOD);
  const showCreateContols = selected === NEW_CARD;

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelected(selectedValue);
  };

  const cardsList = data?.stripePaymentMethodsForCustomer ?? [];
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      //const existingPaymentMethodId = stripeSubscription?.paymentMethod?.id;
      const newPaymentMethodId = selected;
      //if (existingPaymentMethodId !== newPaymentMethodId) {
      let returnValue = await updatePaymentMethod({
        variables: {
          subscriptionUuid: subscription?.uuid,
          stripePaymentMethodId: newPaymentMethodId,
        },
      });
      let error =
        returnValue?.data?.updateStripePaymentMethodOnSubscription?.error ||
        returnValue?.errors;

      if (error) {
        throw error;
      }
      //}
      onHide();
    } catch (e) {
      setUpdatePaymentError(e);
    }
  };

  const createCardControls = showCreateContols ? (
    <StripeElementsWrapper>
      <CreateStripePaymentComponent
        onCreated={async (customer, setupIntent) => {
          console.log("Created!");
          await refetch();
          const paymentMethod = setupIntent?.payment_method;
          setSelected(paymentMethod);
        }}
        customer={customer}
      />
    </StripeElementsWrapper>
  ) : null;

  const cardListComponents = cardsList.map((card, index) => (
    <div key={index + 1}>
      <Form.Check
        type="radio"
        label={`•••• ${card.paymentVisibleIdentifier} ${
          isWithin24Hours(card.created) ? "🆕" : ""
        }`}
        value={`${card.id}`}
        checked={selected === card.id}
        onChange={handleChange}
      ></Form.Check>
    </div>
  ));

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Cambio Carta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <div>
            {cardListComponents}
            <div key={0}>
              <Form.Check
                type="radio"
                value={NEW_CARD}
                label="Nuova Carta"
                checked={selected === NEW_CARD}
                onChange={handleChange}
              ></Form.Check>
            </div>
          </div>
          <div>
            {!showCreateContols ? (
              <Button type="submit" disabled={loading || mutationLoading}>
                {loading || mutationLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Salva"
                )}
              </Button>
            ) : null}
          </div>
          <ErrorMessage
            errors={[
              loadingPaymentMethodsError,
              updatePaymentError,
              updatePaymentMethodMutationError,
            ]}
          />
        </Form>
        <div>{createCardControls}</div>
      </Modal.Body>
    </Modal>
  );
}

UpdateStripePaymentModal.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default UpdateStripePaymentModal;
