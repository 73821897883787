import { Navigate, useLocation } from "react-router-dom";

import React from "react";
import auth from "../api/auth";

const LOGIN_PATH = "/login";

function PrivateRoute({ children }) {
  const location = useLocation();
  const isAuthenticated = auth.isAuthenticated();

  if (!isAuthenticated) {
    const params = new URLSearchParams({ redirect_to: location.pathname });
    return (
      <Navigate
        to={`${LOGIN_PATH}?${params}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

export default PrivateRoute;
