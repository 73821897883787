// store.js
import React, { createContext, useReducer } from "react";

import auth from "./api/auth";
import constants from "./constants";
import newSubscriptionReducer from "./reducers/newSubscription";

import authReducer from "./reducers/auth";

const initialState = {
  auth: {
    loggedIn: auth.isAuthenticated(),
  },
  newSubscription: {
    invoicingCustomer: null,
    receivingCustomer: null,
    paymentProvider: { paymentType: constants.paymentProviders.STRIPE },
    stripePaymentMethod: null,
    unpaid: false,
    uuid: null,
    latestInvoice: null,
    scheduledDateEnabled: false,
    scheduledDate: null,
  },
};
const store = createContext(initialState);
const { Provider } = store;

/*
 Custom redux-like combineReducer 
  https://blog.jakoblind.no/code-your-own-combinereducers/
*/
function combineReducers(reducers) {
  // First get an array with all the keys of the reducers (the reducer names)
  const reducerKeys = Object.keys(reducers);

  return function combination(state = {}, action) {
    // This is the object we are going to return.
    const nextState = {};

    // Loop through all the reducer keys
    for (let i = 0; i < reducerKeys.length; i++) {
      // Get the current key name
      const key = reducerKeys[i];
      // Get the current reducer
      const reducer = reducers[key];
      // Get the the previous state
      const previousStateForKey = state[key];
      // Get the next state by running the reducer
      const nextStateForKey = reducer(previousStateForKey, action);
      // Update the new state for the current reducer
      nextState[key] = nextStateForKey;
    }
    return nextState;
  };
}

// Reducers

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    combineReducers({
      newSubscription: newSubscriptionReducer,
      auth: authReducer,
    }),
    initialState
  );

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
