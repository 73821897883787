import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import MainTable from "./MainTable";
import { getSalesLedgerReportUrl } from "../api/urls";
import {
  getItalianMonthDescription,
  firstDayNextMonth,
  getLastDayOfMonth,
  formatDate,
  firstDayPreviousMonth,
} from "../api/date-utils";

function calculateAmounts(amount, vatRate) {
  const vatAmount = +((Number(amount) * Number(vatRate)) / 100).toFixed(2);
  const netAmount = +(Number(amount) - Number(vatAmount)).toFixed(2);
  return { vatAmount, netAmount };
}

function sumAmounts(gross, net, vat) {
  const amountSum = (+Number(gross) - (+Number(net) + +Number(vat))).toFixed(2);
  return amountSum;
}

const SalesLedgerTotalMonthsList = ({ columns, data, dailyReportTotal }) => {
  // Destructuring of variables to avoid repeating dailyReportTotal
  const {
    year,
    month,
    grossAmount,
    dailyTotalAmountReceipts,
    dailyTotalAmountRefunds,
    dailyTotalAmountInvoices,
    netAmount,
    vatRate,
    day,
    vat,
  } = dailyReportTotal;

  const lastDateOfMonth = getLastDayOfMonth(new Date(year, month - 1, 1));
  const firstDayMonth = `1-${month}`;
  const monthDescription = getItalianMonthDescription(month);

  const { vatAmount: vatGrossAmount, netAmount: netGrossAmount } =
    calculateAmounts(grossAmount, vatRate);

  const {
    vatAmount: vatGrossAmountReceipts,
    netAmount: netGrossAmountReceipts,
  } = calculateAmounts(dailyTotalAmountReceipts, vatRate);

  const { vatAmount: vatGrossAmountRefunds, netAmount: netGrossAmountRefunds } =
    calculateAmounts(dailyTotalAmountRefunds, vatRate);

  const {
    vatAmount: vatGrossAmountInvoices,
    netAmount: netGrossAmountInvoices,
  } = calculateAmounts(dailyTotalAmountInvoices, vatRate);

  // Orizzontal check
  const checkRowGrossAmount = +(
    grossAmount -
    (dailyTotalAmountReceipts -
      dailyTotalAmountRefunds -
      dailyTotalAmountInvoices)
  ).toFixed(2);

  const checkRowNetAmount = +(
    netGrossAmount -
    (netGrossAmountReceipts - netGrossAmountRefunds - netGrossAmountInvoices)
  ).toFixed(2);
  const checkRowVatAmount = +(
    vatGrossAmount -
    (vatGrossAmountReceipts - vatGrossAmountRefunds - vatGrossAmountInvoices)
  ).toFixed(2);

  const enabledCheckRow = [
    checkRowGrossAmount,
    checkRowNetAmount,
    checkRowVatAmount,
  ].some((value) => Math.abs(value) >= 0.1);

  // Vertical  check
  const ckColGrossAmount = sumAmounts(grossAmount, netAmount, vat);

  const ckColNetAmount = sumAmounts(
    dailyTotalAmountReceipts,
    netGrossAmountReceipts,
    vatGrossAmountReceipts
  );

  const ckColNetRefunds = sumAmounts(
    dailyTotalAmountRefunds,
    netGrossAmountRefunds,
    vatGrossAmountRefunds
  );

  const ckColNetInvoices = sumAmounts(
    dailyTotalAmountInvoices,
    netGrossAmountInvoices,
    vatGrossAmountInvoices
  );

  const enabledCheckCol = [
    ckColGrossAmount,
    ckColNetAmount,
    ckColNetRefunds,
    ckColNetInvoices,
  ].some((value) => Math.abs(value) >= 0.1);

  const renderCheckCell = (enabled, value) =>
    enabled ? <td>{value}</td> : <td className="d-none"></td>;

  const nextMonth = firstDayNextMonth(formatDate(`${year}-${month}-${day}`));
  const previousMonth = firstDayPreviousMonth(
    formatDate(`${year}-${month}-${day}`)
  );

  const nextPage = nextMonth && (
    <Button size="sm" variant="Link" href={getSalesLedgerReportUrl(nextMonth)}>
      Mese Successivo {">"}
    </Button>
  );

  const previousPage = (
    <Button
      size="sm"
      variant="Link"
      href={getSalesLedgerReportUrl(previousMonth)}
    >
      {"<"} Mese Precedente
    </Button>
  );

  return (
    <>
      <Row>
        <Col xs={2}>
          <div style={{ textAlign: "left" }}>{previousPage}</div>
        </Col>
        <Col xs={8}>
          <h2 className="text-center">Registro dei Corrispettivi</h2>
          <h4 className="text-center">
            Anno: <strong>{year}</strong> Mese:{" "}
            <strong>{monthDescription}</strong>
          </h4>
        </Col>
        <Col xs={2}>
          <div style={{ textAlign: "right" }}>{nextPage}</div>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>{MainTable(columns, data)}</Row>

      <h5>
        Totali del Periodo: {firstDayMonth} / {lastDateOfMonth}
      </h5>
      <Table bordered hover responsive>
        <thead>
          <tr>
            <th></th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              Corrispettivi
              <br />
              <small>Aliquota 22 %</small>
            </th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              E-Commerce Ricevute
              <br />
              <small>Aliquota 22 %</small>
            </th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              E-Commerce Resi
              <br />
              <small>Aliquota 22 %</small>
            </th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              E-Commerce Fatture
              <br />
              <small>Aliquota 22 %</small>
            </th>
            {enabledCheckRow && <th>Check</th>}
          </tr>
        </thead>
        <tbody>
          <tr className="table-active">
            <td>LORDO</td>
            <td>
              <div style={{ textAlign: "right", fontWeight: "bold" }}>
                {(+grossAmount).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td>
              <div style={{ textAlign: "right" }}>
                {(+dailyTotalAmountReceipts).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className="text-danger">
              <div style={{ textAlign: "right" }}>
                -
                {(+dailyTotalAmountRefunds).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className="text-danger">
              <div style={{ textAlign: "right" }}>
                -
                {(+dailyTotalAmountInvoices).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            {renderCheckCell(enabledCheckRow, checkRowGrossAmount)}
          </tr>
          <tr>
            <td>NETTO</td>
            <td>
              <div style={{ textAlign: "right", fontWeight: "bold" }}>
                {(+netGrossAmount).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            </td>
            <td>
              <div style={{ textAlign: "right" }}>
                {(+netGrossAmountReceipts).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className="text-danger">
              <div style={{ textAlign: "right" }}>
                -
                {(+netGrossAmountRefunds).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className="text-danger">
              <div style={{ textAlign: "right" }}>
                -
                {(+netGrossAmountInvoices).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            {renderCheckCell(enabledCheckRow, checkRowNetAmount)}
          </tr>

          <tr>
            <td>IVA</td>
            <td>
              <div style={{ textAlign: "right", fontWeight: "bold" }}>
                {(+vatGrossAmount).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td>
              <div style={{ textAlign: "right" }}>
                {(+vatGrossAmountReceipts).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className="text-danger">
              <div style={{ textAlign: "right" }}>
                -
                {(+vatGrossAmountRefunds).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className="text-danger">
              <div style={{ textAlign: "right" }}>
                -
                {(+vatGrossAmountInvoices).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            {renderCheckCell(enabledCheckRow, checkRowVatAmount)}
          </tr>
          <tr>
            {renderCheckCell(enabledCheckCol, "Check")}
            {renderCheckCell(enabledCheckCol, ckColGrossAmount)}
            {renderCheckCell(enabledCheckCol, ckColNetAmount)}
            {renderCheckCell(enabledCheckCol, ckColNetRefunds)}
            {renderCheckCell(enabledCheckCol, ckColNetInvoices)}
            {renderCheckCell(enabledCheckCol, "")}
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default SalesLedgerTotalMonthsList;
