import React, { useState } from "react";
import {
  getCustomerUrl,
  getPlanUrl,
  getSubscriptionDetailUrl,
} from "../api/urls";
import { useMutation, useQuery } from "@apollo/client";

import ActionsButton from "../components/ActionsButton";
import Button from "react-bootstrap/Button";
import { ErrorMessage } from "../components/ErrorMessage";
import { IconContext } from "react-icons";
import { MUTATION_CANCEL_SUBSCRIPTION_SCHEDULED } from "../api/mutations";
import Modal from "react-bootstrap/Modal";
import { SUBSCRIPTION_SCHEDULED_LIST } from "../api/queries";
import SubscriptionsScheduledList from "../components/SubscriptionsScheduledList";
import { TiDeleteOutline } from "react-icons/ti";
import { useNavigate } from "react-router-dom";

function ConfirmDeleteModal({ onConfirm, onCancel, show }) {
  return (
    <Modal
      show={show.show}
      onHide={onCancel}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Annulla Schedulazione</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Questa azione annulla in modo irreversibile la schedulazione.<br></br>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Esci senza Annullare
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          <IconContext.Provider
            value={{ size: "1.5em", className: "global-class-name" }}
          >
            <TiDeleteOutline />
          </IconContext.Provider>{" "}
          Annulla Schedulazione
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function loadScheduledSubscription(subscriptionList) {
  let j = 0;
  let arrayListSubscription = subscriptionList.map((l) => {
    const customerFullName =
      l?.subscription?.invoicingCustomer?.uuid ===
      l?.subscription?.customer?.uuid
        ? ""
        : `${l?.subscription?.customer?.last} ${l?.subscription?.customer?.first}`;

    j = j + 1;

    return {
      scheduledDate: l?.scheduledDate,
      startDate: l?.subscription?.startDate,
      planName: l?.subscription?.plan?.name,
      paymentType: l?.subscription?.paymentType,
      invoicingCustomerFullName: `${l?.subscription?.invoicingCustomer?.last} ${l?.subscription?.invoicingCustomer?.first}`,
      customerFullName: customerFullName,
      subscriptionUuid: l?.subscription?.uuid,
      invoicingUuid: l?.subscription?.invoicingCustomer?.uuid,
      customerUuid: l?.subscription?.customer?.uuid,
      planUuid: l?.subscription?.plan?.uuid,
      scheduledUuid: l.uuid,
      index: j,
      menu: "•••",
    };
  });

  return arrayListSubscription;
}

function SubscriptionsScheduledListPage() {
  //const [completed, setCompleted] = useState(false);
  const [showModalCanceled, setShowModalCanceled] = useState({
    show: false,
    uuid: null,
  });

  const navigate = useNavigate();

  const { loading, error, data } = useQuery(SUBSCRIPTION_SCHEDULED_LIST, {});

  const [
    cancelSubscriptionScheduled,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(MUTATION_CANCEL_SUBSCRIPTION_SCHEDULED, {});

  const popoverLinksList = (cell) => {
    let linksList = [];
    const showCustomerDetail = cell?.customerUuid !== cell?.invoicingUuid;
    if (showCustomerDetail) {
      linksList.push({
        title: "Visualizza Cliente Beneficiario",
        url: getCustomerUrl(cell?.customerUuid),
      });
    }
    linksList = [
      ...linksList,
      {
        title: "Visualizza Abbonamento",
        url: getSubscriptionDetailUrl(cell?.subscriptionUuid),
      },
      {
        title: "Visualizza Cliente Titolare",
        url: getCustomerUrl(cell?.invoicingUuid),
      },
      {
        title: "Visualizza Piano",
        url: getPlanUrl(cell?.planUuid),
      },
    ];

    return linksList;
  };
  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            N.
          </div>
        ),
        accessor: "index",
        width: 20,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Data Schedulazione
          </div>
        ),
        accessor: "scheduledDate",
        width: 65,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Data Contratto
          </div>
        ),
        accessor: "startDate",
        width: 65,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Piano",
        accessor: "planName",
        width: 120,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Metodo Pagamento
          </div>
        ),
        accessor: "paymentType",
        width: 50,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Titolare",
        accessor: "invoicingCustomerFullName",
        width: 90,
      },
      {
        Header: "Beneficiario",
        accessor: "customerFullName",
        width: 90,
      },
      {
        Header: "",
        accessor: "menu",
        width: 25,
        Cell: ({ cell }) => {
          const links = popoverLinksList(cell?.row?.original);
          return <ActionsButton linksList={links} />;
        },
      },
      {
        Header: "",
        accessor: "menu1",
        width: 65,
        Cell: ({ cell }) => {
          const btnCanceled = (
            <>
              <div style={{ textAlign: "center" }}>
                <Button
                  variant={"outline-danger"}
                  size="sm"
                  key={"z56"}
                  type="button"
                  onClick={() => {
                    setShowModalCanceled({
                      show: true,
                      uuid: cell?.row?.original?.scheduledUuid,
                    });
                  }}
                >
                  <IconContext.Provider
                    value={{ size: "1.5em", className: "global-class-name" }}
                  >
                    <TiDeleteOutline />
                  </IconContext.Provider>{" "}
                  Annulla
                </Button>
              </div>
            </>
          );
          return btnCanceled;
        },
      },
    ],
    []
  );

  if (loading || mutationLoading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  if (mutationError) {
    return <ErrorMessage errors={[mutationError]} />;
  }

  const listSubscriptions = loadScheduledSubscription(
    data?.scheduledSubscriptionList
  );

  return (
    <>
      <SubscriptionsScheduledList data={listSubscriptions} columns={columns} />
      <ConfirmDeleteModal
        show={showModalCanceled}
        onConfirm={async () => {
          await cancelSubscriptionScheduled({
            variables: {
              guid: showModalCanceled.uuid,
            },
          });
          setShowModalCanceled({ show: false });
          navigate(0);
        }}
        onCancel={() => {
          setShowModalCanceled({ show: false });
        }}
      />
    </>
  );
}
export default SubscriptionsScheduledListPage;
