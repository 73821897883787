import {
  add,
  addMonths,
  differenceInDays,
  differenceInMinutes,
  endOfMonth,
  format,
  getDay,
  isAfter,
  parse,
  parseISO,
  set,
  startOfMonth,
} from "date-fns";

export function getItalianMonthDescription(monthNumber) {
  const italianMonths = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];
  return italianMonths[monthNumber - 1];
}

export function getLastDayOfMonth(date) {
  return format(endOfMonth(date), "dd-MM");
}

// Format date string into 'YYYY-MM-DD'
export function formatDate(inputDate) {
  const parsedDate = parse(inputDate, "yyyy-MM-dd", new Date());

  // Handle invalid parsing
  if (isNaN(parsedDate.getTime())) {
    console.error("Invalid date format:", inputDate);
    return null;
  }

  return format(parsedDate, "yyyy-MM-dd");
}

// Get the first day of the next month if it's not in the future
export function firstDayNextMonth(dateString) {
  const inputDate = parseISO(dateString);
  const nextMonth = startOfMonth(addMonths(inputDate, 1));
  return isAfter(nextMonth, new Date())
    ? null
    : format(nextMonth, "yyyy-MM-dd");
}

// Get the first day of the previous month
export function firstDayPreviousMonth(dateString) {
  const inputDate = parseISO(dateString);
  const previousMonth = startOfMonth(addMonths(inputDate, -1));
  return format(previousMonth, "yyyy-MM-dd");
}

// Format date using ISO string formatting
export function formatDateIso(inputDate) {
  const parsedDate = new Date(inputDate);
  return format(parsedDate, "yyyy-MM-dd");
}

export function formatTimeIso(inputDate) {
  let parsedInputDate = inputDate;
  if (typeof inputDate == "string") {
    const timeArray = inputDate.split(":");
    parsedInputDate = new Date();
    parsedInputDate.setHours(parseInt(timeArray[0]));
    parsedInputDate.setMinutes(parseInt(timeArray[1]));
    parsedInputDate.setSeconds(0);
    parsedInputDate.setMilliseconds(0);
  }
  try {
    const stringTime = parsedInputDate.toISOString().substr(11);
    return stringTime;
  } catch (err) {
    const errorMessage = `Error formatting time ISO string for inputDate "${inputDate}": ${err.message}`;
    const newError = new Error(errorMessage);
    newError.previousError = err;
    throw newError;
  }
}

export function formatLocalTime(inputDate) {
  let parsedInputDate = inputDate;

  if (typeof inputDate == "string") {
    // Loads the UTC string into a Date object
    const timeArray = inputDate.split(":").map((item) => {
      return parseInt(item);
    });
    parsedInputDate = new Date();
    parsedInputDate.setUTCHours(timeArray[0], timeArray[1], timeArray[2]);
  }

  const m = parsedInputDate.getMinutes();
  const h = parsedInputDate.getHours();
  const paddedMinutes = (m < 10 ? "0" : "") + m;
  const paddedHours = (h < 10 ? "0" : "") + h;

  return `${paddedHours}:${paddedMinutes}`;
}
// Calculate difference in days between two dates
export function diffDays(d1, d2) {
  return differenceInDays(d2, d1);
}

// Calculate difference in minutes between two dates
export function diffMinute(time1, time2) {
  return differenceInMinutes(new Date(time2), new Date(time1));
}

// Get the first day of the week based on a given index
export function firstDayOfWeek(dateObject, firstDayOfWeekIndex) {
  const dayOfWeek = getDay(dateObject);
  const diff =
    dayOfWeek >= firstDayOfWeekIndex
      ? dayOfWeek - firstDayOfWeekIndex
      : 7 - (firstDayOfWeekIndex - dayOfWeek);

  return set(add(dateObject, { days: -diff }), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
}
