import * as yup from "yup";

import React, { useEffect, useState } from "react";
import {
  isEmpty,
  subscriptionItalianState,
  subscriptionItalianStatePaid,
} from "../api/utils";
import { formatDateIso } from "../api/date-utils";
import {
  getCustomerUrl,
  getPlanUrl,
  getSubscriptionDetailUrl,
} from "../api/urls";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ErrorMessage } from "./ErrorMessage";
import Form from "react-bootstrap/Form";
import FormElement from "./FormElement";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import StateIcon from "./StateIcon";
import Tooltip from "react-bootstrap/Tooltip";
import UpdateStripePaymentModal from "./subscription/UpdateStripePaymentModal";
import addBsArrowRight from "../components/AddBsArrowRight";
import constants from "../constants";
import dropDownHeaderView from "../components/DropDownHeaderView";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UpdateReceivingCustomerModal from "../pages/UpdateReceivingCustomerModal";

const schema = yup.object().shape({
  productZones: yup
    .number()
    .required(constants.VALIDATION_MANDATORY_FIELD)
    .positive("Il numero deve essere positivo")
    .typeError("Deve essere un numero"),
});

function ContinueModal({ onContinue, show = false }) {
  return (
    <Modal show={show} onHide={onContinue}>
      <Modal.Header closeButton>
        <Modal.Title>Aggiornamento Abbonamento</Modal.Title>
      </Modal.Header>
      <Modal.Body>L'abbonamento è stato aggiornato correttamente</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onContinue}>
          Continua
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function ConfirmDeleteModal({ onConfirm, onCancel, show = false }) {
  return (
    <Modal show={show} onHide={onCancel} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Annulla Abbonamento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Questa azione annulla in modo irreversibile l'abbonamento.<br></br>
          Eventuali rate dell'abbonamento rimanenti non saranno mai più
          riscosse.<br></br>
          Su questo abbonamento non sarà più possibile effettuare Check-In.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Esci senza Annullare
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          <StateIcon
            nameIcon={constants.SUBSCRIPTION_STATE_CANCELED}
          ></StateIcon>
          Annulla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ConfirmPauseModal({ onConfirm, onCancel, show }) {
  const exitLabel = show.title === "Attiva" ? "Attivare" : "Sospendere";
  const notesPaused =
    "La riscossione delle rate previste nell'abbonamento viene sospeso a tempo indeterminato.";
  const notesActive =
    "La riscossione delle rate previste nell'abbonamento viene attivato nuovamente.";
  const notes = show.title === "Attiva" ? notesActive : notesPaused;
  const buttonConfirmPaused = (
    <Button variant="warning" onClick={onConfirm}>
      <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_PAUSED}></StateIcon>
      {`${show.title}`}
    </Button>
  );
  const buttonConfirmActive = (
    <Button variant="warning" onClick={onConfirm}>
      <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_ACTIVE}></StateIcon>
      {`${show.title}`}
    </Button>
  );

  const buttonConfirm =
    show.title === "Attiva" ? buttonConfirmActive : buttonConfirmPaused;

  return (
    <Modal
      show={show.show}
      onHide={onCancel}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{`${show.title} Abbonamento`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Siete sicuri di voler cambiare Stato? <br></br>Lo stato passerà da:{" "}
          <b>
            {show.oldState} --{">"} {show.newState}{" "}
          </b>
          <br></br>
          {notes}
          <br></br>
          Su questo abbonamento sarà ancora possibile effettuare Check-In.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {`Esci senza ${exitLabel}`}
        </Button>
        {buttonConfirm}
      </Modal.Footer>
    </Modal>
  );
}
function ConfirmScheduledModal({ onConfirm, onCancel, show, scheduledDate }) {
  const VALIDATION_MANDATORY_FIELD = "Campo obbligatorio";
  const schema = yup.object().shape({
    dateScheduled: yup
      .date()
      .min(new Date(), "La data non può essere nel passato")
      .required(VALIDATION_MANDATORY_FIELD),
  });

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      dateScheduled: scheduledDate,
    },
  });

  useEffect(() => {
    reset({
      dateScheduled: scheduledDate,
    });
  }, [reset, scheduledDate]);

  const onSubmit = async (formData) => {
    onConfirm(formData.dateScheduled);
  };

  return (
    <Modal
      show={show.show}
      onHide={onCancel}
      backdrop="static"
      keyboard={false}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Modifica Data Schedulazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Questa azione modifica la data di schedulazione dell'abbonamento.
          </p>
          <Form.Group as={Col} xs={12} controlId="date">
            <FormElement
              label="Scegli la data"
              type="date"
              name="dateScheduled"
              register={register}
              errors={errors}
            ></FormElement>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Esci senza Modificare
          </Button>
          <Button variant="primary" type="submit">
            <StateIcon
              nameIcon={constants.SUBSCRIPTION_STATE_SCHEDULED}
            ></StateIcon>
            Modifica
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function SubscriptionUpdateForm({
  data,
  payData,
  updateSubscription,
  updating,
  completed,
  updateError,
  modalContinueClicked = () => {},
  currentLocation,
}) {
  const [showModalCanceled, setShowModalCanceled] = useState(false);
  const [showModalContinue, setShowModalContinue] = useState(false);
  const [showUpdateStripePaymentModal, setShowUpdateStripePaymentModal] =
    useState(false);

  const [showModalPaused, setShowModalPaused] = useState({
    show: false,
    newState: data?.state,
    oldState: data?.state,
    title: "",
  });
  const [showModalScheduled, setShowModalScheduled] = useState({
    show: false,
  });
  const [
    showUpdateReceivingCustomerModal,
    setShowUpdateReceivingCustomerModal,
  ] = useState(false);

  // Form hooks
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      productZones: data?.zonesQuantity,
      notes: data?.notes,
    },
  });

  const buttonSpinner = <Spinner animation="border" variant="light" />;
  const updatingSuccess = (
    <Alert variant="success"> Registrazione effettuata</Alert>
  );

  const paySubscription = payData?.stripeSubscription?.result;

  const onSubmit = async (formData) => {
    const subscription = {
      state: null,
      notes: formData.notes,
      zonesQuantity: formData.productZones,
    };

    await updateSubscription({
      variables: {
        subscription,
        guid: data.uuid,
      },
    });

    setShowModalContinue(true);
  };

  let btnUpdate = (
    <Button
      variant={isEmpty(errors) ? "primary" : "danger"}
      type="submit"
      disabled={updating}
      key={"z5"}
    >
      {updating ? buttonSpinner : "Aggiorna"}
    </Button>
  );

  let placement = "bottom";

  const cancelButton = (
    <OverlayTrigger
      key={"btn1"}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-btn1`}>
          <StateIcon
            nameIcon={constants.SUBSCRIPTION_STATE_CANCELED}
          ></StateIcon>
          <strong>Annulla</strong> l'abbonamento. La riscossione viene
          interrotta definitivamente.
        </Tooltip>
      }
    >
      <Button
        variant={"danger"}
        size="sm"
        disabled={updating}
        key={"z56"}
        type="button"
        onClick={() => {
          setShowModalCanceled(true);
        }}
      >
        <StateIcon
          nameIcon={constants.SUBSCRIPTION_STATE_CANCELED}
          // colorIc="danger"
        ></StateIcon>
        Annulla
      </Button>
    </OverlayTrigger>
  );

  const pauseButton = (
    <OverlayTrigger
      key={"btn2"}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-btn2`}>
          <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_PAUSED}></StateIcon>
          <strong>Sospende</strong> l'abbonamento. La riscossione viene sospesa.
        </Tooltip>
      }
    >
      <Button
        variant={"warning"}
        size="sm"
        disabled={updating}
        key={"z51"}
        type="button"
        onClick={() => {
          setShowModalPaused({
            show: true,
            newState: "Sospeso",
            oldState: subscriptionItalianState[data?.state],
            title: "Sospendi",
          });
        }}
      >
        <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_PAUSED}></StateIcon>
        Sospendi
      </Button>
    </OverlayTrigger>
  );

  const resumeButton = (
    <OverlayTrigger
      key={"btn1"}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-btn1`}>
          <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_ACTIVE}></StateIcon>
          <strong>Attiva</strong> l'abbonamento. Si riattiva la riscossione.
        </Tooltip>
      }
    >
      <Button
        variant={"warning"}
        size="sm"
        disabled={updating}
        key={"2"}
        onClick={() => {
          setShowModalPaused({
            show: true,
            newState: "Attivo",
            oldState: subscriptionItalianState[data?.state],
            title: "Attiva",
          });
        }}
      >
        <StateIcon nameIcon={constants.SUBSCRIPTION_STATE_ACTIVE}></StateIcon>
        Attiva
      </Button>
    </OverlayTrigger>
  );

  const updateScheduledDateButton = (
    <OverlayTrigger
      key={"btn3"}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-btn3`}>
          <StateIcon
            nameIcon={constants.SUBSCRIPTION_STATE_SCHEDULED}
          ></StateIcon>
          <strong>Modifica</strong> la data di schedulazione.
        </Tooltip>
      }
    >
      <Button
        variant={"secondary"}
        size="sm"
        disabled={updating}
        key={"z56"}
        type="button"
        onClick={() => {
          setShowModalScheduled({
            show: true,
          });
        }}
      >
        <StateIcon
          nameIcon={constants.SUBSCRIPTION_STATE_SCHEDULED}
          // colorIc="danger"
        ></StateIcon>
        Modifica data schedulazione
      </Button>
    </OverlayTrigger>
  );

  let buttonGroupShowingForActiveSubscription = (
    <Form.Group>
      {cancelButton}
      {pauseButton}
    </Form.Group>
  );

  let buttonGroupShowingForPausedSubscription = (
    <Form.Group>
      {cancelButton}
      {resumeButton}
    </Form.Group>
  );

  const buttonGroupShowingForScheduledSubscription = (
    <Form.Group>{updateScheduledDateButton}</Form.Group>
  );

  const buttonGroupShowingForNeedsAuthSubscription = (
    <Form.Group>{cancelButton}</Form.Group>
  );

  let paymentType = data?.paymentType;

  // If the status is: Pending, Schedeled, Canceled, the subscription is not editable
  if (
    [
      constants.SUBSCRIPTION_STATE_CANCELED,
      constants.SUBSCRIPTION_STATE_PENDING,
    ].includes(data?.state)
  ) {
    btnUpdate = <Col />;
  }
  // If the current location is different from the current one, no changes can be made
  if (data?.location?.uuid !== currentLocation?.uuid) {
    btnUpdate = <Col />;
    buttonGroupShowingForActiveSubscription = <Col />;
    buttonGroupShowingForPausedSubscription = <Col />;
  }

  if (data?.paymentType === constants.STRIPE_PAYMENT_METHOD) {
    if (!!payData) {
      let pause_behavior =
        paySubscription?.pauseBehavior === null
          ? ""
          : `-- ${paySubscription?.pauseBehavior}`;
      paymentType = `${data?.paymentType} -- ${paySubscription?.status} ${pause_behavior}`;
      if (
        paySubscription?.status.toUpperCase() ===
        constants.STRIPE_STATUS_SUBSCRIPTION_CANCELED
      ) {
        btnUpdate = <Col />;
      }
    } else {
      paymentType = `${data?.paymentType} -- Query failed`;
    }
  }

  let dataChangeState = "";
  let labelDataChangeState = "";

  if (data?.state === constants.SUBSCRIPTION_STATE_CANCELED) {
    const changeState = !!data?.cancelDate ? data?.cancelDate : "";
    if (!!changeState) {
      labelDataChangeState = `${subscriptionItalianState[data?.state]} in data`;
      dataChangeState = data?.cancelDate;
    } else {
      labelDataChangeState = "Stato";
      dataChangeState = `${subscriptionItalianState[data?.state]}`;
    }
  }

  if (data?.state === constants.SUBSCRIPTION_STATE_SCHEDULED) {
    dataChangeState = buttonGroupShowingForScheduledSubscription;
    labelDataChangeState = `Stato: ${
      subscriptionItalianState[data?.state]
    } in data: ${data?.scheduledDate}`;
  }

  if (data?.state === constants.SUBSCRIPTION_STATE_ACTIVE) {
    dataChangeState = buttonGroupShowingForActiveSubscription;
    labelDataChangeState = `Stato: ${subscriptionItalianState[data?.state]}`;
  }

  if (data?.state === constants.SUBSCRIPTION_STATE_PAUSED) {
    dataChangeState = buttonGroupShowingForPausedSubscription;
    labelDataChangeState = `Stato: ${
      subscriptionItalianState[data?.state]
    } (Riscossione in Pausa)`;
  }

  if (data?.state === constants.SUBSCRIPTION_STATE_PENDING) {
    labelDataChangeState = "Stato";
    dataChangeState = `${subscriptionItalianState[data?.state]} (Pending)`;
  }

  let dateStart = data?.startDate;
  dateStart =
    data?.paidState === constants.SUBSCRIPTION_STATEPAID_UNPAID
      ? `${dateStart} -- ${
          subscriptionItalianStatePaid[constants.SUBSCRIPTION_STATEPAID_UNPAID]
        }`
      : dateStart;
  if (data?.state === constants.SUBSCRIPTION_STATE_NEEDS_AUTH) {
    dataChangeState = buttonGroupShowingForNeedsAuthSubscription;
    labelDataChangeState = "Stato: Autorizzazione richiesta";
    //dataChangeState = `${subscriptionItalianState[data?.state]} (Needs Auth)`;
  }
  const dropdownCustomer =
    data?.customer?.uuid === data?.invoicingCustomer?.uuid ? null : (
      <Dropdown.Item href={getCustomerUrl(data?.customer?.uuid)}>
        {addBsArrowRight("Beneficiario")}
      </Dropdown.Item>
    );

  const cardLast4 = paySubscription?.paymentMethod?.paymentVisibleIdentifier;
  const shownCardLast4 = cardLast4 ? `•••• ${cardLast4}` : "Nessuna";

  let enabledChangeReceivingCustomer = false;

  if (
    data?.customer?.uuid !== data?.invoicingCustomer?.uuid ||
    data?.state !== constants.SUBSCRIPTION_STATE_ACTIVE
  )
    enabledChangeReceivingCustomer = true;

  return (
    <>
      <Form
        onSubmit={(e) => {
          return handleSubmit(onSubmit)(e);
        }}
      >
        <Row>
          <Col xs={9}>
            <h4 className="text-center"> Modifica Abbonamento </h4>
          </Col>
          <Col xs={3}>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <ButtonGroup>
                <Col>{btnUpdate}</Col>
                <DropdownButton
                  menualign="right"
                  title="Azioni"
                  id="dropdown-menu-align-right"
                  variant="outline-secondary"
                >
                  <Dropdown.Item
                    onClick={() => {
                      setShowUpdateStripePaymentModal(true);
                    }}
                  >
                    Cambio Carta
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setShowUpdateReceivingCustomerModal(true);
                    }}
                    disabled={enabledChangeReceivingCustomer}
                  >
                    Cambio Beneficiario
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                  <Dropdown.Item href={getCustomerUrl(data?.customer?.uuid)}>
                    {addBsArrowRight("Titolare")}
                  </Dropdown.Item>
                  {dropdownCustomer}
                  <Dropdown.Item href={getSubscriptionDetailUrl(data?.uuid)}>
                    {addBsArrowRight("Abbonamento")}
                  </Dropdown.Item>
                  <Dropdown.Item href={getPlanUrl(data?.plan?.uuid)}>
                    {addBsArrowRight("Piano")}
                  </Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
        <h5> Cliente Beneficiario</h5>
        <Row>
          <Col>
            <h6> Nome: </h6>
            <span>{data?.customer?.first} </span>
          </Col>
          <Col>
            <h6>Cognome</h6>
            <span>{data?.customer?.last}</span>
          </Col>
          <Col>
            <h6>Mail</h6>
            <span>{data?.customer?.email}</span>
          </Col>
        </Row>
        <hr />
        <h5> Cliente Titolare Pagamenti</h5>
        <Row>
          <Col>
            <h6> Nome: </h6>
            <span>{data?.invoicingCustomer?.first} </span>
          </Col>
          <Col>
            <h6>Cognome</h6>
            <span>{data?.invoicingCustomer?.last}</span>
          </Col>
          <Col>
            <h6>Mail</h6>
            <span>{data?.invoicingCustomer?.email}</span>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h6> Data Inizio </h6>
            <span>{dateStart} </span>
          </Col>
          <Col>
            <h6>Piano</h6>
            <span>{data?.plan?.name}</span>
          </Col>
          <Col>
            <h6>Metodo di pagamento </h6>
            <span>{paymentType}</span>
          </Col>
        </Row>
        <Row>
          <div className="mt-4" />
        </Row>

        <Row>
          <Col>
            <Form.Group xs={4} controlId="productZones">
              <FormElement
                label="Numero Zone"
                key={"z53"}
                type="number"
                name="productZones"
                register={register}
                errors={errors}
                controlOptions={{ defaultValue: "1", max: 15, min: 0 }}
              ></FormElement>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group xs={4} controlId="changeData">
              <Form.Label> {labelDataChangeState} </Form.Label>
              <Row>
                <Col>{dataChangeState}</Col>
              </Row>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group xs={4} controlId="currentLocation">
              <Form.Label> Sede Corrente </Form.Label>
              <Row>
                <Col>
                  <Form.Label> {data?.location?.name} </Form.Label>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <div className="mt-4" />
        </Row>
        <Row>
          <Col>
            <h6>Carta attiva</h6>
            <span>{shownCardLast4}</span>
          </Col>
        </Row>
        <Row>
          <div className="mt-4" />
        </Row>
        <Row>
          <Form.Group as={Col} controlId="notes">
            <FormElement
              label="Note"
              key={"z6"}
              name="notes"
              register={register}
              errors={errors}
              controlOptions={{ rows: "2", as: "textarea" }}
            ></FormElement>
          </Form.Group>
        </Row>

        <Row>
          <ErrorMessage error={updateError} />
        </Row>
        <Row>{completed ? updatingSuccess : null}</Row>
      </Form>
      <ConfirmDeleteModal
        show={showModalCanceled}
        onConfirm={async () => {
          const subscription = {
            state: constants.SUBSCRIPTION_STATE_CANCELED,
          };
          await updateSubscription({
            variables: {
              subscription,
              guid: data.uuid,
            },
          });
          setShowModalCanceled(false);
        }}
        onCancel={() => {
          setShowModalCanceled(false);
        }}
      />
      <ConfirmPauseModal
        show={showModalPaused}
        onConfirm={async () => {
          let subscription = {
            state: constants.SUBSCRIPTION_STATE_PAUSED,
          };
          if (data?.state === constants.SUBSCRIPTION_STATE_PAUSED)
            subscription = {
              state: constants.SUBSCRIPTION_STATE_ACTIVE,
            };

          await updateSubscription({
            variables: {
              subscription,
              guid: data.uuid,
            },
          });
          setShowModalPaused({
            show: false,
            newState: "Attivo",
            oldState: subscriptionItalianState[data?.state],
            title: "Attiva",
          });
        }}
        onCancel={() => {
          setShowModalPaused({
            show: false,
            newState: "Attivo",
            oldState: subscriptionItalianState[data?.state],
            title: "Attiva",
          });
        }}
      />
      <ContinueModal
        show={showModalContinue}
        onContinue={() => {
          setShowModalContinue(false);
          modalContinueClicked();
        }}
      />
      <ConfirmScheduledModal
        show={showModalScheduled}
        scheduledDate={data.scheduledDate}
        onConfirm={async (newScheduledDate) => {
          const subscription = {
            scheduledDate: formatDateIso(new Date(newScheduledDate)),
          };
          await updateSubscription({
            variables: {
              subscription,
              guid: data.uuid,
            },
          });
          setShowModalScheduled({
            show: false,
          });
        }}
        onCancel={() => {
          setShowModalScheduled({
            show: false,
          });
        }}
      />
      <UpdateStripePaymentModal
        show={showUpdateStripePaymentModal}
        onHide={() => {
          setShowUpdateStripePaymentModal(false);
        }}
        customer={data?.invoicingCustomer}
        subscription={data}
        stripeSubscription={paySubscription}
      />
      <UpdateReceivingCustomerModal
        show={showUpdateReceivingCustomerModal}
        onHide={() => {
          setShowUpdateReceivingCustomerModal(false);
        }}
        subscriptionUuid={data?.uuid}
      />
    </>
  );
}

export default SubscriptionUpdateForm;
