import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import dropDownHeaderView from "../components/DropDownHeaderView";
import addBsArrowRight from "../components/AddBsArrowRight";
import {
  useTable,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
} from "react-table";
import constants from "../constants";
import { getCustomerUrl } from "../api/urls";

function CustomerSubscriptionsList({ columns, subscriptionsList, customer }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: subscriptionsList,
      },
      useSortBy,
      useFlexLayout,
      useResizeColumns
    );

  return (
    <>
      <Row>
        <Col xs={8}>
          <h3 className="text-center"> Elenco Abbonamenti Cliente </h3>
        </Col>
        <Col xs={4}>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <ButtonGroup>
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="outline-primary"
              >
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item href={getCustomerUrl(customer?.uuid)}>
                  {addBsArrowRight("Cliente")}
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Nome: </h6>
          <span>{customer.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{customer.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{customer.email}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Table striped bordered hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps(
                    column.getSortByToggleProps()
                  );
                  return (
                    <th key={key} {...restColumnProps}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? constants.DESCENDING_SORT_SIMBOL
                            : constants.ASCENDING_SORT_SIMBOL
                          : ""}
                      </span>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <tr key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  return (
                    <td key={key} {...restCellProps}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="mt-4" />
    </>
  );
}

export default CustomerSubscriptionsList;
