import "./App.css";

import {
  getSubscriptionCheckinsListUrl,
  getSubscriptionInvoicesListUrl,
} from "../api/urls";
import { loadListCheckIn, loadListInvoice } from "../api/utils";

import { ErrorMessage } from "../components/ErrorMessage";
import { QUERY_GET_SUBSCRIPTION } from "../api/queries";
import { QUERY_GET_SUBSCRIPTION_PAYMENT } from "../api/queries";
import { MUTATION_CANCEL_DOCUMENT_CONTRACT } from "../api/mutations";
import SubscriptionDetailForm from "../components/SubscriptionDetailForm";
import constants from "../constants";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";

function SubscriptionDetailPage() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [documentType, setDocumentType] = useState(null);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [cancelDocument] = useMutation(MUTATION_CANCEL_DOCUMENT_CONTRACT);

  const handleCancelDocument = async () => {
    await cancelDocument({
      variables: {
        subscriptionUuid: guid,
        operationType: documentType,
      },
    })
      .then((result) => {
        if (!!result?.data?.cancelDocumentContract?.error) {
          // There is an error
          setShowError(true);
          setErrorMessage(result?.data?.cancelDocumentContract?.error?.message);
        } else {
          setShowModal(false);
        }
      })
      .catch((error) => {
        setShowError(true);
        let msg = `${error.message} ${error?.networkError?.result?.errors[0]?.message}`;
        setErrorMessage(msg);
      });
  };

  const handleShowModal = (type) => {
    setDocumentType(type);
    setShowModal(true);
  };

  let { guid } = useParams();
  const subscriptionUuid = guid;
  const LIMIT_MAX_RECORD = 3;

  const { loading, error, data } = useQuery(QUERY_GET_SUBSCRIPTION, {
    variables: {
      subscriptionUuid: guid,
      checkInMaxLimit: LIMIT_MAX_RECORD,
    },
  });
  const { loading: payLoading, data: payData } = useQuery(
    QUERY_GET_SUBSCRIPTION_PAYMENT,
    {
      variables: {
        subscriptionUuid: guid,
      },
    }
  );

  if (loading || payLoading) {
    return <p>Loading..</p>;
  }

  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  let subscriptionData = data?.subscription;
  const userSetting = data?.userSetting;

  const counterCheckIn = data?.checkInCounter;
  const counterCheckInNotPresent = data?.checkInCounterNotPresent;
  const invoiceCounterExpired = data?.invoiceCounterExpired;
  const checkinList = loadListCheckIn(data?.subscriptionCheckins);

  let invoiceList = null;

  const dataAvailable =
    subscriptionData?.paymentType === constants.STRIPE_PAYMENT_METHOD &&
    !!payData &&
    !!payData?.invoiceForSubscription?.result &&
    !!payData?.stripeSubscription?.result;
  if (dataAvailable) {
    invoiceList = loadListInvoice(payData?.invoiceForSubscription?.result);
  }

  function openSubscriptionCheckinsList(values) {
    navigate(getSubscriptionCheckinsListUrl(subscriptionUuid));
  }

  function openSubscriptionInvoicesList(values) {
    navigate(getSubscriptionInvoicesListUrl(subscriptionUuid));
  }

  return (
    <>
      <SubscriptionDetailForm
        data={subscriptionData}
        payData={payData}
        checkinList={checkinList}
        counterCheckIn={counterCheckIn}
        counterCheckInNotPresent={counterCheckInNotPresent}
        invoiceCounterExpired={invoiceCounterExpired}
        openSubscriptionCheckinsList={openSubscriptionCheckinsList}
        invoiceForSubscription={invoiceList}
        openSubscriptionInvoicesList={openSubscriptionInvoicesList}
        userSetting={userSetting}
        onCancelDocument={handleShowModal}
      />

      {showError && (
        <>
          <div className="mt-4" />
          <Alert
            variant="danger"
            onClose={() => {
              setShowError(false);
              setErrorMessage("");
            }}
            dismissible
          >
            <Alert.Heading>
              Errore durante l'annullamento del documento
            </Alert.Heading>
            <p>{errorMessage}</p>
          </Alert>
        </>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma Annullamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sei sicuro di voler annullare questo documento?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annulla
          </Button>
          <Button
            variant="primary"
            onClick={handleCancelDocument}
            disabled={showError}
          >
            Conferma
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SubscriptionDetailPage;
