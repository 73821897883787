import { FormControl, InputGroup } from "react-bootstrap";

import { BsSearch } from "react-icons/bs";
import React from "react";

function SearchInput({ onChange }) {
  const handleInputChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <InputGroup>
      <FormControl
        type="text"
        placeholder="Search..."
        onChange={handleInputChange}
        aria-label="Search"
      />
      <InputGroup.Text>
        <BsSearch />
      </InputGroup.Text>
    </InputGroup>
  );
}

export default SearchInput;
