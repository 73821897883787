import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import CreditInsertForm from "../components/CreditInsertForm";
import { ErrorMessage } from "../components/ErrorMessage";
import { MUTATION_CREATE_CREDIT } from "../api/mutations";
import { Navigate } from "react-router";
import { QUERY_DATA_FOR_NEW_CREDIT } from "../api/queries";
import { getCustomerUrl } from "../api/urls";
import { useParams } from "react-router-dom";

function CreditInsertPage() {
  let { customerUuid } = useParams();

  const [
    createCreditMutation,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_CREATE_CREDIT);

  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);
  const { loading, error, data } = useQuery(QUERY_DATA_FOR_NEW_CREDIT, {
    variables: {
      customerUuid: customerUuid,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  let redirectContinueToList = null;
  if (!!shouldRedirectToList) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: getCustomerUrl(customerUuid),
        }}
      />
    );
  }
  return (
    <>
      <CreditInsertForm
        data={data}
        createCredit={createCreditMutation}
        creating={mutationLoading}
        completed={!!mutationData}
        createError={mutationError}
        modalContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
      />
      {redirectContinueToList}
    </>
  );
}

export default CreditInsertPage;
