import { Form } from "react-bootstrap";
import React from "react";

const NotesDisplay = ({ notes }) => {
  return (
    <Form.Control
      as="textarea"
      disabled
      id="notes"
      name="Notes"
      rows="4"
      value={notes}
      className="w-100"
    />
  );
};

export default NotesDisplay;
