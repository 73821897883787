// Codici stati esteri ISO 3166-1 alpha-2
export const STATI = [
  ["AF", "Afghanistan", "Z200"],
  ["AL", "Albania", "Z100"],
  ["DZ", "Algeria", "Z301"],
  ["AD", "Andorra", "Z101"],
  ["AO", "Angola", "Z302"],
  ["AI", "Anguilla (isola)", "Z529"],
  ["AQ", "Antartide", ""],
  ["AG", "Antigua e Barbuda", "Z532"],
  ["AN", "Antille Olandesi", "Z501"],
  ["SA", "Arabia Saudita", "Z203"],
  ["AR", "Argentina", "Z600"],
  ["AM", "Armenia", "Z252"],
  ["AW", "Aruba", "Z112"],
  ["AU", "Australia", "Z2700"],
  ["AT", "Austria", "Z102"],
  ["AZ", "Azerbaijan", "Z253"],
  ["BS", "Bahamas", "Z502"],
  ["BH", "Bahrain", "Z204"],
  ["BD", "Bangladesh", "Z249"],
  ["BB", "Barbados", "Z522"],
  ["BE", "Belgio", "Z103"],
  ["BZ", "Belize", "Z512"],
  ["BJ", "Benin", "Z314"],
  ["BM", "Bermuda", "Z400"],
  ["BY", "Bielorussia", "Z139"],
  ["BT", "Bhutan", "Z205"],
  ["BO", "Bolivia", "Z601"],
  ["BA", "Bosnia Erzegovina", "Z153"],
  ["BW", "Botswana", "Z364"],
  ["BR", "Brasile", "Z602"],
  ["BN", "Brunei Darussalam", "Z207"],
  ["BG", "Bulgaria", "Z104"],
  ["BF", "Burkina Faso", "Z306"],
  ["BI", "Burundi", "Z305"],
  ["KH", "Cambogia", "Z208"],
  ["CM", "Camerun", "Z306"],
  ["CA", "Canada", "Z401"],
  ["CV", "Capo Verde", "Z307"],
  ["TD", "Ciad", "Z309"],
  ["CL", "Cile", "Z603"],
  ["CN", "Cina", "Z210"],
  ["CY", "Cipro", "Z211"],
  ["VA", "Città del Vaticano", "Z106"],
  ["CO", "Colombia", "Z604"],
  ["KM", "Comore", "Z310"],
  ["KP", "Corea del Nord", "Z214"],
  ["KR", "Corea del Sud", "Z213"],
  ["CR", "Costa Rica", "Z503"],
  ["CI", "Costa d'Avorio", "Z313"],
  ["HR", "Croazia", "Z149"],
  ["CU", "Cuba", "Z504"],
  ["DK", "Danimarca", "Z107"],
  ["DM", "Dominica", "Z526"],
  ["EC", "Ecuador", "Z605"],
  ["EG", "Egitto", "Z336"],
  ["IE", "Eire", "Z108"],
  ["SV", "El Salvador", "Z506"],
  ["AE", "Emirati Arabi Uniti", "Z215"],
  ["ER", "Eritrea", "Z368"],
  ["EE", "Estonia", "Z144"],
  ["ET", "Etiopia", "Z315"],
  ["RU", "Federazione Russa", "Z154"],
  ["FJ", "Fiji", "Z704"],
  ["PH", "Filippine", "Z216"],
  ["FI", "Finlandia", "Z109"],
  ["FR", "Francia", "Z110"],
  ["GA", "Gabon", "Z316"],
  ["GM", "Gambia", "Z317"],
  ["GE", "Georgia", "Z254"],
  ["DE", "Germania", "Z112"],
  ["GH", "Ghana", "Z318"],
  ["JM", "Giamaica", "Z507"],
  ["JP", "Giappone", "Z219"],
  ["GI", "Gibilterra", "Z113"],
  ["DJ", "Gibuti", "Z361"],
  ["JO", "Giordania", "Z220"],
  ["GR", "Grecia", "Z115"],
  ["GD", "Grenada", "Z509"],
  ["GL", "Groenlandia", "Z402"],
  ["GP", "Guadalupa", "Z508"],
  ["GU", "Guam", "Z706"],
  ["GT", "Guatemala", "Z509"],
  ["GN", "Guinea", "Z319"],
  ["GW", "Guinea-Bissau", "Z320"],
  ["GQ", "Guinea Equatoriale", "Z321"],
  ["GY", "Guyana", "Z606"],
  ["GF", "Guyana Francese", "Z607"],
  ["HT", "Haiti", "Z510"],
  ["HN", "Honduras", "Z511"],
  ["HK", "Hong Kong", "Z221"],
  ["IN", "India", "Z222"],
  ["ID", "Indonesia", "Z223"],
  ["IR", "Iran", "Z224"],
  ["IQ", "Iraq", "Z225"],
  ["BV", "Isola di Bouvet", ""],
  ["CX", "Isola di Natale", "Z702"],
  ["HM", "Isola Heard e Isole McDonald", ""],
  ["KY", "Isole Cayman", "Z503"],
  ["CC", "Isole Cocos", "Z212"],
  ["CK", "Isole Cook", "Z703"],
  ["FK", "Isole Falkland", "Z609"],
  ["FO", "Isole Faroe", "Z108"],
  ["MH", "Isole Marshall", "Z711"],
  ["MP", "Isole Marianne Settentrionali", "Z710"],
  ["UM", "Isole Minori degli Stati Uniti d'America", ""],
  ["NF", "Isola Norfolk", "Z715"],
  ["SB", "Isole Solomon", "Z724"],
  ["TC", "Isole Turks e Caicos", "Z519"],
  ["VI", "Isole Vergini Americane", "Z520"],
  ["VG", "Isole Vergini Britanniche", "Z525"],
  ["IL", "Israele", "Z226"],
  ["IS", "Islanda", "Z117"],
  ["IT", "Italia", ""],
  ["KZ", "Kazakistan", "Z255"],
  ["KE", "Kenya", "Z322"],
  ["KG", "Kirghizistan", "Z256"],
  ["KI", "Kiribati", "Z731"],
  ["KW", "Kuwait", "Z227"],
  ["LA", "Laos", "Z228"],
  ["LV", "Lettonia", "Z145"],
  ["LS", "Lesotho", "Z359"],
  ["LB", "Libano", "Z229"],
  ["LR", "Liberia", "Z325"],
  ["LY", "Libia", "Z326"],
  ["LI", "Liechtenstein", "Z119"],
  ["LT", "Lituania", "Z146"],
  ["LU", "Lussemburgo", "Z120"],
  ["MO", "Macao", "Z231"],
  ["MK", "Macedonia", "Z148"],
  ["MG", "Madagascar", "Z327"],
  ["MW", "Malawi", "Z328"],
  ["MV", "Maldive", "Z232"],
  ["MY", "Malesia", "Z247"],
  ["ML", "Mali", "Z329"], //---
  ["MT", "Malta", "Z121"],
  ["MA", "Marocco", "Z330"],
  ["MQ", "Martinica", "Z513"],
  ["MR", "Mauritania", "Z331"],
  ["MU", "Mauritius", "Z332"],
  ["YT", "Mayotte", "Z360"],
  ["MX", "Messico", "Z514"],
  ["MD", "Moldavia", "Z140"],
  ["MC", "Monaco", "Z123"],
  ["MN", "Mongolia", "Z233"],
  ["MS", "Montserrat", "Z531"],
  ["MZ", "Mozambico", "Z333"],
  ["MM", "Myanmar", "Z206"],
  ["NA", "Namibia", "Z300"],
  ["NR", "Nauru", "Z713"],
  ["NP", "Nepal", "Z234"],
  ["NI", "Nicaragua", "Z515"],
  ["NE", "Niger", "Z334"],
  ["NG", "Nigeria", "Z335"],
  ["NU", "Niue", "Z714"],
  ["NO", "Norvegia", "Z125"],
  ["NC", "Nuova Caledonia", "Z716"],
  ["NZ", "Nuova Zelanda", "Z719"],
  ["OM", "Oman", "Z235"],
  ["NL", "Paesi Bassi", "Z126"],
  ["PK", "Pakistan", "Z236"],
  ["PW", "Palau", "Z734"],
  ["PA", "Panama", "Z516"],
  ["PG", "Papua Nuova Guinea", "Z730"],
  ["PY", "Paraguay", "Z610"],
  ["PE", "Peru", "Z611"],
  ["PN", "Pitcairn", "Z722"],
  ["PF", "Polinesia Francese", "Z723"],
  ["PL", "Polonia", "Z127"],
  ["PT", "Portogallo", "Z128"],
  ["PR", "Porto Rico", "Z518"],
  ["QA", "Qatar", "Z237"],
  ["GB", "Regno Unito", "Z114"],
  ["CZ", "Repubblica Ceca", "Z156"],
  ["CF", "Repubblica Centroafricana", "Z308"],
  ["CG", "Repubblica del Congo", "Z311"],
  ["CD", "Repubblica Democratica del Congo", "Z312"],
  ["DO", "Repubblica Dominicana", "Z505"],
  ["RE", "Reunion", "Z324"],
  ["RO", "Romania", "Z129"],
  ["RW", "Ruanda", "Z338"],
  ["EH", "Sahara Occidentale", "Z339"],
  ["KN", "Saint Kitts e Nevis", "Z533"],
  ["PM", "Saint Pierre e Miquelon", "Z403"],
  ["VC", "Saint Vincent e Grenadine", "Z528"],
  ["WS", "Samoa", "Z726"],
  ["AS", "Samoa Americane", "Z725"],
  ["SM", "San Marino", "Z130"],
  ["SH", "Sant'Elena", "Z340"],
  ["LC", "Santa Lucia", "Z527"],
  ["ST", "Sao Tome e Principe", "Z341"],
  ["SN", "Senegal", "Z343"],
  ["XK", "Kosovo", "Z160"],
  ["SC", "Seychelles", "Z342"],
  ["SL", "Sierra Leone", "Z3444"],
  ["SG", "Singapore", "Z248"],
  ["SY", "Siria", "Z240"],
  ["SK", "Slovacchia", "Z155"],
  ["SI", "Slovenia", "Z150"],
  ["SO", "Somalia", "Z345"],
  ["ES", "Spagna", "Z131"],
  ["LK", "Sri Lanka", "Z209"],
  ["FM", "Stati Federati della Micronesia", "Z701"],
  ["US", "Stati Uniti d'America", "Z404"],
  ["ZA", "Sud Africa", "Z347"],
  ["GS", "Sud Georgia e Isole Sandwich", ""],
  ["SD", "Sudan", "Z348"],
  ["SR", "Suriname", "Z608"],
  ["SJ", "Svalbard e Jan Mayen", ""],
  ["SE", "Svezia", "Z132"],
  ["CH", "Svizzera", "Z133"],
  ["SZ", "Swaziland", "Z349"],
  ["TJ", "Tagikistan", "Z147"],
  ["TH", "Tailandia", "Z241"],
  ["TW", "Taiwan", "Z217"],
  ["TZ", "Tanzania", "Z357"],
  ["IO", "Territori Britannici dell'Oceano Indiano", ""],
  ["TF", "Territori Francesi del Sud", "Z346"],
  ["PS", "Territori Palestinesi Occupati", "Z161"],
  ["TL", "Timor Est", "Z242"],
  ["TG", "Togo", "Z351"],
  ["TK", "Tokelau", "Z727"],
  ["TO", "Tonga", "Z728"],
  ["TT", "Trinidad e Tobago", "Z612"],
  ["TN", "Tunisia", "Z352"],
  ["TR", "Turchia", "Z243"],
  ["TM", "Turkmenistan", "Z258"],
  ["TV", "Tuvalu", "Z732"],
  ["UA", "Ucraina", "Z138"],
  ["UG", "Uganda", "Z334"],
  ["HU", "Ungheria", "Z157"],
  ["UY", "Uruguay", "Z613"],
  ["UZ", "Uzbekistan", "Z259"],
  ["VU", "Vanuatu", "Z733"],
  ["VE", "Venezuela", "Z614"],
  ["VN", "Vietnam", "Z251"],
  ["WF", "Wallis e Futuna", "Z729"],
  ["YE", "Yemen", "Z246"],
  ["ZM", "Zambia", "Z355"],
  ["ZW", "Zimbabwe", "Z337"],
  ["RS", "Serbia", "Z158"],
  ["ME", "Montenegro", "Z159"],
  ["TP", "Timor Est", "Z242"],
  ["GG", "Guernsey", ""],
];
