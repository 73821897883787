import Badge from "react-bootstrap/Badge";
import { BsArrowDown } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../ErrorFallback";
import { ErrorMessage } from "../ErrorMessage";
import ListGroup from "react-bootstrap/ListGroup";
import { Spinner } from "react-bootstrap";
import { formatDateIso } from "../../api/date-utils";
import styled from "@emotion/styled";
const sortAndGroupLeadsByDate = (leads) => {
  if (!leads) return [];

  const sortedLeads = Array.from(leads);

  sortedLeads.sort((a, b) => {
    //const delta = new Date(a.creationDate) - new Date(b.creationDate);
    const delta = b.similarity - a.similarity;
    return delta;
  });

  const groupedLeads = sortedLeads.reduce((groups, lead) => {
    const date = formatDateIso(new Date(lead.creationDate));

    if (!groups[date]) {
      groups[date] = [];
    }

    groups[date].push(lead);
    return groups;
  }, {});

  return groupedLeads;
};

// Soon to be deprecated
/*
const formatDateISO = (inputDate) => {
  if (!inputDate) {
    return null;
  }
  try {
    return new Date(inputDate).toISOString().slice(0, 10);
  } catch (err) {
    const errorMessage = `Error formatting time ISO string for inputDate "${inputDate}": ${err.message}`;
    const newError = new Error(errorMessage);
    newError.previousError = err;
    throw newError;
  }
*/
const LeadGroupDate = styled.span`
  font-size: 1rem;
`;

const BadgeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

function LeadList({
  data,
  selectedLead,
  onSelect,
  onNextPage,
  showNextButton,
  isLoading,
  leadsError,
}) {
  const leads = data?.leadSearch?.objects;
  const groupedLeads = sortAndGroupLeadsByDate(leads);

  const handleLeadClick = (lead) => {
    onSelect(lead);
  };

  const handleNextPageClick = () => {
    onNextPage();
  };

  const loader = isLoading ? (
    <Spinner animation="border" variant="primary" />
  ) : null;

  return (
    <section>
      <ErrorMessage errors={[leadsError]}></ErrorMessage>
      {loader}
      <ListGroup>
        {Object.keys(groupedLeads).map((ISOdate) => {
          const leadsGroup = groupedLeads[ISOdate];
          return (
            <ListGroup.Item key={ISOdate} numbered="true">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <BadgeWrapper>
                  <div>
                    <LeadGroupDate>{ISOdate}</LeadGroupDate>
                  </div>
                  <div>
                    <Badge bg="secondary" pill>
                      {leadsGroup.length}
                    </Badge>
                  </div>
                </BadgeWrapper>
              </ErrorBoundary>

              {leadsGroup.map((lead) => (
                <ListGroup.Item
                  key={lead.uuid}
                  onClick={() => {
                    handleLeadClick(lead);
                  }}
                  action
                  selected
                >
                  {lead.fullName}
                </ListGroup.Item>
              ))}
            </ListGroup.Item>
          );
        })}

        <div className="d-grid gap-2">
          <Button
            variant="light"
            disabled={!showNextButton}
            onClick={() => {
              handleNextPageClick();
            }}
          >
            <BsArrowDown /> Altri..
          </Button>
        </div>
      </ListGroup>
    </section>
  );
}

export default LeadList;
