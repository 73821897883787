import Alert from "react-bootstrap/Alert";
import PropTypes from "prop-types";
import React from "react";

const errorCodesMappings = {
  example_code: "Example message",
};

// Used to extract errors coming from react-hook-form
// Returns the first error found in formErrors
function extractFormErrors(formErrors) {
  const errorList = [];
  for (let prop in formErrors) {
    if (formErrors.hasOwnProperty(prop)) {
      return {
        message: `${prop}: ${formErrors[prop].message}`,
      };
    }
  }
  return errorList;
}

function extractError(errorList) {
  if (!errorList) return null;
  // Extracts the first error in the list
  let errorFound = null;
  // Array of errors
  if (Array.isArray(errorList)) {
    let filteredErrors = errorList.filter(function (er) {
      return !!er;
    });
    errorFound = filteredErrors[0];
    if (!errorFound) {
      return null;
    }
  }

  // Tries to extract message
  if (errorFound.message) {
    const mappedError = errorCodesMappings[errorFound.code];
    if (mappedError) {
      return mappedError;
    }
    // Tries to extract the first network error if any
    const firstNetworkError =
      errorFound?.networkError?.result?.errors?.[0]?.message;
    if (firstNetworkError) {
      return firstNetworkError;
    }
    if (errorFound.code) {
      // Tries to print the error message
      return `${errorFound.message} - ${errorFound.code}`;
    }
    return errorFound.message;
  }
  return errorFound.toString();
}
// Extract application errors returned by the graphQl Server
function extractApplicationError(data) {
  if (!data) {
    return null;
  }
  let applicationError = null;
  for (let prop in data) {
    applicationError = data[prop]["error"];
    if (!!applicationError) {
      return applicationError;
    }
  }
}

// Accepts errors as an array of errors
function ErrorMessage({ errors }) {
  let displayedMessage = extractError(errors);

  if (!displayedMessage) return null;

  return (
    <Alert data-testid="error-message" variant="danger">
      {displayedMessage}
    </Alert>
  );
}

ErrorMessage.propTypes = {
  errors: PropTypes.array, // Array of errors
};

export {
  ErrorMessage,
  extractError,
  extractFormErrors,
  extractApplicationError,
};
