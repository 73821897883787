import "./App.css";

import ActionsButton from "../components/ActionsButton";
import { ErrorMessage } from "../components/ErrorMessage";
import PlanList from "../components/PlanList";
import { QUERY_LIST_PLANS } from "../api/queries";
import React from "react";
import { getPlanUrl } from "../api/urls";
import urls from "../api/urls";
import { useQuery } from "@apollo/client";

const ACTIVE = "Attivo";
const NOT_ACTIVE = "Disattivo";

function loadAllPlan(planList) {
  let listPlan = [];
  for (const s of planList) {
    const status = s.disabled ? NOT_ACTIVE : ACTIVE;
    const isValid = status === NOT_ACTIVE && Number(s.subscriptionCount) === 0;
    if (!isValid) {
      listPlan.push({
        uuid: s?.uuid,
        name: s?.name,
        shortNname: s?.shortName,
        subscriptionCount: s.subscriptionCount,
        subscriptionCountActive: s.subscriptionCountActive,
        subscriptionCountPaused: s.subscriptionCountPaused,
        status: status,
        menu: null,
      });
    }
  }
  return listPlan;
}

function PlanListPage() {
  const { loading, error, data } = useQuery(QUERY_LIST_PLANS);

  const popoverLinksList = (cell) => {
    let params;
    params = new URLSearchParams({
      page: 1,
      filter: `plan_type:${cell.uuid}`,
      order: "",
    });
    const urlSubscription = `${
      urls.URL_LIST_SUBSCRIPTION
    }?${params.toString()}`;
    const linksList = [
      {
        title: "Visualizza Piano",
        url: getPlanUrl(cell.uuid),
      },
      {
        title: "Visualizza Abbonamenti",
        url: urlSubscription,
      },
    ];

    return linksList;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Nome Piano",
        accessor: "name",
        width: 80,
      },
      {
        Header: "Sigla Piano",
        accessor: "shortNname",
        width: 40,
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Abbonamenti
            <div>Totale </div>
          </div>
        ),
        accessor: "subscriptionCount",
        width: 30,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Abbonamenti
            <div>Attivi </div>
          </div>
        ),
        accessor: "subscriptionCountActive",
        width: 30,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Abbonamenti
            <div>In Pausa </div>
          </div>
        ),
        accessor: "subscriptionCountPaused",
        width: 30,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Stato
          </div>
        ),
        accessor: "status",
        width: 30,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },

      {
        Header: "",
        accessor: "menu",
        width: 10,
        Cell: ({ cell }) => {
          const links = popoverLinksList(cell?.row?.original);
          return <ActionsButton linksList={links} />;
        },
      },
    ],
    []
  );

  if (loading) {
    return <p>Loading..</p>;
  }

  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  const dataAvailable = !!data && !!data?.plansList;
  let planList;

  if (dataAvailable) {
    planList = loadAllPlan(data?.plansList);
  }

  return (
    <>
      <PlanList columns={columns} planList={planList} />
    </>
  );
}
export default PlanListPage;
