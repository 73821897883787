import React from "react";

function dropDownHeaderView(
  text = "Visualizza",
  align = "center",
  fontSize = "130%",
  color = "grey"
) {
  return (
    <>
      <div
        style={{
          textAlign: align,
          fontSize: fontSize,
          color: color,
        }}
      >
        {text}
      </div>
    </>
  );
}

export default dropDownHeaderView;
