import React, { useState } from "react";

import { BiLinkExternal } from "react-icons/bi";
import { BiPlusCircle } from "react-icons/bi";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import CustomerCreateModal from "./CustomerCreateModal";
import { ErrorMessage } from "../ErrorMessage";
import Form from "react-bootstrap/Form";
import { ImCancelCircle } from "react-icons/im";
import PropTypes from "prop-types"; // ES6
import { QUERY_CUSTOMER_SEARCH } from "../../api/queries";
import Row from "react-bootstrap/Row";
import { customerValidationType } from "../validators";
import { getCustomerUrl } from "../../api/urls";
import { useLazyQuery } from "@apollo/client";

const MIN_CHAR_CUSTOMER_SEARCH = 3;
const CUSTOMER_SELECT_PLACEHOLDER = "Seleziona...";
// Utils
function customerString(c) {
  let name = c.fullName ? c.fullName : `${c.last} ${c.first}`;
  return `${name} - ${c.email} \n ${c.phoneNumber}`;
}

// Customer View component
function CustomerCompactView({ customer, onClearCustomer }) {
  const customerUrl = getCustomerUrl(customer.uuid);
  return (
    <Row>
      <Col xs={9}>
        <div>
          <Form.Label>{`${customer.last} ${customer.first}`}</Form.Label>
        </div>
        <div>
          <Form.Label>{`${customer.email} | ${customer.taxId}`}</Form.Label>
        </div>
      </Col>
      <Col xs={3}>
        {" "}
        <Button
          size="lg"
          variant="light"
          alt="Cambia cliente"
          onClick={onClearCustomer}
        >
          <ImCancelCircle />
        </Button>
        <Button
          size="lg"
          variant="light"
          alt="Cambia cliente"
          href={customerUrl}
          target="_blank"
        >
          <BiLinkExternal />
        </Button>
      </Col>
    </Row>
  );
}
// Creation element displayed in the typeahead
function CreateNewCustomerSelectItem(createCustomer) {
  return (
    <>
      <BiPlusCircle size={24} /> Crea nuovo cliente
    </>
  );
}

// Customer search component
function CustomerSearch({
  onSetCustomer,
  fullInfo,
  className,
  validation,
  createCustomer = true,
}) {
  const [showModal, setShowModal] = useState(false);
  const [searchCustomers, { loading, data, error }] = useLazyQuery(
    QUERY_CUSTOMER_SEARCH
  );

  // Search function for the typeahead
  const handleOnInputChange = (newalue, actionMeta) => {
    let input = newalue?.trim();
    if (input.length < MIN_CHAR_CUSTOMER_SEARCH) {
      return;
    }
    searchCustomers({
      variables: {
        searchQuery: input,
      },
    });
  };

  const handleOnChange = (data) => {
    if (data?.__isNew__) {
      setShowModal(true);
    }
    if (!data?.content) {
      return;
    }
    // Customer is selected
    return onSetCustomer(data.content, false);
  };

  // Prepares data for the typeahead
  let customerList = [];
  if (data?.customerSearch) {
    customerList = data?.customerSearch.map((customer) => {
      return {
        label: customerString(customer),
        value: customer.uuid,
        content: customer,
      };
    });
  }

  let customerCreateModal = null;
  if (showModal) {
    customerCreateModal = (
      <CustomerCreateModal
        show={showModal}
        fullInfo={fullInfo}
        validation={validation}
        onHide={() => {
          setShowModal(false);
        }}
        onCreated={(customer) => {
          // Customer is created and selected
          setShowModal(false);
          onSetCustomer(customer, true);
        }}
      />
    );
  }

  const customStyles = {
    menu: (base) => ({
      ...base,
      zIndex: "5",
    }),
  };

  const customCreateTable = (
    <CreatableSelect
      className="customer-select-input"
      onInputChange={handleOnInputChange}
      isLoading={loading}
      options={customerList}
      createOptionPosition="first"
      allowCreateWhileLoading={true}
      formatCreateLabel={() => CreateNewCustomerSelectItem(createCustomer)}
      isValidNewOption={() => true}
      placeholder={CUSTOMER_SELECT_PLACEHOLDER}
      styles={customStyles}
      onCreateOption={() => {
        if (createCustomer) setShowModal(true);
      }}
      onChange={handleOnChange}
    />
  );

  const customSelect = (
    <Select
      className="customer-select-input"
      onInputChange={handleOnInputChange}
      isLoading={loading}
      options={customerList}
      createOptionPosition="first"
      allowCreateWhileLoading={true}
      noOptionsMessage={() => null}
      isValidNewOption={() => true}
      placeholder={CUSTOMER_SELECT_PLACEHOLDER}
      styles={customStyles}
      onChange={handleOnChange}
    />
  );

  return (
    <>
      <div data-testid="customer-select" className={className}>
        {createCustomer ? customCreateTable : customSelect}
        <ErrorMessage errors={[error]} />
        {customerCreateModal}
      </div>
    </>
  );
}

// Main component
function CustomerModule({
  customer,
  onSetCustomer,
  className,
  validation,
  fullInfo = true,
  createCustomer = true,
}) {
  let componentToShow = null;
  if (customer) {
    componentToShow = (
      <CustomerCompactView
        customer={customer}
        onClearCustomer={() => {
          onSetCustomer(null);
        }}
      ></CustomerCompactView>
    );
  } else {
    componentToShow = (
      <CustomerSearch
        className={className}
        onSetCustomer={onSetCustomer}
        validation={validation}
        fullInfo={fullInfo}
        createCustomer={createCustomer}
      />
    );
  }
  return componentToShow;
}

CustomerModule.propTypes = {
  // Customer to show
  customer: PropTypes.object,
  // Called to set a new customer
  onSetCustomer: PropTypes.func,
  // If false only a subset of the fields is used to create a new customer
  fullInfo: PropTypes.bool,
  // Validation type
  validation: PropTypes.oneOf([
    customerValidationType.INVOICING,
    customerValidationType.RECEIVING,
  ]),
  //Show "Create New Customer"
  createCustomer: PropTypes.bool,
};

export { CustomerModule, CUSTOMER_SELECT_PLACEHOLDER, CustomerSearch };
